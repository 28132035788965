import { IconButton, Tooltip } from '@material-ui/core';
import { getMostRecentDate, getOlderDate, formatDate, momentFromDateString } from 'modules/dates';
import React, { useMemo } from 'react';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import { isArray, isPlainObject } from 'lodash';
import Typography from './Typography';
import useLanguage from 'hooks/useLanguage';
import moment, { isMoment } from 'moment';


const DateTooltip = ({
	dates = [],
}) => {
	const lang = useLanguage('date')

	const receivedDates = useMemo(() => isPlainObject(dates)
		? Object.keys(dates).sort().join('\n')
		: isArray(dates)
			? dates.map(d => isMoment(d) ? d : moment(d)).map(formatDate).sort().join('\n')
			: dates
	, [dates])

	const parsedDates = useMemo(() => (!isArray(dates) ? Object.keys(dates).map(momentFromDateString) : dates), [dates])

	const to = useMemo(() => (!parsedDates || parsedDates.length === 0) ? '' : formatDate(getMostRecentDate(parsedDates)), [parsedDates])
	const from = useMemo(() => (!parsedDates || parsedDates.length === 0) ? '' : formatDate(getOlderDate(parsedDates)), [parsedDates])
	if (!parsedDates || parsedDates.length === 0) {
		return null
	}
	return (
		<div>
			{to === from ? <Typography>{from}</Typography> : <div>
				<Typography>{lang.from + ' ' + from + ' ' + lang.to + ' ' + to}</Typography>
				<Tooltip title={receivedDates}>
					<IconButton>
						<CalendarMonthIcon />
					</IconButton>
				</Tooltip>
			</div>}
		</div>
	);
};

export default DateTooltip;
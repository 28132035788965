import { Chip, TextField } from '@material-ui/core';
import { Autocomplete } from '@mui/material';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { getLevels } from './helpers';
import classes from 'styles/classes.module.scss'
import './LevelFilter.scss'

const LevelFilter = ({
    freelances,
    updateLevels,
}) => {
    const [APIlevels, setAPILevels] = useState([])
    const [levels, setLevels] = useState([])
    const allLevelsOption = useMemo(() => ({ label: 'Tous les levels', level: 0 }), [])
    const [levelsSelected, setLevelsSelected] = useState([allLevelsOption])

    useEffect(() => {
        getLevels()
            .then(setAPILevels)
    }, [])

    useEffect(() => {
        setLevels(APIlevels.filter(level => freelances.findIndex(
            freelance => Math.trunc(parseFloat(freelance.level)) === Math.trunc(parseFloat(level.level))
        ) !== -1)
        )
    }, [freelances, APIlevels])

    const options = useMemo(() => [allLevelsOption, ...levels
        .map((option) => ({
            label: `Niveau ${option.level} - ${option.studio_price}€`,
            ...option
        }))], [allLevelsOption, levels])

    const isAddition = useCallback((newValues) => levelsSelected.length < newValues.length, [levelsSelected])

    const getDifferentialLevel = useCallback((newValues, isAdd = false) =>
        isAdd ? newValues.find(elem => levelsSelected.findIndex(lvl => lvl.level === elem.level) === -1)
            : levelsSelected.find(elem => newValues.findIndex(lvl => lvl.level === elem.level) === -1),
        [levelsSelected])

    const manageLevelsSelected = (levelsSelected, elem, isAdd) =>
        isAdd ? [...(levelsSelected[0].level === 0 ? [] : levelsSelected), elem]
            : levelsSelected.length === 1 ? [allLevelsOption] : levelsSelected.filter(lvl => lvl.level !== elem.level)

    const onChange = ({ target }, values) => {
        const isAdd = isAddition(values)
        const elem = getDifferentialLevel(values, isAdd)
        if (elem.level === 0) {
            if (!isAdd) {
                return
            }
            setLevelsSelected([elem])
        }
        setLevelsSelected((levelsSelected) => manageLevelsSelected(levelsSelected, elem, isAdd))
    }

    useEffect(() => {
        updateLevels(
            levelsSelected.map(({ level }) => level)
        )
    }, [levelsSelected])

    return (
        <Autocomplete
            className={classes.fitContent}
            multiple
            filterSelectedOptions
            disableCloseOnSelect={options.length > 2}
            id="level-filter"
            options={options}
            value={levelsSelected}
            isOptionEqualToValue={(option, value) => option.level === value.level}
            onChange={onChange}
            freeSolo
            renderTags={(value, getTagProps) =>
                value.map((option, index) => (
                    <Chip variant="outlined" label={option.label} {...getTagProps({ index })} />
                ))
            }
            renderInput={(params) => (
                <TextField
                    {...params}
                    variant="filled"
                />
            )}
        />
    );
};

export default LevelFilter;
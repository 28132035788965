import { useEffect, useState, useCallback } from 'react'
import Calendar from 'common/components/Calendar/Calendar'
import { useNavigate } from 'react-router-dom'
import timestamp2date from 'common/utils/date/timestamp2date'
import { removeEvent, socket } from 'socket'
import { useSockets } from 'hooks/useSockets'
import { getMeetings } from './helpers'
import RightSide from 'components/RightSide'
import TitlePage from 'components/TitlePage'

function AdminCalendar() {
	const [meetings, setMeetings] = useState([])
	const { socketConnected } = useSockets()
	const navigate = useNavigate()

	const onClickEvent = ({ event: { id } }) => navigate(`/meeting/${id}`)

	const components = {
		eventWrapper: (props) => <div onClick={() => onClickEvent(props)}>{props.children}</div>
	}

	const prep = (dp) => {
		dp.start = timestamp2date(dp.start)
		dp.end = timestamp2date(dp.end)
		return dp
	}

	const init = useCallback(async () => {
		try {
			const meetingsFromAPI = await getMeetings()
			setMeetings(meetingsFromAPI.map(prep))
		} catch (e) {
			console.error(e)
		}
	}, [])

	useEffect(() => {
		if (socketConnected && socket) {
			socket.emit('join_meetings');
			socket.on(`meeting updated`, () => {
				init()
			});
		}
		return () => {
			if (socketConnected && socket) {
				socket.emit('leave_meetings');
				removeEvent(`meeting updated`);
			}
		};
	}, [socketConnected])

	useEffect(() => {
		init()
	}, [])

	return (
		<RightSide>
			<TitlePage>Liste des événements ADMIN</TitlePage>
			<Calendar
				selectable={true}
				popup={true}
				components={components}
				timeslots={1}
				step={30}
				views={{ week: true }}
				// onSelectSlot={onSelectSlot}
				defaultView='week'
				events={meetings}
			/></RightSide>
	)
}

export default AdminCalendar

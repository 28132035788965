import Loader from 'components/Loader';
import React, { useContext } from 'react';
import { Store } from 'store/store';
import classes from './RightSide.module.scss'

const RightSide = ({
    children
}) => {
    const { state: { rightSideLoading } } = useContext(Store)
    return (
        <div className={classes.root}>
            {rightSideLoading ? <Loader /> : children}
        </div>
    );
};

export default RightSide;
import { IconButton } from "@material-ui/core";
import { Tooltip } from "antd";
import MyTable from "components/MyTable/MyTable";
import React, { useMemo } from "react";
import { Link } from "react-router-dom";
import AutoGraphIcon from '@mui/icons-material/AutoGraph';
import PersonIcon from '@mui/icons-material/Person';
import moment from "moment";
import useLanguage from "hooks/useLanguage";

const FreelanceTable = ({
	freelances = []
}) => {
	const lang = useLanguage("statistics");

	const freelanceColumns = useMemo(() => [
		{ title: lang.name, render: ({ firstname, lastname }) => firstname + ' ' + lastname },
		{
			title: lang.actions,
			render: ({ id }) => (
				<>
                	<Link to={"/freelance/badges/" + id}>
						<Tooltip title="Accéder à la page d'augmentation de level">
							<IconButton>
								<AutoGraphIcon />
							</IconButton>
						</Tooltip>
					</Link>
					<Link to={"/freelance/levelup/" + id}>
						<Tooltip title="Accéder à la page d'augmentation de level">
							<IconButton>
								<AutoGraphIcon />
							</IconButton>
						</Tooltip>
					</Link>
					<Link to={"/user/" + id}>
						<Tooltip title="Accéder au profil de l'utilisateur">
							<IconButton>
								<PersonIcon />
							</IconButton>
						</Tooltip>
					</Link>
				</>
			)
		},
		{
			title: lang.pastDates,
			render: ({ pastDates }) => {
				if (pastDates?.length > 0)
					return pastDates.map(date => moment(date).format('DD/MM/YYYY')).join(', ');
				else
					return 'Aucune date passée';
			}
		},
		{
			title: lang.futurDates,
			render: ({ futurDates }) => {
				if (futurDates?.length > 0)
					return futurDates.map(date => moment(date).format('DD/MM/YYYY')).join(', ');
				else
					return 'Aucune date future';
			}
		}
	], [lang]);

	return (
		<MyTable
			columns={freelanceColumns}
			data={freelances}
			options={{ paging: false }}
		/>
	)
};

export default FreelanceTable;

import Typography from 'components/Typography'
import { getDateFormatted } from 'modules/dates'
import { Label } from 'semantic-ui-react'

function IssueElem({ title, description, reference, status, created_at }) {
	return (
		<div>
			<h4>{reference}</h4>
			<Typography italic>Créée le {getDateFormatted(created_at)}</Typography>
			<h1>{title}</h1>
			<p>{description}</p>
			<Label color='teal' horizontal>
				{status}
			</Label>
		</div>
	)
}

export default IssueElem

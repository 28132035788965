import classes from './SideBar.module.scss'
import { logOut } from 'containers/helpers'
import useLanguage from 'hooks/useLanguage'
import IconSignOut from 'icons/signOut.svg'
import MiniProfileCard from 'components/MiniProfileCard'
import { Link } from 'react-router-dom'
import { useContext } from 'react'
import { Store } from 'store/store'
import useIsMobile from 'hooks/useIsMobile'
import classNames from 'classnames'
import Image from 'components/Image/Image'
import SideBarLinksComponent from './SideBarLinksComponent'

function SideBar({ setOpositeToActualOpen = () => { } }) {
	const isMobile = useIsMobile()
	const { state: { user } } = useContext(Store)
	const lang = useLanguage('sideBar')
	const clickLogOut = () => {
		setOpositeToActualOpen()
		logOut()
	}
	return (
		<div className={classNames(classes.container, isMobile && classes.mobile)}>
			<Image
				src='/assets/styleep-logo.png'
				alt='styleep'
				centered
				containerClassName={classes.logoContainer}
			/>
			<div className={classes.wrapper}>
				<SideBarLinksComponent setOpositeToActualOpen={setOpositeToActualOpen} />
				<div>
					<MiniProfileCard {...user} />
					<div className={classes.link}>
						<img src={IconSignOut} alt={lang.signOut} />
						<Link onClick={clickLogOut} to='/' className={classes.label}>
							{lang.signOut}
						</Link>
					</div>
				</div>
			</div>
		</div>
	)
}

export default SideBar

import { DatePicker } from "antd";
import MenuNavigationComponent from "components/MenuNavigationComponent";
import RightSide from "components/RightSide";
import TitlePage from "components/TitlePage";
import { dayStatuses } from "constants/DayStatuses";
import useLanguage from "hooks/useLanguage";
import { useCallback, useMemo, useState } from "react";
import { useLocation } from "react-router-dom";
import { getStatistics } from "./helper";
import StatisticsListComponent from "./StatisticsListComponent";
import classes from "./Statistics.module.scss";
import Button from "components/Button";
import { isEmpty } from "lodash";
import { getExtremesOfMonth, getExtremesOfYear } from "modules/dates";

const { RangePicker } = DatePicker;

const Statistics = () => {
	const lang = useLanguage("statistics");
	const [isLoading, setIsLoading] = useState(false)
	const [missionsWithoutFreelances, setMissionsWithoutFreelances] = useState([]);
	const [contracts, setContracts] = useState([]);
	const [invoices, setInvoices] = useState([]);
	const [freelanceWithoutAvaibility, setFreelanceWithoutAvaibility] = useState([]);
	const [studioWithoutMission, setStudioWithoutMission] = useState([]);
	const [freelanceWithProposal, setFreelanceWithProposal] = useState([]);
	const [days, setDays] = useState([]);
	const [selectedDates, setSelectedDates] = useState([]);

	const { search } = useLocation()
	const query = useMemo(() => new URLSearchParams(search), [search])

	const handleSubmit = useCallback(async (e) => {
		e.preventDefault();
		setIsLoading(true)
		console.log('selectedDates', selectedDates)
		try {
			let data;
			//ici faire un seul type d'envoie on parse en fonction du type le startDate et endDate
			switch (query.get('date_nav')) {
				case '0':
					data = await getStatistics(
						selectedDates[0].format('YYYY-MM-DD'), selectedDates[1].format('YYYY-MM-DD')
					);
					break;
				case '1':
					const [startDateYear, endDateYear] = getExtremesOfYear(selectedDates).map(momentDate =>  momentDate.format('YYYY-MM-DD'))
					data = await getStatistics(startDateYear, endDateYear);
					break;
					case '2':
					const [startDate, endDate] = getExtremesOfMonth(selectedDates).map(momentDate =>  momentDate.format('YYYY-MM-DD'))
					data = await getStatistics(startDate, endDate);
					break;
				default:
					break
			}
			setContracts(data.contracts);
			setInvoices(data.invoices);
			setDays(data.days);
			setMissionsWithoutFreelances(data.missionsWithoutFreelances);
			setFreelanceWithoutAvaibility(data.freelanceWithoutAvaibility);
			setStudioWithoutMission(data.studioWithoutMission);
			setFreelanceWithProposal(data.freelanceWithProposal);
		} catch (err) {
			console.log(err);
		} finally {
			setIsLoading(false)
		}
	}, [selectedDates, query]);

	const components = useMemo(() => [
		{
			title: lang.range,
			component: <RangePicker
				onChange={setSelectedDates}
			/>
		},
		{
			title: lang.year,
			component: <DatePicker
				onChange={setSelectedDates}
				picker="year"
			/>
		},
		{
			title: lang.month,
			component: <DatePicker
				onChange={setSelectedDates}
				picker="month"
			/>
		}
	], [lang, setSelectedDates]);

	const missionDaysNotBooked = useMemo(() => days.filter(
		({ status }) => status < dayStatuses.BOOKED || status === dayStatuses.CANCELLED).length,
		[days])
	const missionDaysBooked = useMemo(() => invoices.reduce((prev, { dates }) => prev + dates.length, 0), [invoices])
	const turnover = useMemo(() => invoices.reduce((acc, { sum }) => acc + Number(sum), 0), [invoices])
	const benefit = useMemo(() => invoices.reduce((acc, { sum, freelance_price }) =>
		acc + Number(sum) - Number(freelance_price)
		, 0).toFixed(2), [invoices])
	const unpaid = useMemo(() => contracts.filter(({ status }) => status < dayStatuses.BOOKED || status === dayStatuses.CANCELLED).length, [contracts])
	const isVisible = useMemo(() => isEmpty(days) || !contracts || !missionsWithoutFreelances || !freelanceWithoutAvaibility, [
		days,
		contracts,
		missionsWithoutFreelances,
		freelanceWithoutAvaibility,
	])
	return (
		<RightSide>
			<TitlePage>{lang.statistics}</TitlePage>

			<div className={classes.searchContainer}>
				<MenuNavigationComponent
					queryName="date_nav"
					components={components}
				/>

				<Button type='primary' onClick={handleSubmit} loading={isLoading}>
					Recherche
				</Button>
			</div>

			{isVisible ? (
				<p>{lang.noStatistics}</p>
			) : (
				<div className={classes.statisticsContainer}>
					<div>
						<h3>{lang.numberOfUnbookedMissionDays}</h3>
						<p>{missionDaysNotBooked}</p>
					</div>
					<div>
						<h3>{lang.numberOfBookedMissionDays}</h3>
						<p>{missionDaysBooked}</p>
					</div>
					<div>
						<h3>{lang.turnover}</h3>
						<p>{turnover}€ HT</p>
					</div>
					<div>
						<h3>{lang.benefit}</h3>
						<p>{benefit}€ HT</p>
					</div>
					<div>
						<h3>{lang.unpaidContracts}</h3>
						<p>{unpaid}</p>
					</div>
					<div>
						<h3>{lang.missionWithoutFreelance}</h3>
						<p>{missionsWithoutFreelances.length}</p>
					</div>
					<div>
						<h3>{lang.freelanceWithoutAvaibility}</h3>
						<p>{freelanceWithoutAvaibility.length}</p>
					</div>
					<div>
						<h3>{lang.studioWithoutMission}</h3>
						<p>{studioWithoutMission.length}</p>
					</div>
					<div>
						<h3>{lang.freelancerRequest}</h3>
						<p>{freelanceWithProposal.length}</p>
					</div>
				</div>
			)}


			<StatisticsListComponent
				contracts={contracts}
				missions={missionsWithoutFreelances}
				freelance={freelanceWithoutAvaibility}
				freelanceWithProposal={freelanceWithProposal}
			/>
		</RightSide>
	);
};

export default Statistics;

import { useCallback, useContext } from 'react'
import jwtDecode from 'jwt-decode'
import { useNavigate } from 'react-router-dom'

import { fetchApi, saveTokens, logOut, getMeFromApi } from '../containers/helpers'
import { Store } from '../store/store'

export const useStoreUtils = () => {
    const { dispatch, state } = useContext(Store)
    const navigate = useNavigate()
    const dispatchAlert = useCallback(
        (alert = { type: 'error', message: 'rergegreg' }) => {
            dispatch({ type: 'ADD_ALERT', payload: alert })
        },
        [dispatch]
    )

    const regenerateTokens = useCallback(
        () =>
            fetchApi('/user/regenerateTokens')
                .then((tokens) => saveTokens(tokens, dispatch))
                .catch((e) => {
                    logOut()
                    process.env.NODE_ENV !== 'production' && console.error(e.message)
                }),
        [dispatch]
    )

    const updateStoreUser = useCallback(user => dispatch({
        type: 'USER/INIT',
        payload: user
    }), [dispatch])

    const updateLocalUser = useCallback((newUser) => {
        const user = {
            ...JSON.parse(localStorage.getItem('profile')),
            ...newUser,
        }
        localStorage.setItem(
            'profile',
            JSON.stringify(user)
        )
        updateStoreUser(user)
    }, [updateStoreUser])

    const reloadUser = useCallback(async () => {
        try {
            const user = await getMeFromApi()
            console.log('user reloaded', user);
            updateLocalUser(user)
        } catch (error) {
            console.log('error during reloadUser', error)
        }
    }, [updateLocalUser])

    const cleanNotifications = () => dispatch({ type: 'REMOVE_ALL_NOTIFICATIONS' })


    const loginInApp = tokens => {
        const user = jwtDecode(tokens.token.token)
        updateStoreUser(user)
        saveTokens(tokens)
        const path = JSON.parse(localStorage.getItem('path'))
        if (path) localStorage.removeItem('path')
        cleanNotifications()
        path ? navigate(path) : navigate('/home')
    }

    const setLoadingOn = () => dispatch({
        type: 'LOADING_ON'
    })
    const setLoadingOff = () => dispatch({
        type: 'LOADING_OFF'
    })
    const setTableLoadingOn = () => dispatch({
        type: 'UPDATE_TABLE_LOADING', payload: true
    })
    const setTableLoadingOff = () => dispatch({
        type: 'UPDATE_TABLE_LOADING', payload: false
    })

    return {
        dispatchAlert,
        dispatch,
        state,
        regenerateTokens,
        updateLocalUser,
        loginInApp,
        setLoadingOn,
        setLoadingOff,
        reloadUser,
        setTableLoadingOn,
        setTableLoadingOff
    }
}

import React, { useContext, useMemo, useState } from 'react';
import { toast } from 'react-toastify';
import { deleteBadgeAPI, updateImageAPI } from '../helpers';
import { Store } from 'store/store';
import Button from 'components/Button';
import TitlePage from 'components/TitlePage';
import RightSide from 'components/RightSide';
import BadgeListComponent from './BadgeListComponent';

const BadgeList = () => {
    const { state: { badges } } = useContext(Store)

    return (
        <RightSide>
            <TitlePage
                subtitle={
                    <Button link={'/badge/create'}>
						Créer un badge
					</Button>
                }
			>
				Liste des badges existants
			</TitlePage>
			<BadgeListComponent badges={Object.entries(badges)} />
        </RightSide>
    );
};

export default BadgeList;
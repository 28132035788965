export const FR = {
    mission_created: 'Vous avez créé une nouvelle mission',
    mission_proposal: '$1 vous a proposé une mission $2',
    privateConv: 'votre conversation privée',
    readed: 'Afficher les notifications lues',
  }
  
  export const EN = {
    mission_created: 'You have created a new mission',
    mission_proposal: '$1 send you a proposal mission $2',
    privateConv: 'your private discussion',
    readed: 'To display readed notifications',
  }
  
  export default {
    fr: FR,
    us: EN,
  }
  
import { useEffect } from 'react'
import { useSockets } from 'hooks/useSockets'
import { socket, removeEvent } from 'socket'
import { toast } from 'react-toastify'
import { upperFirst } from 'lodash'

const useElemSocketUpdates = (elemName, setData) => {
	const { socketConnected } = useSockets()
	const addElem = `add ${elemName}`
	const removeElem = `remove ${elemName}`

	useEffect(() => {
		if (socketConnected && socket) {
			socket.on(addElem, (newElem) => {
				toast(`New ${upperFirst(elemName)} ${newElem.id} just added`)
				setData(data => [...data, newElem])
			})

			socket.on(removeElem, (elemToRemove) => {
				toast(`Element ${elemToRemove.id} has been removed from ${upperFirst(elemName)}`)
				setData(data => data.filter(currElem => currElem.id !== elemToRemove.id))
			})
		}
		return () => {
			if (socketConnected && socket) {
				removeEvent(addElem)
				removeEvent(removeElem)
			}
		}
	}, [socketConnected, elemName, setData])
}

export default useElemSocketUpdates
import RightSide from 'components/RightSide';
import TitlePage from 'components/TitlePage';
import { useStoreUtils } from 'hooks/useStoreUtils';
import React, { useEffect, useMemo, useState } from 'react';
import AppointmentPaymentListComponent from './AppointmentPaymentListComponent';
import { getAppointments } from './helpers';
import AppointmentBtocListComponent from './AppointmentBtocListComponent.js';

const PaymentList = ({ isBtoB = true }) => {
    const [appointments, setAppointments] = useState([])
	const {
        setTableLoadingOn,
        setTableLoadingOff
    } = useStoreUtils()

	const initAppointments = (isBtoB) => getAppointments(isBtoB)
		.then(setAppointments)
		.finally(setTableLoadingOff)

    useEffect(() => {
		setTableLoadingOn()
        initAppointments(isBtoB);
    }, [isBtoB])

    const appointmentsOrdered = useMemo(() => [...appointments]
        .sort((a, b) => {
            //la valeur la plus haute est la plus récente
            const aLast = new Date(a.updated_at).getTime()
            const bLast = new Date(b.updated_at).getTime()

            return bLast - aLast
        }), [appointments])

    return (
        <RightSide>
            <TitlePage>Liste des rdvs</TitlePage>

            {isBtoB ? (
                <AppointmentPaymentListComponent appointments={appointmentsOrdered} />
            ) : (
                <AppointmentBtocListComponent appointments={appointmentsOrdered} />
            )}
        </RightSide>
    );
};

export default PaymentList;
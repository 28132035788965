/* eslint-disable import/no-anonymous-default-export */
import confirmAccountLanguage from './confirmAccount.language';
import formLanguage from './form.language';
import headerLanguage from './header.language';
import logInLanguage from './logIn.language';
import passwordLanguage from './password.language';
import profileLanguage from './profile.language';
import rightSideLanguage from './rightSide.language';
import titleLanguage from './title.language';
import forgotPasswordLanguage from './forgotPassword.language'
import onboardLanguage from './onboard.language'
import subscriptionPaymentLanguage from './subscriptionPayment.language';
import starterPayLanguage from './starterPay.language';
import bookkeepingLanguage from './bookkeeping.language';
import notificationLanguage from './notification.language';
import godsonLanguage from './godson.language';
import reviewLanguage from './review.language';
import sideBarLanguage from './sideBar.language'
import marketPlaceLanguage from './marketPlace.language';
import dateLanguage from './date.language';
import adddressFormLanguage from './addressForm.language';
import statisticsLanguage from './statistics.language';

export const getLanguage = (value = 'fr') => ({
    login: logInLanguage[value],
    title: titleLanguage[value],
    confirmAccount: confirmAccountLanguage[value],
    form: formLanguage[value],
    password: passwordLanguage[value],
    profile: profileLanguage[value],
    rightSide: rightSideLanguage[value],
    header: headerLanguage[value],
    forgotPassword: forgotPasswordLanguage[value],
    onboard: onboardLanguage[value],
    subscriptionPayment: subscriptionPaymentLanguage[value],
    starterPay: starterPayLanguage[value],
    bookkeeping: bookkeepingLanguage[value],
    notification: notificationLanguage[value],
    godson: godsonLanguage[value],
    review: reviewLanguage[value],
    sideBar: sideBarLanguage[value],
    marketPlace: marketPlaceLanguage[value],
    date: dateLanguage[value],
	addressForm: adddressFormLanguage[value],
	statistics: statisticsLanguage[value],
});

export default {
    fr: getLanguage(),
    us: getLanguage('us'),
};

import useLanguage from 'hooks/useLanguage'
import classes from './MiniProfileCard.module.scss'
import { UserTypes } from 'constants/UserTypes'
import { Link } from 'react-router-dom'
import { useMemo } from 'react'
import { getImageUrl } from 'containers/helpers'
import Image from 'components/Image'
import { upperFirst } from 'lodash'
import Typography from 'components/Typography'
import classNames from 'classnames'

function MiniProfileCard({ image, firstname, lastname, type, gender }) {

	const lang = useLanguage('profile')

	const prof = useMemo(() =>
		type === UserTypes.STUDIO
			? lang.studio
			: (!gender || gender === 'male')
				? lang.male_prof : lang.female_prof,
		[type, lang, gender])

	return (
		<Link to={'/profile'}>
			<div className={classes.container}>
				<span className={image ? classes.avatar : classes.emptyAvatar}>
					{image ? <Image contain src={getImageUrl(image)} alt='profile' /> : null}
				</span>
				<div className={classNames(classes.flex, classes.columnDirection)}>
					<Typography className={classes.firstName}>{upperFirst(firstname)} {upperFirst(lastname)}</Typography>
					<Typography className={classes.profession}>{prof}</Typography>
				</div>
			</div>
		</Link>
	)
}

export default MiniProfileCard
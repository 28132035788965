import React, { useMemo } from 'react';
import { Button } from 'semantic-ui-react';
import { Link } from 'react-router-dom';
import MyTable from 'components/MyTable/MyTable';
import DateTooltip from 'components/DateTooltip';
import { dateByDateFormat } from 'modules/dates';
import useLocale from 'hooks/useLocale';
// import StatusGesture from './StatusGesture';

const MissionListComponent = ({
    missions = []
}) => {
	const locale = useLocale()
    const columns = useMemo(() => [
        { title: 'Actions', render: (props) => <Button as={Link} to={'/mission/page/' + props.id}>Aller à la page de la mission</Button> },
        { title: 'Titre de la mission', render: ({ title }) => title },
        { title: 'Nombre de jours', render: ({ days }) => Object.keys(days).length },
        { title: 'Studio', render: ({ studio: { id, studio_name } }) => `${studio_name} [${id}]` },
        {
            title: 'Jours', render: ({ days }) => <DateTooltip dates={Object.keys(days).map(date => dateByDateFormat(date, 'DD/MM/YYYY', locale))} />
        },
    ], [])

    const datas = useMemo(() => missions, [missions])
    return (
        <div style={{ maxWidth: "100%" }}>
            <MyTable
                columns={columns}
                data={datas}
                title={'Missions'}
                sear
            />
        </div>
    );
};

export default MissionListComponent;
import useRemoteData from 'hooks/useRemoteData';
import React, { useContext } from 'react';
import { Store } from 'store/store';
import AdminMeetingInfos from 'components/AdminMeetingInfos'

const MeetingIssueComponent = ({
    meetingId
}) => {
    const { loading, error, data: meeting } = useRemoteData(`/meeting/${meetingId}`)
    const { state: { user: { id: userId } } } = useContext(Store)
    console.log('meeting', meeting)
    return (
        !!meeting && <AdminMeetingInfos meeting={meeting} userId={userId} />
    );
};

export default MeetingIssueComponent;
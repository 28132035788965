import RightSide from 'components/RightSide';
import { useSockets } from 'hooks/useSockets';
import React, { useCallback, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { removeEvent, socket } from 'socket';
import { getAppointment } from './helpers';
import { getContractConfirmations } from 'containers/contract/helpers';
import AppointmentInfos from 'components/AppointmentInfos';
import AppointmentInfosBtoc from 'components/AppointmentInfosBtoc.js';
import { getPrepaymentApi } from 'containers/profile/Profile/helpers';

const AppointmentPage = ({ isBtob = true }) => {
	const appointmentId = Number(useParams().appointmentId);
	const { socketConnected } = useSockets();
	const [appointment, setAppointment] = useState({});
	const [prepayment, setPrepayment] = useState(undefined);

	const loadAppointment = useCallback(async () => {
		try {
			const newAppointment = await getAppointment(appointmentId, isBtob);
			if (newAppointment.contract) {
				newAppointment.confirmations = await getContractConfirmations(newAppointment.contract);
			}
			setAppointment(newAppointment);
		} catch (error) {
			console.log('error', error);
		}
	}, [appointmentId, isBtob]);

	const loadPrepayment = useCallback(async () => {
		try {
			const newPrepayment = await getPrepaymentApi(appointmentId);
			setPrepayment(newPrepayment);
		} catch (error) {
			console.log('error', error);
		}
	}, [appointmentId]);

	useEffect(() => {
		if (appointmentId) {
			loadAppointment(appointmentId);
			loadPrepayment(appointmentId);
		}
	}, [appointmentId, loadAppointment, loadPrepayment]);

	const navigate = useNavigate();

	useEffect(() => {
		if (socketConnected && socket) {
			socket.emit('join_appointment', appointmentId);
			socket.on(`appointment updated`, () => {
				loadAppointment();
				loadPrepayment();
			});
			socket.on(`appointment removed`, () => {
				toast('Le rdv a été supprimé.');
				navigate('/missions');
			});
		}
		return () => {
			if (socketConnected && socket) {
				socket.emit('leave_appointment', appointmentId);
				removeEvent(`appointment updated`);
				removeEvent(`appointment removed`);
			}
		};
	}, [appointmentId, socketConnected]);

	return (
		<RightSide>
			{isBtob ? (
				<AppointmentInfos {...appointment} prepayment={prepayment} />
			) : (
				<AppointmentInfosBtoc {...appointment} prepayment={prepayment} />
			)}
		</RightSide>
	);
};

export default AppointmentPage;

import { godfatherStatuses } from 'constants/godfather.constant';
import React, { useCallback, useContext, useState } from 'react';
import { toast } from 'react-toastify';
import { Button } from 'semantic-ui-react';
import { Store } from 'store/store';
import { sentFundsToFreelance } from './helpers';

const StatusGodson = ({ status, user, updateGodsons = () => {} }) => {
    const { state: { language: { godson: godsonLang } } } = useContext(Store)
    const [loading, setLoading] = useState(false)
    const IHaveSentFunds = useCallback(async () => {
        setLoading(true)
        try {
            await sentFundsToFreelance(user)
            updateGodsons(user)
            toast('Succès')
        } catch (error) {
            toast('Erreur')
            console.log('error', error)
        } finally {
            setLoading(false)
        }
    }, [updateGodsons, user])

    switch (status) {
        case godfatherStatuses.WAITING_FIRST_MISSION:
            return godsonLang.statuses[godfatherStatuses.WAITING_FIRST_MISSION]
        case godfatherStatuses.FIRST_MISSION_DONE:
            return <div>
                <div>{godsonLang.statuses[godfatherStatuses.FIRST_MISSION_DONE]}</div>
                <Button onClick={IHaveSentFunds} loading={loading}>
                    J'ai effectué le paiement
                </Button></div>
        case godfatherStatuses.PAYMENT_DONE:
            return godsonLang.statuses[godfatherStatuses.PAYMENT_DONE]
        default:
            return false
    }
};

export default StatusGodson;
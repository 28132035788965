import { QuestionTextKeys as qt } from 'constants/QuestionTextKeys'

export const FR = {
	satisfactionLevels: {
		0: 'très insatisfait',
		1: 'insatisfait',
		2: 'ok',
		3: 'satisfait',
		4: 'très satisfait'
	},
	questions: {
		[qt.PROFESSIONALISM]: 'Professionnalisme',
		[qt.PUNCTUALITY]: 'Ponctualité',
		[qt.COMPETENCE]: 'Compétence',
		[qt.CLIENT_RAPPORT]: 'Rapport client',
		[qt.COMMENT_ABOUT_FREELANCER_PUBLIC]: 'Veuillez laisser un commentaire public pour le freelancer',
		[qt.COMMENT_ABOUT_STUDIO_PUBLIC]: 'Veuillez laisser un commentaire public pour le studio',
		[qt.COMMENT_ABOUT_FREELANCER_PRIVATE]: `Y a-t-il quelque chose que vous voudriez nous faire savoir en privé sur votre expérience (la réponse ne sera pas partagée avec le freelancer)`,
		[qt.COMMENT_ABOUT_STUDIO_PRIVATE]: `Y a-t-il quelque chose que vous voudriez nous faire savoir en privé sur votre expérience (la réponse ne sera pas partagée avec le studio)`,
		[qt.RECOMMEND_TO_COLLEAGUES]: `Je recommanderais de travailler ici à mes collègues`,
		[qt.COMMUNCATION]: `Communication`,
		[qt.MORALE]: `Morale`,
	},
	reviews: 'Avis',
	myReviews: 'Mes Avis',
	myReview: 'Mon Avis',
	reviewsFromStudio: 'Les avis de l\'entreprise',
	reviewFromStudio: 'L\'avis de l\'entreprise',
	reviewsFromFreelance: 'Les avis du freelance',
	reviewFromFreelance: 'L\'avis du freelance',
	leaveReviewForStudio: `Laisser un avis pour l'entreprise`,
	leaveReviewForFreelance: `Laisser un avis pour le freelance`,
	thanks: 'Merci pour votre notation',
	reviewOfMission: 'Avis de la mission avec ',
	from: 'du',
	reviewSent: 'Avis envoyé',
	tellUsMore: 'Dites nous en plus',
	sendReview: 'Envoyer l\'avis',
}

export const EN = {
	satisfactionLevels: {
		0: 'very unsatisfied',
		1: 'unsatisfied',
		2: 'ok',
		3: 'satisfied',
		4: 'very satisfied'
	},
	questions: {
		[qt.PROFESSIONALISM]: 'Professionalism',
		[qt.PUNCTUALITY]: 'Punctuality',
		[qt.COMPETENCE]: 'Competence',
		[qt.CLIENT_RAPPORT]: 'Client rapport',
		[qt.COMMENT_ABOUT_FREELANCER_PUBLIC]: 'Leave a public comment for the freelancer',
		[qt.COMMENT_ABOUT_STUDIO_PUBLIC]: 'Leave a public comment for the studio',
		[qt.COMMENT_ABOUT_FREELANCER_PRIVATE]: `Is there something you'd like to share in private about your experience? (the response will not be shared with the freelancer)`,
		[qt.COMMENT_ABOUT_STUDIO_PRIVATE]: `Is there something you'd like to share in private about your experience? (the response will not be shared with the studio)`,
		[qt.RECOMMEND_TO_COLLEAGUES]: `I'd recommend working here to my colleagues`,
		[qt.COMMUNCATION]: `Communication`,
		[qt.MORALE]: `Morale`,
	},
	reviews: 'Reviews',
	myReviews: 'My Reviews',
	myReview: 'My Review',
	reviewsFromStudio: 'Review from studio',
	reviewFromStudio: 'Review from studio',
	reviewsFromFreelance: 'Review from freelancer',
	reviewFromFreelance: 'Review from freelancer',
	leaveReviewForStudio: 'Leave a review for the studio',
	leaveReviewForFreelance: `Leave a review for the freelancer`,
	thanks: 'Thanks for your review',
	reviewOfMission: 'Review of mission with',
	from: 'from',
	reviewSent: 'Review sent',
	tellUsMore: 'please tell us more',
	sendReview: 'Send review',
}

export default {
	fr: FR,
	us: EN,
}
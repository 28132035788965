import { fetchApi } from "containers/helpers";

export const getFreelances = () => fetchApi('/freelances')
export const getFreelanceBadges = (userId) => fetchApi('/userBadges/'+userId)
export const createUserBadge = (userId, name, intensity = 0) => fetchApi('/userBadge/'+userId, {
    method: 'POST',
    body: {
        name, intensity
    }
})
export const deleteUserBadge = (userId, name, intensity) => fetchApi('/userBadge/'+userId, {
    method: 'DELETE',
    body: {
        name, intensity
    }
})
import React from 'react';
import { useContext, useMemo, useState } from 'react'
import FormComponent from '../Form/Form'
import classNames from 'classnames'
import Image from 'components/Image/Image'
import { Store } from '../../store/store'
import classes from './UnAuthLayout.module.scss'
import Typography from 'components/Typography'
import AuthFormHeader from './AuthFormHeader';

const LeftSide = ({
    page = 'login',
    message = '',
    inputs,
    sendForm: sendFormFromParams,
    messageLinkeable,
    submitMessage,
}) => {
    const {
        state: {
            language: { login: copy },
        },
    } = useContext(Store)
    const [loading, setLoading] = useState(false)
    const sendForm = async (params) => {
        setLoading(true)
        try {
            await sendFormFromParams(params)
        } catch (error) {
            console.log('error', error)
        } finally {
            setLoading(false)
        }
    }

    const loginOrSignUpPage = useMemo(() => page === 'login' || page === 'signup' ? (
        <AuthFormHeader
            page={page}
            copy={copy}
        />
    ) : (
        <Typography variant='text'>
            {message}
        </Typography>
    ), [page, copy, message])

    return (
        <div className={classNames(classes.fullHeight, classes.leftSideContainer)} >
            <div className={classes.imageContainer}>
                <Image fullHeight centered size='small' link='/' alt='Logo' src={'/assets/styleep-logo.png'} />
            </div>
            <div className={classNames(classes.actionContainer, classes.centeredContainer)}>
                <div>
                    <div className={classes.switchSignContainer}>
                        <div >
                            {loginOrSignUpPage}
                        </div>
                    </div>
                    <div>
                        {page === 'confirmAccount' || page === 'reset' || page === 'forgot' ? <div >
                            <FormComponent
                                responsive={false}
                                segment={false}
                                inputs={inputs}
                                sendForm={sendForm}
                                messageLinkeable={messageLinkeable}
                                messages={{ submit: submitMessage }}
                                loading={loading}
                                noConfirmationMailLang={
                                    copy.noConfirmationMail
                                }
                            />
                        </div> : (
                            <div >
                                <FormComponent
                                    responsive={false}
                                    segment={false}
                                    inputs={inputs}
                                    sendForm={sendForm}
                                    messageLinkeable={messageLinkeable}
                                    messages={{ submit: submitMessage }}
                                    loading={loading}
                                    noConfirmationMailLang={
                                        copy.noConfirmationMail
                                    }
                                />
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default LeftSide;
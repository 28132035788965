export const FR = {
  creationDate: "Date de création de votre activité",
  declarationType: "J'ai opté pour une déclaration par ",
  declarationTypeChoices: {
    quartely: "Trimestre",
    monthly: "Mensuelle",
  },
  contract: "Contrat",
  mission: "Mission",
  sum: "Somme",
  start_date: "Date de début de mission",
  end_date: "Date de fin de mission",
  payment_date: "Date d'émission du paiement",
  tva: "Suis-je soumis à la TVA ?",
  quarter: 'Trimestre',
  quarters: [
    'Tous les trimestres',
    'Premier trimestre',
    'Deuxième trimestre',
    'Troisième trimestre',
    'Quatrième trimestre'
  ],
  month: 'Mois',
  months: [
    'Tous les mois', 'Janvier', 'Février', 'Mars', 'Avril', 'Mai', 'Juin', 'Juillet', 'Août', 'Septembre', 'Octobre', 'Novembre', 'Décembre'
  ],
  year: 'Année',
  allYears: 'Toutes les années',
  selectYear: 'Choisissez une année pour pouvoir selectionner la période.',
  navigateToContract: 'Voir le contrat',
  navigateToMission: 'Voir la mission',
  transactionList: 'Liste des transactions'
}

export const EN = {
  creationDate: "Creation date of your activity",
  declarationType: "I have choose my declaration period by ",
  declarationTypeChoices: {
    quartely: "Quarter",
    monthly: "Month",
  },
  contract: "Contract",
  mission: "Mission",
  sum: "Sum",
  start_date: "Date of beginning of mission",
  end_date: "End date of mission",
  payment_date: "Date of Bank transfer",
  tva: "TVA ?",
  quarter: 'Quarter',
  quarters: [
    'All quarters',
    'First quarter',
    'Second quarter',
    'Third quarter',
    'Fourth quarter'
  ],
  month: 'Month',
  months: [
    'All months', 'January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'
  ],
  year: 'Year',
  allYears: 'All years',
  selectYear: 'Please choose a year to select a period.',
  navigateToContract: 'See contract',
  navigateToMission: 'See mission',
  transactionList: 'Transaction list'

}

export default {
  fr: FR,
  us: EN,
}

import { UserTypes } from 'constants/UserTypes';
import { toDateInput } from 'containers/level/LevelUp/dates';
import { upperFirst } from 'lodash';
import React, { useMemo } from 'react';
import StatusUserOnboardComponent from './StatusUserOnboardComponent';
import MyTable from 'components/MyTable/MyTable';

const UserOnboardListComponent = ({
    users
}) => {
    const columns = [
        { title: 'ID', field: 'id', type: "numeric" },
        { title: 'Type', render: ({ type }) => Object.keys(UserTypes)[type] },
        { title: 'Email', render: ({ email }) => email },
        { title: 'Téléphone', render: ({ phone }) => phone },
        { title: 'GoogleConnect', render: ({ is_google_oauth }) => is_google_oauth ? 'Google connect' : 'NON' },
        {
            title: 'Nom',
            render:
                ({ type, firstname, lastname, studio_name, company_name }) =>
                    type === UserTypes.FREELANCE ? `${upperFirst(firstname)} ${upperFirst(lastname)} - ${upperFirst(company_name)}`
                        : studio_name
        },
        { title: 'Status', render: (user) => <StatusUserOnboardComponent {...user} /> },
        { title: 'Créé le', render: ({ created_at }) => toDateInput(created_at) },
        { title: 'Level', render: ({ level }) => level != null ? level : 'C\'est un studio' },
    ]

    const datas = useMemo(() => users, [users])

    return (
        <MyTable
            columns={columns}
            data={datas}
            title={'Utilisateurs'}
            options={{ paging: false, }}
        />
    );
};

export default UserOnboardListComponent;
export const FR = {
  title: "Paiement de l'abonnement",
  card:  {
    title: 'carte bancaire',
  },
  sepa: {
    title:         'sepa',
    authorization: 'En fournissant votre IBAN et en confirmant ce paiement, vous autorisez Klack SAS et Stripe, notre prestataire de services de paiement, à envoyer des instructions à votre banque pour débiter votre compte conformément à ces instructions. Vous avez droit à un remboursement de votre banque selon les termes et conditions de votre accord avec votre banque. Un remboursement doit être demandé dans les huit semaines à compter de la date de débit de votre compte.',
  },
  plan: {
    pro_monthly: {
      duration: 'mois',
    },
    premium_monthly: {
      duration: 'mois',
    },
    pro_annual: {
      duration: 'an',
    },
    premium_annual: {
      duration: 'an',
    },
    unlimited_monthly: {
      duration: 'mois',
    },
    unlimited_annual: {
      duration: 'an',
    },
  },
  success: {
    title:         'Vous êtes passé __PLAN__ !',
    subtitle:      'Vous pouvez accéder à tous les types d\'études KLACK',
    goToDashboard: 'Accéder à mon tableau de bord',
    createNew:     'Créer une nouvelle étude',
  },
  professional:           'Professionnel',
  unlimited:              'Illimité',
  payLater:               'Payer plus tard',
  subtitle:               'Paiement de l\'étude',
  topRight:               'Étape 7/7',
  titleCentered:          'Moyen de paiement',
  registeredCardTitle:    'Choisir une de mes cartes enregistrées',
  registeredCardSubtitle: 'Le bouton de paiement s\'activera lorsque vous sélectionnerez une de vos cartes ci-dessous.',
  newCardTitle:           'Ajouter un nouveau moyen de paiement',
  newCardSubtitle:        'Le bouton de paiement s\'activera lorsque vous indiquerez un moyen de paiement valide',
  saveCardText:           'Je souhaite enregistrer ma carte pour les prochaines commandes',
  buttonPayNewCard:       'Payer avec cette carte',
  buttonPayCard:          'Payer avec la carte',
  buttonTest:             'Passer à l\'étape suivante',
  HT:                     'HT',
  TTC:                    'TTC',
  modalTitle:             'Paiement en cours de traitement',
  modalDescription:       'votre paiement est en cours de traitement, vous recevrez un mail lorsque celui-ci sera traité',
  modalButtonText:        'allez vers la page Home',
  bankTransfer: {
    selectionButton: 'Virement bancaire',
    sendButton: 'J\'ai effectué le virement bancaire'
  }

}

export const EN = {
  title: 'Subscription payment',
  card:  {
    title: 'card',
  },
  sepa: {
    title:         'Sepa',
    authorization: 'By providing your IBAN and confirming this payment, you are authorizing Klack SAS and Stripe, our payment service provider, to send instructions to your bank to debit your account in accordance with those instructions. You are entitled to a refund from your bank under the terms and conditions of your agreement with your bank. A refund must be claimed within eight weeks starting from the date on which your account was debited.',
  },
  plan: {
    pro_monthly: {
      duration: 'month',
    },
    premium_monthly: {
      duration: 'month',
    },
    pro_annual: {
      duration: 'year',
    },
    premium_annual: {
      duration: 'year',
    },
    unlimited_monthly: {
      duration: 'month',
    },
    unlimited_annual: {
      duration: 'year',
    },
  },
  success: {
    title:         'You\'re __PLAN__ !',
    subtitle:      'You can access to all surveys types.',
    goToDashboard: 'Access to my dashboard',
    createNew:     'Create a new survey',
  },
  professional:           'Professional',
  unlimited:              'Unlimited',
  payLater:               'Pay later',
  subtitle:               'Campaign payment',
  topRight:               'Step 7/7',
  titleCentered:          'Payment method',
  registeredCardTitle:    'Use a registered card',
  registeredCardSubtitle: 'The payment button will be actived when you select one of your card',
  newCardTitle:           'Add a new payment method',
  newCardSubtitle:        'The payment button will be actived when you enter a payment method',
  saveCardText:           'I want to save my card to future orders',
  buttonPayNewCard:       'Pay with this card',
  buttonPayCard:          'Pay with the card',
  buttonTest:             'Next step',
  HT:                     'excl. taxes',
  TTC:                    'taxes incl.',
  modalTitle:             'Payment in process',
  modalDescription:       'Your payment is being processed, you will receive an email when it is processed',
  modalButtonText:        'Go to the Home page',
  bankTransfer: {
    selectionButton: 'Bank transfer',
    sendButton: 'I made the bank transfer'
  }
}

export default {
  fr: FR,
  us: EN,
}

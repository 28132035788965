import React from 'react'
import { Message } from 'semantic-ui-react'

function MeetingPlannedFor({ meeting }) {
	return (
		<Message floating>
			Meeting planifié pour {new Date(meeting.start).toLocaleString('fr')}
		</Message>
	)
}

export default MeetingPlannedFor
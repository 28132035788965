import { fetchApi } from "containers/helpers";

export const getFreelanceHistory = userId => fetchApi('/freelance/history/'+userId)
export const getUserLevelsReference = () => fetchApi('/userLevels/reference')
export const levelUpAPI = (userId, level) => fetchApi('/freelance/levelUp/'+userId, {
    body: {
        level
    }
})
export const levelDownAPI = (userId, level) => fetchApi('/freelance/levelDown/'+userId, {
    body: {
        level
    }
})

export const defineInitialLevel = (userId, level) => fetchApi('/freelance/level/'+userId, {
    method: 'PATCH',
    body: {
        level
    }
})
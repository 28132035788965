import RightSide from 'components/RightSide';
import TitlePage from 'components/TitlePage';
import React from 'react';
import LastEmailsSent from './LastEmailsSent';
import SendEmailToUsers from './SendEmailToUsers';

const SendEmailPage = () => {
    return (
        <RightSide>
            <TitlePage>
                Envoie des emails
            </TitlePage>
            <SendEmailToUsers />
            <LastEmailsSent />
        </RightSide>
    );
};

export default SendEmailPage;
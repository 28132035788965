import RightSide from 'components/RightSide';
import React, { useMemo } from 'react';
import { useContract } from 'hooks/useContract';
import { useParams, useNavigate } from 'react-router-dom';
import UnlockFundsComponent from './UnlockFundsComponent';
import columns from 'containers/issue/IssuesAdminPage/columns';
import MyTable from 'components/MyTable/MyTable';
import TitlePage from 'components/TitlePage';
import { statuses as issueStatuses } from 'containers/issue/constants/issue.enum';

const UnlockFunds = () => {
    const navigate = useNavigate()
    const contractId = Number(useParams().contractId)
    const { contract, setContract, issues, setIssues } = useContract(contractId)

    const isIssueOpen = useMemo(() => !!issues
        .find(({ status: issueStatus }) => issueStatus !== issueStatuses.CLOSE)
        , [issues])
    return (
        <RightSide>
            <TitlePage>Débloquer les fonds de la mission</TitlePage>
            {isIssueOpen ? <MyTable options={{
                paging: false
            }} 
            title={'Liste des issues'} 
            data={issues} 
            columns={columns}
            onRowClick={(event, rowData) => navigate(`/issue/${rowData.id}`)}
             />
                : contract && <UnlockFundsComponent contract={contract} />}
        </RightSide>
    );
};

export default UnlockFunds;
import MyTable from "components/MyTable/MyTable";
import { dayStatuses } from "constants/DayStatuses";
import useLanguage from "hooks/useLanguage";
import React, { useMemo } from "react";

const MissionTable = ({
	missions = []
}) => {
	const lang = useLanguage("statistics");

	const missionsColumns = useMemo(() => [
        { title: lang.id, render: ({ id }) => id },
        { title: lang.title, render: ({ title }) => title },
        { title: lang.status, render: ({ status }) => Object.keys(dayStatuses).find(key => dayStatuses[key] === status) },
        { title: lang.description, render: ({ description }) =>  description },
    ], [lang])

	return (
		<MyTable
			columns={missionsColumns}
			data={missions}
			options={{ paging: false }}
		/>
	)
};

export default MissionTable;

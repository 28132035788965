import { useEffect, useState, useCallback, useMemo } from 'react';
import { getMissionAppointmentReviews } from './helpers';
import useLanguage from 'hooks/useLanguage';
import { isEmpty } from 'lodash';
import { toast } from 'react-toastify';
import ReviewWrapper from './ReviewWrapper';

const MissionContractReviews = ({ appointmentId }) => {
	const lang = useLanguage('review');
	const [data, setData] = useState(null);

	useEffect(() => {
		const init = async () => {
			try {
				const res = await getMissionAppointmentReviews(appointmentId);
				res && setData(res);
			} catch (e) {
				toast.error(e.message);
			}
		};
		if (appointmentId) {
			init();
		}
	}, [appointmentId]);

	const injectLang = useCallback(
		(reviews) => reviews.map((review) => ({ ...review, text: lang.questions[review.text_key] })),
		[lang],
	);

	const isDefined = useMemo(
		() => !isEmpty(data) && (!isEmpty(data.reviewsStudio) || !isEmpty(data.reviewsFreelance)),
		[data],
	);

	return (
		isDefined && (
			<>
				<ReviewWrapper title={lang.reviewFromFreelance} reviews={injectLang(data.reviewsFreelance)} />
				{!!data.reviewsStudio.length && (
					<ReviewWrapper title={lang.reviewFromStudio} reviews={injectLang(data.reviewsStudio)} />
				)}
			</>
		)
	);
};

export default MissionContractReviews;

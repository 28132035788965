import React, { useMemo, useState } from 'react';
import { toast } from 'react-toastify';
import FormComponent from '../Form/Form';
import { updateContractPricesAPI } from './helpers';

const PriceContractUpdate = ({ contractId, prices }) => {
    const [loading, setLoading] = useState(false)
    const inputs = useMemo(() => [
        {
            type: 'number',
            name: 'price',
            placeholder: 'Prix de la journée',
            required: true,
            silentRequired: true,
            fluid: true,
        },
    ], []);
    const updateContractPrices = async ({ price }) => {
        try {
            setLoading(true)
            await updateContractPricesAPI(contractId, price)
            toast('Prix actualisé, veuillez actualiser la page afin d\'accéder au nouveau contrat')
            setLoading(false)
        } catch (error) {
            setLoading(false)
            toast('Erreur ' + error.message)
        }
    }
    return (
        <div>
            <h3>Modifier le prix du contrat journalier</h3>
            <FormComponent
                inputs={inputs}
                sendForm={updateContractPrices}
                loading={loading}
                centered={true}
                responsive={false}
            />
        </div>
    );
};

export default PriceContractUpdate;
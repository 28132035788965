/* eslint-disable import/no-anonymous-default-export */
import { createBrowserHistory } from 'history';
import jwtDecode from 'jwt-decode';
import { toDateInput } from 'modules/dates';
import { pathToRegexp } from 'path-to-regexp';

import { socket, socketRooms } from '../socket';

const headers = {
	'Access-Control-Request-Headers': '*',
	'Access-Control-Request-Method': '*',
	'Access-Control-Allow-Origin': '*',
};

const jsonHeaders = {
	Accept: 'application/json',
	'Content-Type': 'application/json',
};

export const getToken = () => localStorage.getItem('token');
export const getHeaders = (multiform = false) => ({
	...headers,
	...(multiform ? {} : jsonHeaders),
	'x-refresh-token': localStorage.getItem('refreshToken'), // await SecureStore.getItemAsync('refreshToken', {}),
	'x-access-token': localStorage.getItem('token'), // await SecureStore.getItemAsync('userToken', {}),
	'x-language': localStorage.getItem('language'),
});

export const catchGesture = ({ status }, navigate) => status === 403 && navigate('/home');
export const history = createBrowserHistory();

export const isObject = (elem) => typeof elem === 'object' && elem !== null;
export const logOut = async () => {
	setLoggedInCookie(0);
	localStorage.removeItem('token');
	localStorage.removeItem('tokenExpiration');
	localStorage.removeItem('refreshToken');
	localStorage.removeItem('refreshTokenExpiration');
	localStorage.removeItem('profile');
	console.log('socket disconnect before');
	socketRooms.map((room) => {
		if (socket) {
			socket.removeListener(room, (timestamp) => {
				console.log(timestamp);
			});
		}
	});
	if (socket) {
		socket.emit('forceDisconnect');
	}
	document.cookie = null;
	return history.push('/');
};
export const isTokenRegenerate = async (res, apiRoute, params) => {
	const body = (await res.json()) || {};
	res.message = body.message;
	if (isObject(res) && res.status) {
		if (body.message === 'regenerateTokens') {
			saveTokens(body.tokens, () => {});
			return fetchApi(apiRoute, params);
		}
		if (body.message === 'disconnect') {
			logOut();
		} else if (body.message === 'forbidden') {
			return history.push('/');
		}
	}
	return Promise.reject(res);
};

export const responseToJson = (res, apiRoute, params) => {
	if (res.ok) {
		return res.json();
	}
	return isTokenRegenerate(res, apiRoute, params);
};

export const apiUrl = process.env.REACT_APP_API_URL; // 'https://localhost:9000'
export const websiteUrl = process.env.REACT_APP_WEBSITE_URL; // 'https://localhost:8000'

const differenceDatesInSeconds = (d1, d2) => Math.abs(Math.round((d2.getTime() - d1.getTime()) / 1000));

export const setLoggedInCookie = (value, expiresIn = Date.now()) =>
	(document.cookie = `loggedIn=${value}; expires=${expiresIn}; path=/ ; max-age=${differenceDatesInSeconds(
		new Date(),
		new Date(expiresIn),
	)}`);

export const isLoggedIn = () =>
	!!(
		document.cookie &&
		document.cookie.split(';').reduce((cookies, str) => {
			const [key, value] = str.split('=').map((s) => s.trim());
			cookies[key] = value;
			return cookies;
		}, {}).loggedIn === '1'
	);

export const saveTokens = ({ token, refreshToken }) => {
	const user = jwtDecode(token.token);
	if (user) {
		setLoggedInCookie(1, token.expiresIn);
		localStorage.setItem('token', token.token);
		localStorage.setItem('tokenExpiration', refreshToken.expiresIn);
		localStorage.setItem('refreshToken', refreshToken.token);
		localStorage.setItem('refreshTokenExpiration', token.expiresIn);
		localStorage.setItem('profile', JSON.stringify(user));
		return token;
	}
	throw new Error('Token is no-readeable.');
};

export const fetchApi = async (route, init = null) => {
	const apiRoute = apiUrl + route;
	const params = !init
		? { headers: getHeaders() }
		: {
				method: init && init.body ? 'POST' : 'get',
				...init,
				mode: 'cors',
				headers: { ...getHeaders(init.multiform), ...init.headers },
				body: init.multiform ? init.body : JSON.stringify(init.body),
		  };
	return fetch(apiRoute, params).then((res) =>
		res.status !== 204 ? responseToJson(res, route, !init ? null : params) : null,
	);
};

export const isNull = (value) =>
	value === null || value === undefined || value === 'NULL' || value === 'undefined' || value === 'null';

export const isFalse = (value) => isNull(value) || value === false;

export const getImage = (imageId) => fetchApi(`/image/${imageId}`);

export const updateApi = (route, obj) => {
	fetchApi(route, {
		method: 'PATCH',
		body: { ...obj },
	});
};

export const updateLanguage = (language) =>
	fetchApi('/user/language', {
		method: 'PATCH',
		body: { language },
	});

export const capitalizeFirstLetter = (string) => (string ? string.charAt(0).toUpperCase() + string.slice(1) : '');

export const unCapitalizeFirstLetter = (string) => (string ? string.charAt(0).toLowerCase() + string.slice(1) : '');

export function round(value, precision) {
	const multiplier = Math.pow(10, precision || 0);
	return Math.round(value * multiplier) / multiplier;
}

export const roundUpToNextTen = (value) => (value % 10 === 0 ? value : (Math.floor(value / 10) + 1) * 10);
export const roundUpToLastTen = (value) => (value % 10 === 0 ? value : Math.floor(value / 10) * 10);
export const strToLineBreak = (str) => (
	<div>
		{str
			.split('.')
			.filter((e) => e)
			.map((sentence) => `${sentence}.`)
			.map((sentence, index) => (
				<div key={index}>{sentence}</div>
			))}
	</div>
);

export const getUser = () => {
	const data = JSON.parse(localStorage.getItem('profile')) || {};
	data.icon = Number(data.icon);
	return data || {};
};

export const deleteUser = (userId) => fetchApi(`/user/${userId}`, { method: 'DELETE' });

export const getEnums = () => fetchApi('/enums');

export const urlParametersToJson = (search) =>
	search
		.replace('?', '')
		.split('&')
		.reduce((prev, actual) => {
			const [key, value] = actual.split('=');
			return {
				...prev,
				[key]: value,
			};
		}, {});

export const userRoles = {
	USER: 0,
	ADMIN: 1,
	SUPERADMIN: 2,
	GUEST: 3,
};

export const collaboratorRoles = {
	DG: 0,
	DIRECTOR: 1,
	MANAGER: 2,
	EXECUTANT: 3,
	COLLABORATOR: 4,
	PRESTA: 5,
	INTERN: 6,
};

export const exceptAccessibilityPath = {
	home: null,
	profile: null,
	user: [userRoles.GUEST, userRoles.USER],
	support: null,
	mail: [userRoles.GUEST],
	locker: null,
	spot: [userRoles.GUEST],
	leenks: null,
	docx: null,
	task: null,
	meet: [userRoles.GUEST],
	calendar: null,
	notes: [userRoles.GUEST],
	notification: null,
	setting: [userRoles.GUEST, userRoles.USER],
};

export const parentsPaths = {
	home: '/',
	profile: '/profile',
	support: '/support',
	mail: '/mail',
	locker: '/locker',
	spot: '/spots/setting',
	leenks: '/leenk',
	docx: '/docx',
	task: '/task',
	meet: '/meet',
	calendar: '/calendar',
	notes: '/notes',
	notification: '/notification',
	setting: '/setting',
};

export const getLoginPathsIndexes = () => ({
	home: {
		href: '/',
		parent: parentsPaths.home,
		exceptAccessibility: exceptAccessibilityPath.home,
		get regex() {
			return pathToRegexp(this.href);
		},
	},
	profile: {
		href: '/profile',
		parent: parentsPaths.profile,
		exceptAccessibility: exceptAccessibilityPath.profile,
		get regex() {
			return pathToRegexp(this.href);
		},
	},
	userProfile: {
		href: '/user/:userId',
		parent: parentsPaths.setting,
		exceptAccessibility: exceptAccessibilityPath.userProfile,
		get regex() {
			return pathToRegexp(this.href);
		},
	},
	profileUpdate: {
		href: '/profile/update',
		parent: parentsPaths.profile,
		exceptAccessibility: exceptAccessibilityPath.profile,
		get regex() {
			return pathToRegexp(this.href);
		},
	},
	support: {
		href: '/support',
		parent: parentsPaths.support,
		exceptAccessibility: exceptAccessibilityPath.support,
		get regex() {
			return pathToRegexp(this.href);
		},
	},
	homePrincipal: {
		href: '/home',
		parent: parentsPaths.home,
		exceptAccessibility: exceptAccessibilityPath.home,
		get regex() {
			return pathToRegexp(this.href);
		},
	},
	subscription: {
		href: '/subscription/payment/:plan',
		parent: parentsPaths.plan,
		exceptAccessibility: exceptAccessibilityPath.plan,
		get regex() {
			return pathToRegexp(this.href);
		},
	},
	mail: {
		href: '/mail',
		parentsPaths: parentsPaths.mail,
		exceptAccessibility: exceptAccessibilityPath.mail,
		get regex() {
			return pathToRegexp(this.href);
		},
	},
	locker: {
		href: '/locker',
		parentsPaths: parentsPaths.locker,
		exceptAccessibility: exceptAccessibilityPath.locker,
		get regex() {
			return pathToRegexp(this.href);
		},
	},
	folder: {
		href: '/locker/folder/:folderId',
		parentsPaths: parentsPaths.locker,
		exceptAccessibility: exceptAccessibilityPath.locker,
		get regex() {
			return pathToRegexp(this.href);
		},
	},
	spots: {
		href: '/spots/:floorId',
		parentsPaths: parentsPaths.spot,
		exceptAccessibility: exceptAccessibilityPath.spot,
		get regex() {
			return pathToRegexp(this.href);
		},
	},
	leenks: {
		href: '/leenk',
		parentsPaths: parentsPaths.leenks,
		exceptAccessibility: exceptAccessibilityPath.leenks,
		get regex() {
			return pathToRegexp(this.href);
		},
	},
	leenkInvite: {
		href: '/leenk/invite/:leenkId',
		parentsPaths: parentsPaths.leenks,
		exceptAccessibility: exceptAccessibilityPath.leenks,
		get regex() {
			return pathToRegexp(this.href);
		},
	},
	leenk: {
		href: '/leenk/:leenkId',
		parentsPaths: parentsPaths.leenks,
		exceptAccessibility: exceptAccessibilityPath.leenks,
		get regex() {
			return pathToRegexp(this.href);
		},
	},
	docx: {
		href: '/docx/:id',
		parentsPaths: parentsPaths.docx,
		exceptAccessibility: exceptAccessibilityPath.docx,
		get regex() {
			return pathToRegexp(this.href);
		},
	},
	tasks: {
		href: '/task',
		parentsPaths: parentsPaths.task,
		exceptAccessibility: exceptAccessibilityPath.task,
		get regex() {
			return pathToRegexp(this.href);
		},
	},
	task: {
		href: '/task/:taskId',
		parentsPaths: parentsPaths.task,
		exceptAccessibility: exceptAccessibilityPath.task,
		get regex() {
			return pathToRegexp(this.href);
		},
	},
	meet: {
		href: '/meet/:meetId',
		parentsPaths: parentsPaths.meet,
		exceptAccessibility: exceptAccessibilityPath.meet,
		get regex() {
			return pathToRegexp(this.href);
		},
	},
	meetFolders: {
		href: '/meet/:meetId/folder',
		parentsPaths: parentsPaths.meetFolders,
		exceptAccessibility: exceptAccessibilityPath.meetFolders,
		get regex() {
			return pathToRegexp(this.href);
		},
	},
	meetFolder: {
		href: '/meet/:meetId/folder/:folderId',
		parentsPaths: parentsPaths.meetFolder,
		exceptAccessibility: exceptAccessibilityPath.meetFolder,
		get regex() {
			return pathToRegexp(this.href);
		},
	},
	calendar: {
		href: '/calendar',
		parentsPaths: parentsPaths.calendar,
		exceptAccessibility: exceptAccessibilityPath.calendar,
		get regex() {
			return pathToRegexp(this.href);
		},
	},
	notes: {
		href: '/notes',
		parentsPaths: parentsPaths.notes,
		exceptAccessibility: exceptAccessibilityPath.notes,
		get regex() {
			return pathToRegexp(this.href);
		},
	},
	note: {
		href: '/note/:noteId',
		parentsPaths: parentsPaths.notes,
		exceptAccessibility: exceptAccessibilityPath.notes,
		get regex() {
			return pathToRegexp(this.href);
		},
	},
	notification: {
		href: '/notification',
		parentsPaths: parentsPaths.notification,
		exceptAccessibility: exceptAccessibilityPath.notification,
		get regex() {
			return pathToRegexp(this.href);
		},
	},
	settings: {
		href: '/setting',
		parentsPaths: parentsPaths.setting,
		exceptAccessibility: exceptAccessibilityPath.setting,
		get regex() {
			return pathToRegexp(this.href);
		},
	},
	settingUser: {
		href: '/setting/user/:userId',
		parentsPaths: parentsPaths.setting,
		exceptAccessibility: exceptAccessibilityPath.setting,
		get regex() {
			return pathToRegexp(this.href);
		},
	},
});

export const getloginPaths = () => {
	const paths = getLoginPathsIndexes();
	const obj = {};
	Object.keys(paths).map((key) => (obj[key] = paths[key].href));
	return obj;
};

export const getPathsAccessibility = () => {
	const paths = getLoginPathsIndexes();
	const obj = {};
	Object.keys(paths).map((key) => (obj[key] = paths[key].exceptAccessibility));
	return obj;
};

export const isAccessible = (exceptAccessibility) =>
	exceptAccessibility ? !exceptAccessibility.find((excepted) => excepted === getUser().role) : true;

export const createDatabase = ({ email, name }) =>
	fetchApi('/database', {
		method: 'POST',
		body: {
			email,
			name,
		},
	});

export const deleteDatabase = ({ code }) =>
	fetchApi(`/database/${code}`, {
		method: 'DELETE',
	});
export const getUserFromApi = (userId) => fetchApi(`/users/${userId}`);
export const blockAccountAPI = (userId) => fetchApi(`/user/block/${userId}`, { method: 'PATCH' });
export const unlockAccountAPI = (userId) => fetchApi(`/user/unlock/${userId}`, { method: 'PATCH' });
export const unsubscribeApi = (userId) => fetchApi('/stripe/cancelSubscription', { method: 'PATCH', body: { userId } });

export const getUsersConnected = () => fetchApi('/users/connected');
export const messageFileTypes = {
	IMAGE: 0,
	AUDIO: 1,
	FILE: 2,
	DOCX: 3,
	PDF: 4,
};

export const getFileTypeFromExtension = (extension) => {
	switch (extension) {
		case 'png':
		case 'PNG':
		case 'jpeg':
		case 'jpg':
		case 'JPG':
			return messageFileTypes.IMAGE;
		case 'docx':
			return messageFileTypes.DOCX;
		case 'pdf':
			return messageFileTypes.PDF;
		case 'ogg':
			return messageFileTypes.AUDIO;
		default:
			return messageFileTypes.FILE;
	}
};

export const getParamsFromUrl = (search) =>
	search.substring(1)
		? JSON.parse(
				`{"${decodeURI(search.substring(1)).replace(/"/g, '\\"').replace(/&/g, '","').replace(/=/g, '":"')}"}`,
		  )
		: {};

export const updateSocketId = (socketId) =>
	fetchApi('/user/socketid', {
		method: 'PATCH',
		body: {
			socketId,
		},
	});

export const updateUser = (toUpdate) =>
	fetchApi('/user', {
		method: 'PATCH',
		body: toUpdate,
	});

export const reverseString = (str) => [...str].reverse().join('');

export const getFileExtension = (filename) => {
	const reversed = reverseString(filename);
	return reverseString(reversed.substr(0, reversed.indexOf('.'))).replaceAll(' ', '');
};

export const objectToFormData = (obj, rootName, ignoreList) => {
	const formData = new FormData();

	function appendFormData(data, root) {
		if (!ignore(root)) {
			root = root || '';
			if (data instanceof File) {
				formData.append(root, data);
			} else if (Array.isArray(data)) {
				for (let i = 0; i < data.length; i++) {
					appendFormData(data[i], root + '[' + i + ']');
				}
			} else if (typeof data === 'object' && data) {
				for (const key in data) {
					if (data.hasOwnProperty(key)) {
						if (root === '') {
							appendFormData(data[key], key);
						} else {
							appendFormData(data[key], root + '.' + key);
						}
					}
				}
			} else {
				if (data !== null && typeof data !== 'undefined') {
					formData.append(root, data);
				}
			}
		}
	}

	function ignore(root) {
		return (
			Array.isArray(ignoreList) &&
			ignoreList.some(function (x) {
				return x === root;
			})
		);
	}

	appendFormData(obj, rootName);

	return formData;
};

export const roundTo2Dec = (nbr) => Math.round((nbr + Number.EPSILON) * 100) / 100;
export const roundTo3Dec = (nbr) => Math.round((nbr + Number.EPSILON) * 1000) / 1000;
export const getImageUrl = (imageId) => apiUrl + `/image/visualisation/${imageId}?${global.Date.now()}`;
export const getImageUrlWithName = (filename) => apiUrl + `/image/name/visualisation/${filename}?${global.Date.now()}`;
export const getFileByIdVisualisationUrl = (token, fileId) => `${apiUrl}/file/visualisation/${fileId}/${token}`;
export const getFileByNameVisualisationUrl = (token, filename) =>
	apiUrl + `/file/name/visualisation/${token}/${filename}`;

export const getBadges = () => fetchApi('/badges');

export const isNumber = (nbr) => /^[0-9]+$/.test(String(nbr));
export const getMeFromApi = () => fetchApi('/user');
export const getObjectNextValue = (obj, currentKey, next = 1) =>
	Object.values(obj)[Object.keys(obj).indexOf(currentKey) + next];
export const isDateFormat = (str) => /^([0-9\-/]+)$/.test(String(str));
export const isSameValueOrUndefined = (objectToCompare, key, value) => {
	return (
		objectToCompare[key] == value ||
		value === null ||
		(isDateFormat(value) && toDateInput(objectToCompare[key]) === value)
	);
};
export const getOnlyUpdatedValues = (objectToCompare, values) =>
	Object.entries(values).reduce(
		(prev, [key, value]) =>
			isSameValueOrUndefined(objectToCompare, key, value)
				? prev
				: {
						...prev,
						[key]: value,
				  },
		{},
	);

export const isFieldEmpty = (field) => field == null || field === '';

export const getKeyFromValue = (valueSearch, obj) =>
	(Object.entries(obj).find(([key, value]) => value === valueSearch) || [''])[0];

// try to put in useLanguage
// 	export const isObjectOfObject = obj => !!Object.entries(obj).find(([key, value]) => isObject(value))
// const isUserTypeObject = obj => {
// 	const keys = Object.keys(obj)
// 	if (keys.length !== 2) {
// 		return false
// 	}
// 	return keys.length === 2
// 		&& keys.includes(UserTypes.FREELANCE)
// 		&& keys.includes(UserTypes.STUDIO)
// }

// export const getObjectByUserType = (obj, type) =>
// 	Object.entries(obj).reduce(
// 		(prev, [key, value]) => ({
// 			...prev,
// 			[key]: isObject(value) ?
// 				isObjectOfObject(value)
// 					? getObjectByUserType(value, type)
// 					: isUserTypeObject(value[type]) ? value[type] : value : value,
// 		}),
// 		{}
// 	)
export const getFile = (fileId) => fetchApi('/file/' + fileId);
export const toIbanFormat = (ibanStr = '') =>
	ibanStr
		.replace(/[^\dA-Z]/g, '')
		.replace(/(.{4})/g, '$1 ')
		.trim();

export const getObjectByUserType = (obj, type) =>
	Object.entries(obj).reduce(
		(prev, [key, value]) => ({
			...prev,
			[key]: isObject(value) ? value[type] : value,
		}),
		{},
	);

export const numberToText = (number) => {
	switch (number) {
		case 0:
			return 'zero';
		case 1:
			return 'one';
		case 2:
			return 'two';
		case 3:
			return 'three';
		case 4:
			return 'four';
		case 5:
			return 'five';
		case 6:
			return 'six';
		case 7:
			return 'seven';
		case 8:
			return 'eight';
		case 9:
			return 'nine';
		case 10:
			return 'ten';
		default:
			return 'zero';
	}
};

export const getLastElemOfArr = (arr = []) => arr[arr.length - 1];

export default {
	numberToText,
	getObjectByUserType,
	getKeyFromValue,
	isFieldEmpty,
	isLoggedIn,
	fetchApi,
	responseToJson,
	setLoggedInCookie,
	saveTokens,
	catchGesture,
	getImage,
	apiUrl,
	capitalizeFirstLetter,
	unCapitalizeFirstLetter,
	round,
	roundUpToNextTen,
	roundUpToLastTen,
	strToLineBreak,
	getUser,
	isObject,
	parentsPaths,
	getLoginPathsIndexes,
	getloginPaths,
	isNull,
	isFalse,
	userRoles,
	getPathsAccessibility,
	isAccessible,
	collaboratorRoles,
	getEnums,
	getToken,
	getUsersConnected,
	messageFileTypes,
	getFileTypeFromExtension,
	getParamsFromUrl,
	updateSocketId,
	updateUser,
	reverseString,
	getFileExtension,
	objectToFormData,
	roundTo2Dec,
	roundTo3Dec,
	getImageUrl,
	getImageUrlWithName,
	getBadges,
	getMeFromApi,
	isNumber,
	getObjectNextValue,
	isSameValueOrUndefined,
	getOnlyUpdatedValues,
	isDateFormat,
	getUserFromApi,
	getLastElemOfArr,
};

import { useState, useRef, useContext, useMemo } from 'react'
import useRemoteData from 'hooks/useRemoteData'
import { Modal, Input, Button } from 'semantic-ui-react'
import DataActions from 'components/DataActions'
import fetchApi from 'common/utils/api/fetchApi'
import { useParams, Link } from 'react-router-dom'
import Error from 'common/components/Error'
import Loader from 'common/components/Loader'
import { updateApi } from 'containers/helpers'
import getUser from 'common/utils/user/getUser'
import RightSide from 'components/RightSide'
import { toast } from 'react-toastify'
import { Store } from 'store/store'
import AdminMeetingInfos from 'components/AdminMeetingInfos'
import { updateMeeting } from './helpers'
import ExternalLink from 'components/ExternalLink'

const actionKeys = {
	ASSIGN_TO_ME: 'ASSIGN_TO_ME',
	SEND_USER_INFORMATION: 'SEND_USER_INFORMATION',
	RESCHEDULE: 'RESCHEDULE',
	CANCEL: 'CANCEL',
	UNASSIGN_FROM_ME: 'UNASSIGN_FROM_ME',
}

const actions = [
	{
		key: actionKeys.ASSIGN_TO_ME,
		label: 'Assign to Me',
		action: (meeting) => {
			const { id } = getUser()
			updateApi('/meetings', { ids: [meeting.id], admin: id })
		},
	},
	// {
	// 	key: actionKeys.RESCHEDULE,
	// 	label: 'Reschedule',
	// 	action: (meeting, newStartTime) => {
	// 		const newEndTime = addMinutes(newStartTime, 30)
	// 		updateApi('/meetings', {
	// 			ids: [meeting.id],
	// 			start: newStartTime,
	// 			end: newEndTime,
	// 		})
	// 	},
	// },
	{
		key: actionKeys.CANCEL,
		label: 'Cancel',
		action: (meeting) => {
			fetchApi(`/meeting/${meeting.id}`, { method: 'DELETE' })
				.then(() => {
					toast(`You've succesfully canceled meeting ${meeting.id}`)
					if (meeting.user) {
						// notif({
						// 	data: { start: meeting.start },
						// 	type: NotificationTypes.CANCEL_USER_MEETING,
						// })
					}
				})
				.catch((e) => {
					console.error(e)
					toast.error(`Unable to cancel meeting ${meeting.id}`)
				})
		},
	},
	{
		key: actionKeys.UNASSIGN_FROM_ME,
		label: 'Unassign from Meeting',
		action: (meeting) => {
			const { id } = getUser()
			if (id === meeting.admin)
				updateApi(`/meetings/`, { ids: [meeting.id], admin: null })
		},
	},
]

function ContactCard({ id, firstname, lastname, gender, icon, phone, email }) {
	return (
		<div>
			{icon && <img alt='profile picture' src={icon} />}
			<p>
				<strong>First Name</strong>: <span>{firstname}</span>
			</p>
			<p>
				<strong>Last Name</strong>: <span>{lastname}</span>
			</p>
			<p>
				<strong>Gender</strong>: <span>{gender}</span>
			</p>
			<br></br>
			<p>
				<strong>Phone</strong>: <span>{phone}</span>
			</p>
			<p>
				<strong>Email</strong>: <span>{email}</span>
			</p>
		</div>
	)
}

function AdminMeetingPage() {
	const { id } = useParams()
	const { state: { user: { id: userId } } } = useContext(Store)
	const { data: meeting, error, loading, setData: setMeeting } = useRemoteData(`/meeting/${id}`)
	const [notifModal, setModalOpen] = useState(false)
	const ref = useRef()

	const {
		data: contactInfo,
		error: contactError,
		loading: contactLoading,
	} = useRemoteData(meeting ? `/users/${meeting.user}` : null)
	const openModal = () => setModalOpen(true)
	const closeModal = () => setModalOpen(false)

	const sendLink = async () => {
		try {
			const link = ref.current.inputRef.current.value
			await updateMeeting(id, { link })
			setMeeting(meeting => ({
				...meeting,
				link
			}))
			toast('Lien envoyé')
			closeModal()
		} catch (error) {
			console.log('error', error)
		}
	}

	const filteredActions = useMemo(() => {
		if (!meeting) { return [] }
		const filtered = []
		if (meeting.admin && meeting.admin === userId) { filtered[actionKeys.ASSIGN_TO_ME] = true }
		if (meeting.admin !== userId) { filtered[actionKeys.UNASSIGN_FROM_ME] = true }
		return filtered
	}, [meeting, userId])

	const link = useMemo(() => meeting && meeting.link, [meeting])

	return (
		<RightSide>
			<div>
				<h1>Meeting Details</h1>
				{meeting && <Button color='blue' as={Link} to={`/user/${meeting.user}`}>
					Accéder aux infos de l'utilisateur pour actualiser son profil / statut
				</Button>}
				<div>
					<Button onClick={openModal}>
						{link ? 'Envoyer un nouveau lien' : 'Send User Meeting Link'}
					</Button>
					{link && <ExternalLink link={link} />}
				</div>
				<AdminMeetingInfos meeting={meeting} userId={userId} />
				<h2>Contact info</h2>
				{contactLoading ? (
					<Loader />
				) : contactError ? (
					<Error error={contactError} />
				) : (
					<ContactCard {...contactInfo} />
				)}
				<DataActions
					actions={actions}
					onAction={(action) => action(meeting)}
					filteredActions={filteredActions}
				/>
			</div>
			<Modal
				open={notifModal}
				closeIcon={true}
				closeOnEscape={true}
				closeOnDimmerClick={true}
				onClose={closeModal}
			>
				<Modal.Header>Send google meets link</Modal.Header>
				<Modal.Content>
					<Input ref={ref} fluid={true} placeholder='Meets link' />
				</Modal.Content>
				<Modal.Actions>
					<Button onClick={closeModal}>Cancel</Button>
					<Button primary onClick={sendLink}>
						Send Link
					</Button>
				</Modal.Actions>
			</Modal>
		</RightSide>
	)
}

export default AdminMeetingPage

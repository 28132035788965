import { Button } from 'semantic-ui-react'

const NewPageLinkButton = ({
    href,
    children
}) => {
    return (
        <Button as={'a'} href={href} target='_blank' rel="noreferrer">
            {children}
        </Button>
    )
}

export default NewPageLinkButton
import { toDateInput } from 'containers/level/LevelUp/dates';
import React, { useMemo } from 'react';
import DateTooltip from './DateTooltip';
import FreelanceInfos from './FreelanceInfos';
import CustomerInfos from './CustomerInfos.jsx';
import AppointmentButtons from './AppointmentButtons.jsx';
import { roundTo2Dec } from 'util/utils.js';
import { GLOBAL_STYLEEP_PERCENTILE } from 'constants/stripe.constant.js';

const AppointmentInfosBtoc = ({
	created_at,
	id: appointmentId,
	status,
	price,
	updated_at,
	users = {},
	days = {},
	prepayment,
}) => {
	const { customer, freelance } = useMemo(() => users || {}, [users]);

	return (
		<div>
			<div>
				dates: <DateTooltip dates={days} />
			</div>
			<div>id: {appointmentId}</div>
			<div>status: {status}</div>
			<div>price: {roundTo2Dec(price * (1 - GLOBAL_STYLEEP_PERCENTILE))}€HT (mais micro-entreprise donc TTC)</div>
			{/* <div>customer_receipt: {studio_receipt}</div> */}
			<div>created_at: {toDateInput(created_at)}</div>
			<div>updated_at: {toDateInput(updated_at)}</div>
			<div className="flex space-between">
				{freelance && (
					<div>
						<h3>Freelance</h3>
						<FreelanceInfos {...freelance} />
					</div>
				)}
				{customer && (
					<div>
						<h3>Client</h3>
						<CustomerInfos {...customer} />
					</div>
				)}
			</div>

			<AppointmentButtons
				status={status}
				appointment={appointmentId}
				prepayment={prepayment}
			/>
			{/* <MissionContractReviews appointmentId={appointmentId} /> */}
		</div>
	);
};

export default AppointmentInfosBtoc;

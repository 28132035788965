import { fetchApi } from 'containers/helpers';

export const launchChroneForTestAPI = (contractId = null) =>
	fetchApi('/admin/launchChroneTest' + (contractId ? '/' + contractId : ''));

export const getPrepaymentApi = (appointmentId) => fetchApi(`/prepayment/appointment/${appointmentId}`);

export const unlockFundsByAppointmentIdApi = (appointmentId) =>
	fetchApi(`/prepayment/reserve/${appointmentId}`, {
		method: 'PATCH',
	});

export const captureFundsByAppointmentIdApi = (appointmentId) =>
	fetchApi(`/prepayment/capture/${appointmentId}`, {
		method: 'PATCH',
	});


export const freelanceNotComeGestureApi = (appointmentId, hasCome) =>
	fetchApi(`/appointment/freelanceNotComeGesture/${appointmentId}`, {
		method: 'PATCH',
		body: { hasCome },
	});

export const getIssueFreelanceNotComeApi = (appointmentId, type) =>
	fetchApi(`/issue/appointment/${appointmentId}/${type}`);
import { fetchApi } from "containers/helpers";

export const getFreelancesForPotentialMission = (days, location) => fetchApi('/admin/freelances/search', {
    method: 'POST',
    body: {
        days,
        location
    }
})

export const getFreelancesForMission = (missionId) =>
	fetchApi('/freelances/search/' + missionId)

export const getFreelanceForMission = (freelanceId, missionId) =>
	fetchApi(`/freelance/search/${freelanceId}/mission/${missionId}`)

export const updateWorkingPropositionStatus = (
	missionId,
	dates,
	status,
	freelanceUserId
) =>
	fetchApi('/availables/status/' + missionId, {
		method: 'PATCH',
		body: {
			dates,
			status,
			freelanceUserId,
		},
	})
export const getDatesPrice = (freelanceId, dates) => fetchApi('/dates/price', {
	body: {
		freelanceId,
		dates//unix dates
	}
})

export const getLevels = () => fetchApi('/levels')

export const getFreelanceBadges = (userId) => fetchApi('/userBadges/'+userId)

export const getDaysCountReductions = () => fetchApi('/days/reductions')

export const getPercentile = (reductionPercentile) => {
	return reductionPercentile * 100 + '%'
}
import React from 'react'
import { Navigate, useLocation } from 'react-router-dom'
import { isLoggedIn /* , isAccessible */ } from '../containers/helpers'

export const AuthRoute = ({ children }) => {
	const location = useLocation()
	if (!isLoggedIn()) {
		localStorage.setItem('path', JSON.stringify(location))
	}
	if (isLoggedIn() /* && isAccessible(exceptAccessibility) */) {
		return children
	}
	return <Navigate replace to='/login' />
}

export const UnAuthRoute = ({ children }) =>
	!isLoggedIn() ? children : <Navigate replace to={{ pathname: '/' }} />

export const DefaultRoute = () => <Navigate to={isLoggedIn() ? '/' : 'login'} />

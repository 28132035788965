import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { getStudios } from '../helpers';
import PersonIcon from '@mui/icons-material/Person';
import { IconButton, Tooltip } from '@material-ui/core';
import RightSide from 'components/RightSide';
import TitlePage from 'components/TitlePage';
import StudioListComponent from './StudioListComponent';
import { useStoreUtils } from 'hooks/useStoreUtils';

const StudioList = () => {
	const {
        setTableLoadingOn,
        setTableLoadingOff
    } = useStoreUtils()
    const [studios, setStudios] = useState([])
    useEffect(() => {
		setTableLoadingOn()
        getStudios()
            .then(setStudios)
			.finally(setTableLoadingOff)
    }, [])

    return (
        <RightSide>
            <TitlePage>Liste des entreprises</TitlePage>
            <StudioListComponent studios={studios} />
        </RightSide>
    );
};

export default StudioList;
import classNames from 'classnames'
import Button from 'components/Button/Button'
import { buttonTypes } from 'constants/button.constant'
import classes from 'styles/classes.module.scss'

const DataActions = ({ actions, onAction, filteredActions = {} }) => {
	return (
		<div className={classNames (classes.flex, classes.flexWrap)}>
			{actions
				.filter(({ key }) => !filteredActions[key])
				.map((action, i) => (
					<Button
						type={buttonTypes[i]}
						key={i}
						onClick={onAction.bind(null, action.action)}>
						{action.label}
					</Button>
				))}
		</div>
	)
}

export default DataActions

import Typography from 'components/Typography'
import classNames from 'classnames';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import classes from './MenuNavigationComponent.module.scss'
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { isEmpty } from 'lodash';
import { urlParametersToJson } from 'containers/helpers';


const MenuNavigationComponent = ({
	queryName = "nav",
    components = [{ title: '', component: '' }]
}) => {
    const { pathname, search } = useLocation()
	const [searchParams, setSearchParams] = useSearchParams();
    const navigate = useNavigate()

    const initMenu = (components = [], menu = []) => {
        let activeIndex = menu.findIndex(({ isActive }) => !!isActive)
        if (activeIndex === -1) { activeIndex = 0 }
        const newMenu = components.map(({ title }) => ({ title }))
        newMenu[activeIndex].isActive = true
        return newMenu
    }
    const [menu, setMenu] = useState(initMenu(components))
    useEffect(() => {
        setMenu(initMenu(components, menu))
    }, [components])

    const index = useMemo(() => {
        if (isEmpty(search)) {
            return 0
        }
        const indexFromParams = Number(urlParametersToJson(search)[queryName] || 0)
        if (indexFromParams >= 0) {
            if (indexFromParams < menu.length - 1) {
                return indexFromParams
            }
            return menu.length - 1
        }
        return 0
    }, [search, menu])

    useEffect(() => {
        if (index !== menu.findIndex(({ isActive }) => isActive)) {
            setMenu(menu => menu.map((menuItem, key) => ({
                ...menuItem,
                isActive: key === index
            })))
        }
    }, [index])

    const updateUrl = useCallback((index) => {
		if (!search) {
			navigate(`${pathname}?${queryName}=${index}`)
			return
		}

		setSearchParams({
			...urlParametersToJson(search),
			[queryName]: index
		})
	}, [navigate, pathname, search, queryName])

    const activeComponent = useMemo(() => components[menu.findIndex(({ isActive }) => !!isActive)].component, [components, menu])
    return (
        <div className={classes.root}>
            <div className={classes.menuContainer}>
                {menu.map(({ title, isActive = false }, key) =>
                    <div
                        key={key}
                        onClick={() => updateUrl(key)}
                        className={classNames(classes.centeredContainer, classes.itemContainer, isActive && classes.active)}>
                        <Typography
                            classes={{ root: classes.item }}
                        >
                            {title}
                        </Typography>
                    </div>)}
            </div>
            <div className={classes.componentContainer}>
                {activeComponent}
            </div>
        </div>
    );
};

export default MenuNavigationComponent;
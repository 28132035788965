import { UserTypes } from 'constants/UserTypes';
import React from 'react';

const DisplayAddressComponent = ({
    user = {}
}) => {
    return (
        <div>
            <div>Adresse : {user.address}</div>
            {user.type === UserTypes.FREELANCE && <div>Distance : {user.radius} Kms</div>}
            <div>Latitude : {user.lat}</div>
            <div>Longitude : {user.lon}</div>
        </div>
    );
};

export default DisplayAddressComponent;
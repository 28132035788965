import { getFile, getFileByNameVisualisationUrl, getToken } from 'containers/helpers';
import React, { useEffect, useState } from 'react';
import NewPageLinkButton from './NewPageLinkButton';
import { getFreelancePaymentByContract, getInvoice, invoiceIsPaid } from 'containers/contract/helpers';
import Button from './Button';
import toast from './toast';

const InvoicesButtons = ({ studioInvoiceId, appointmentId }) => {
	const [invoicePaid, setInvoicePaid] = useState(false);
	const [studioInvoice, setStudioInvoice] = useState();
	const [invoiceFreelanceUrl, setInvoiceFreelanceUrl] = useState();
	const [invoiceStudioUrl, setInvoiceStudioUrl] = useState('');
	const getInvoicesUrl = async (studioInvoiceId, appointmentId) => {
		try {
			const studioInvoice = await getInvoice(studioInvoiceId);
			if (studioInvoice) {
				if (!!studioInvoice.paid) {
					setInvoicePaid(true);
				}
				setStudioInvoice(studioInvoice);
				const studioInvoiceFile = await getFile(studioInvoice.invoice);
				if (studioInvoiceFile) {
					setInvoiceStudioUrl(getFileByNameVisualisationUrl(getToken(), studioInvoiceFile.name));
				}
			}
			const payment = await getFreelancePaymentByContract(appointmentId);
			if (payment) {
				const { name: freelanceName } = await getFile(payment.invoice);
				setInvoiceFreelanceUrl(getFileByNameVisualisationUrl(getToken(), freelanceName));
			}
		} catch (error) {
			console.log('error', error);
		}
	};

	console.log('invoiceFreelanceUrl', invoiceFreelanceUrl)

	useEffect(() => {
		if (appointmentId && studioInvoiceId) {
			getInvoicesUrl(studioInvoiceId, appointmentId);
		}
	}, [studioInvoiceId, appointmentId]);
	const [loading, setLoading] = useState(false);
	const invoiceHasBeenPaid = async () => {
		try {
			setLoading(true);
			await invoiceIsPaid(studioInvoiceId, !invoicePaid);
			setInvoicePaid(!invoicePaid);
			toast.success("C'est enregistré!");
		} catch (error) {
			console.log('error', error);
			toast.error("Une erreur s'est produite : " + error.message);
		} finally {
			setLoading(false);
		}
	};
	return (
		<div>
			<h3>Factures</h3>
			<div className="flex">
				<div>
					<h4>Freelance</h4>
					{!!invoiceFreelanceUrl ? (
						<NewPageLinkButton href={invoiceFreelanceUrl}>Voir la facture en pleine page</NewPageLinkButton>
					) : (
						"N'a pas encore été créée."
					)}
				</div>
				<div>
					<h4>Studio</h4>
					{invoiceStudioUrl ? (
						<NewPageLinkButton href={invoiceStudioUrl}>Voir la facture en pleine page</NewPageLinkButton>
					) : (
						"N'a pas encore été créée."
					)}
				</div>
				{studioInvoiceId !== 0 && !!studioInvoiceId && (
					<Button loading={loading} onClick={invoiceHasBeenPaid}>
						{!invoicePaid
							? 'La facture a été payée par le salon'
							: "La facture n'a finalement pas été payée par le salon"}
					</Button>
				)}
			</div>
		</div>
	);
};

export default InvoicesButtons;

import { userRoles, collaboratorRoles } from '../containers/helpers';

export const FR = {
    title: 'Profil',
    cancelTitle: 'Suppression du compte',
    desactivateTitle: 'Désactivation du compte',
    desactivateSuccess: 'Le compte a été désactivé',
    activateSuccess: 'Le compte a été activé',
    accountDeactivate: 'Compte désactivé',
    accountActivate: 'Compte actif',
    cancel: 'Supprimer mon compte',
    confirm: 'Vous êtes certain de vouloir supprimer votre compte?',
    infos: 'Mes informations personnelles',
    accountName: 'Nom',
    phone: 'Téléphone',
    mailAddress: 'Adresse e-mail',
    firstname: 'Prénom',
    lastname: 'Nom',
    password: 'Mot de passe',
    gender: 'Sexe',
    male: 'Homme',
    female: 'Femme',
    adress: "Adresse de l'entreprise",
    country: 'Pays',
    city: 'Ville',
    zipCode: 'Code postal',
    activity: "Secteur d'activité",
    titleModalError: 'Modification du profil',
    buttonModalError: 'Fermer',
    errorModal: 'Veuillez modifier un des champs pour pouvoir mettre à jour votre compte',
    updateProfileButton: 'Mettre à jour',
    role: {
        label: 'Rôle',
        roles: [
            { value: userRoles.USER, role: 'Utilisateur' },
            { value: userRoles.GUEST, role: 'Invité' },
            { value: userRoles.ADMIN, role: 'Administrateur' },
            { value: userRoles.SUPERADMIN, role: 'Super Administrateur' },
        ],
    },
    poste: {
        label: 'Poste',
        postes: [
            { value: collaboratorRoles.DG, poste: 'Directeur général' },
            { value: collaboratorRoles.DIRECTOR, poste: 'Directeur' },
            { value: collaboratorRoles.MANAGER, poste: 'Manager' },
            { value: collaboratorRoles.EXECUTANT, poste: 'Exécutant' },
            { value: collaboratorRoles.COLLABORATOR, poste: 'Collaborateur' },
            { value: collaboratorRoles.PRESTA, poste: 'Prestataire' },
            { value: collaboratorRoles.INTERN, poste: 'Stagiaire' },
        ],
    },
};

export const EN = {
    title: 'Profile',
    cancelTitle: 'Account deletion',
    cancel: 'Delete my account',
    desactivateTitle: 'Deactivation of account',
    desactivateSuccess: 'Deactivation of account successfull',
    activateSuccess: 'Activation of account successfull',
    accountDeactivate: 'Account deactivate',
    accountActivate: 'Account active',
    confirm: 'Are you sure?',
    infos: 'My personal informations',
    accountName: 'Owner name',
    phone: 'Phone number',
    mailAddress: 'E-mail address',
    firstname: 'First name',
    lastname: 'Last name',
    password: 'Password',
    gender: 'Gender',
    male: 'Man',
    female: 'Woman',
    adress: 'Company address',
    country: 'Country',
    city: 'City',
    zipCode: 'Zip Code',
    activity: 'Industry field',
    titleModalError: 'Update profil',
    buttonModalError: 'Close',
    errorModal: 'Please edit one of the fields to update your account',
    updateProfileButton: 'Update',
    role: {
        label: 'Role',
        roles: [
            { value: userRoles.USER, role: 'User' },
            { value: userRoles.GUEST, role: 'Guest' },
            { value: userRoles.ADMIN, role: 'Admin' },
            { value: userRoles.SUPERADMIN, role: 'Super Admin' },
        ],
    },
    poste: {
        label: 'Job title',
        postes: [
            { value: collaboratorRoles.DG, poste: 'CEO' },
            { value: collaboratorRoles.DIRECTOR, poste: 'Director' },
            { value: collaboratorRoles.MANAGER, poste: 'Manager' },
            { value: collaboratorRoles.EXECUTANT, poste: 'Performing' },
            { value: collaboratorRoles.COLLABORATOR, poste: 'Collaborator' },
            { value: collaboratorRoles.PRESTA, poste: 'Extern' },
            { value: collaboratorRoles.INTERN, poste: 'Intern' },
        ],
    },
};

export default {
    fr: FR,
    us: EN,
};

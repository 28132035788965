import { TextField } from '@material-ui/core';
import { Autocomplete } from '@mui/material';
import { onboardingStatuses } from 'constants/OnboardingStatuses.constant';
import { getStudios } from 'containers/studio/helpers';
import React, { useEffect, useState } from 'react';

const ChooseStudio = ({ setLocation }) => {
    const [studios, setStudios] = useState([])
    const filterByActive = studios => studios.filter(({ status }) => status === onboardingStatuses.ACTIVE)
    useEffect(() => {
        getStudios()
            .then(filterByActive)
            .then(setStudios)
    }, [])

    const handleChange = (event, newStudio) => {
        if (!newStudio) {
            return setLocation(null)
        }
        const { lat, lon, studio_name, address, id } = newStudio
        setLocation({ lat, lon, studio_name, address, isStudio: true, id })
    }

    return (
        <Autocomplete
            placeholder='Entreprise'
            disablePortal
            id="combo-box-salon"
            options={studios.map(studio => ({ ...studio, label: studio.studio_name + ' - ' + studio.address }))}
            onChange={handleChange}
            renderInput={(params) => <TextField {...params} label="Entreprise" />}
        />
    );
};

export default ChooseStudio;
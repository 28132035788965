import React, { useMemo } from 'react';
import { useFreelanceUtils } from 'hooks/useFreelanceUtils';
import FreelanceProfileCard from 'components/FreelanceProfileCard';
import { upperFirst } from 'lodash';

const FreelanceFound = ({
    freelance = {},
    mission = {},
    location = null
}) => {
    const {
        priceHour,
        priceHours,
        // withoutReductionPriceDay,
        // withoutReductionPriceDays,
        // reductionPercentile,
        badges,
        imageUrl,
    } = useFreelanceUtils(freelance)

    // const freelanceSelectionLink = useMemo(() => `/mission/marketplace/${mission.id}/freelance/${freelance.id}`, [mission.id, freelance.id])
    const freelanceName = useMemo(() => freelance && freelance.firstname
        ? upperFirst(freelance.firstname) + ' ' + upperFirst(freelance.lastname)
        : '', [freelance])
    return (
        // <Link to={freelanceSelectionLink} className={classes.link}>
        <FreelanceProfileCard
            {...freelance}
            img={imageUrl}
			availableDays={Object.keys(freelance.days)}
			hourRate={priceHour}
			totalRate={priceHours}
            firstName={freelanceName}
            isAvailableForWholeMission={freelance.matchWithAllDates}
            badges={badges}
            rating={freelance.rating}
            nRatings={freelance.nRatings}
            created_at={freelance.created_at}
            location={location}
        />
        // </Link>
    );
};

export default FreelanceFound;
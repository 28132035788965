import MyTable from "components/MyTable/MyTable";
import { useMemo } from "react";
import { useNavigate } from "react-router-dom";

const StudioListComponent = ({
	studios
}) => {
	const navigate = useNavigate();
	const datas = useMemo(() => studios, [studios])

	const columns = [
        {
            title: 'Nom',
            render: ({ firstname, lastname }) => firstname + ' ' + lastname
        }
    ]

	const handleRowClick = (event, rowData) => {
		navigate("/user/" + rowData.id);
	}
	
	return (
		<MyTable
            columns={columns}
            data={datas}
            title={'Studios'}
            options={{ paging: false }}
			onRowClick={handleRowClick}
        />
	);
};

export default StudioListComponent;

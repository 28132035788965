const PdfViewer = ({ file }) => {
    return (
        <iframe id="inlineFrameExample"
        className="borderRadius shadow"
            title="Inline Frame Example"
            width="500"
            height="800"
            src={file}>
        </iframe>
    )
}

export default PdfViewer
import React, { useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useStoreUtils } from '../../../hooks/useStoreUtils';

import LoginForm from './components/LoginForm';
import { login } from './helpers';

const Login = () => {
    const { state, loginInApp } = useStoreUtils()
    const navigate = useNavigate();
    const { login: copy } = state.language

    const inputs = useMemo(() => [
        {
            type: 'text',
            name: 'email',
            placeholder: copy.mail,
            required: true,
            silentRequired: true,
            fluid: true,
        },
        {
            type: 'password',
            name: 'password',
            placeholder: copy.password,
            required: true,
            silentRequired: true,
            fluid: true,
        },
    ].filter((e) => !!e), [copy]);
    const [errorLogin, setErrorLogin] = useState({ validated: true, message: copy.accountNotValidatedLang });
    const [modal, setModal] = useState(false);

    const sendForm = async (values) => {
        try {
            const tokens = await login(values);
            loginInApp(tokens)
        } catch (e) {
            if (e.message === 'Please verify your email first') {
                setErrorLogin({ validated: false, message: copy.accountNotValidated });
            } else setErrorLogin({ validated: true, message: e.message });

            return setModal(true);
        }
    };

    const goToForgot = () => navigate(`/forgot`);

    return (
            <LoginForm
                forgotPassword={goToForgot}
                inputs={inputs}
                sendForm={sendForm}
                modal={modal}
                setModal={setModal}
                forgotPasswordLang={copy.forgotPassword}
                noConfirmationMailLang={copy.noConfirmationMail}
                unlockLang={copy.unlock}
                typeCodeLang={copy.typeCode}
                validLang={copy.valid}
                errorLogin={errorLogin}
                errorTitleLang={copy.errorTitle}
                redirectConfirmLang={copy.redirectConfirm}
            />
    );
};

export default Login
import useRemoteData from 'hooks/useRemoteData'
import IssueElem from 'components/IssueElem'
import { Button } from 'semantic-ui-react'
import { Link, useParams } from 'react-router-dom'
import RightSide from 'components/RightSide'
import Loader from 'common/components/Loader'
import Error from 'common/components/Error'
import { useUser } from 'hooks/useUser'
import UserInfos from 'components/UserInfos'
import DataActions from 'components/DataActions'
import { actionKeys, actions } from '../actions'
import { useContext, useMemo } from 'react'
import { Store } from 'store/store'
import MeetingIssueComponent from './MeetingIssueComponent'
import { IssueLabels_FR, statuses as IssueStatuses } from '../constants/issue.enum'

function AdminIssuePage() {
	const { state: { user: me } } = useContext(Store)
	const { id } = useParams()
	const { loading, error, data } = useRemoteData(`/issue/${id}`)
	const { user } = useUser(data?.user)
	const filteredActions = useMemo(() => {
		if (!data) { return [] }
		const filtered = []
		if (data.meeting) { filtered[actionKeys.ALLOW_SCHEDULE_MEETING] = true }
		if (data.admin && data.admin === me.id) { filtered[actionKeys.ASSIGN_TO_ME] = true }
		if (data.admin !== me.id) { filtered[actionKeys.UNASSIGN_FROM_ME] = true }
		if (data.status === IssueStatuses.CLOSE) { filtered[actionKeys.CLOSE] = true }
		if (data.status !== IssueStatuses.CLOSE) { filtered[actionKeys.REOPEN] = true }
		return filtered
	}, [data, me])

	return loading ? (
		<Loader />
	) : error ? (
		<Error error={error} />
	) : (
		<RightSide>
			<h1>Issue Details</h1>
			{data.meeting && <><Button as={Link} to={`/meeting/${data.meeting}`} >
				Accéder au meeting
			</Button>
				<MeetingIssueComponent meetingId={data.meeting} />
			</>
			}
			{data.appointment && (
				<Button as={Link} to={`/appointment/${data.appointment}`}>
					Access Appointment
				</Button>
			)}
			<IssueElem
				reference={`réf: ${data.reference}`}
				title={data.title}
				description={data.description}
				status={IssueLabels_FR[data.status]}
				created_at={data.created_at}
			/>
			<DataActions
				actions={actions}
				onAction={(action) => action([data])}
				filteredActions={filteredActions}
			/>
			<h2>User</h2>
			{!user ? <Loader /> : <UserInfos user={user} />}
		</RightSide>
	)
}

export default AdminIssuePage

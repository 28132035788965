import { updateApi } from 'containers/helpers'
import { getUser } from 'containers/helpers'
import notificationTypes from 'common/features/notification/constants/NotificationTypes'
import { notif } from 'containers/notification/helpers'
import { statuses as issueStatuses } from './constants/issue.enum'

export const actionKeys = {
	ALLOW_SCHEDULE_MEETING: 'ALLOW_SCHEDULE_MEETING',
	CLOSE: 'CLOSE',
	REOPEN: 'REOPEN',
	ASSIGN_TO_ME: 'ASSIGN_TO_ME',
	UNASSIGN_FROM_ME: 'UNASSIGN_FROM_ME',
}

export const actions = [
	{
		key: actionKeys.ALLOW_SCHEDULE_MEETING,
		label: 'Lui demander de choisir une date de réunion',
		action: (selected) => {
			const ids = selected.map(s => s.id)
			updateApi('/issues', {
				ids,
				status: issueStatuses.WAITING_MEETING,
			})
			notif({
				data: selected,
				type: notificationTypes.ALLOW_SCHEDULE_MEETING,
			})
		},
	},
	{
		key: actionKeys.REOPEN,
		label: 'Réouvrir la demande',
		action: (selected) => {
			const ids = selected.map(s => s.id)
			updateApi(`/issues`, { ids, status: issueStatuses.OPEN })
		}
	},
	{
		key: actionKeys.CLOSE,
		label: 'Marquer la demande comme traitée',
		action: (selected) => {
			const ids = selected.map((s) => s.id)
			updateApi('/issues', { ids, status: issueStatuses.CLOSE })
		}
	},
	{
		key: actionKeys.ASSIGN_TO_ME,
		label: 'M\'assigner la demande',
		action: (selected) => {
			const ids = selected.map((s) => s.id)
			const { id } = getUser()
			updateApi('/issues', { ids, admin: id })
		},
	},
	{
		key: actionKeys.UNASSIGN_FROM_ME,
		label:  'Ne plus m\'assigner la demande',
		action: (selected) => {
			const ids = selected.map((s) => s.id)
			updateApi('/issues', { ids, admin: null })
		},
	},
]

import { dayStatuses } from 'constants/DayStatuses'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Button } from 'semantic-ui-react';
import { paymentReceivedAPI } from './helpers';
import { types as issueTypes } from 'containers/issue/constants/issue.enum';

const StatusGesture = ({ status, contract: contractId, issues = [] }) => {
	const [localStatus, setLocalStatus] = useState(status)
	const navigate = useNavigate()
	const seeIssuesPage = useCallback(() => navigate('/issues/' + contractId), [contractId, navigate])
	const seeUnlockPage = useCallback(() => navigate('/payment/' + contractId), [contractId, navigate])
	useEffect(() => {
		setLocalStatus(status)
	}, [status])
	const paymentReceived = useCallback(async () => {
		try {
			const { status: newStatus } = await paymentReceivedAPI(contractId)
			setLocalStatus(newStatus)
			toast('Paiement reçu')
		} catch (error) {
			console.log('error', error)
			toast('Erreur, donnée pas actualisée', error.message)
		}
	}, [contractId])
	const statusName = useMemo(() => localStatus == null ? 'Non défini' : Object.entries(dayStatuses).filter(([key, value]) => value === localStatus)[0][0], [localStatus])
	const interaction = useMemo(() => {
		switch (localStatus) {
			case dayStatuses.WAITING_TEAM_FUNDS_RECEIVED:
				return <Button onClick={paymentReceived}>
					Paiement reçu
				</Button>
			case dayStatuses.WAITING_UNLOCK_FUNDS:
				return issues
					.find(({ status: issueStatus }) => issueStatus !== issueTypes.CLOSE)
					//la page issues et unlock funds utilisent le même composant issues si les issues ne sont pas toutes terminées.
					? <Button onClick={seeIssuesPage}>Voir les problèmes en cours</Button>
					: <Button onClick={seeUnlockPage}>
						Effectuer le virement au freelance
					</Button>
			default:
				return false
		}
	}, [localStatus, paymentReceived, issues, seeIssuesPage, seeUnlockPage])

	return <div>
		<span>{statusName}</span>
		{interaction}
	</div>
}

export default StatusGesture;
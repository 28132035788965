import { IconButton } from '@material-ui/core';
import useIsMobile from 'hooks/useIsMobile';
import React from 'react';
import SideBarComponent from './SideBarComponent';
import MenuIcon from '@mui/icons-material/Menu';
import CloseIcon from '@mui/icons-material/Close';
import classNames from 'classnames';
import classes from './LogGrid.module.scss'

const SideBarContainer = ({
    setOpositeToActualOpen = () => { },
    isOpen = false,
    isBurgerClose
}) => {
    const isMobile = useIsMobile()
    return (
        <>
            {
                isMobile && <div className={classes.burgerContainer}>
                    <IconButton onClick={setOpositeToActualOpen}>
                        {isBurgerClose ? <MenuIcon /> : <CloseIcon style={{ color: 'white' }} />}
                    </IconButton>
                </div>
            }
            {!isBurgerClose && <SideBarComponent setOpositeToActualOpen={setOpositeToActualOpen} />}
        </>
    );
};

export default SideBarContainer;
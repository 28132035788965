import getHeaders from './getHeaders'
import responseToJson from './responseToJson'

const apiUrl = process.env.REACT_APP_API_URL
const fetchApi = async (route, init = null) => {
	const apiRoute = apiUrl + route
	const params = !init
		? { headers: getHeaders() }
		: {
				method: init && init.body ? 'POST' : 'get',
				...init,
				mode: 'cors',
				headers: { ...getHeaders(init.multiform), ...init.headers },
				body: init.multiform ? init.body : JSON.stringify(init.body),
		  }
	return fetch(apiRoute, params).then((res) =>
		res.status !== 204
			? responseToJson(res, route, !init ? null : params)
			: null
	)
}

export default fetchApi
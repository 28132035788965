import FormComponent from 'components/Form/Form';
import { intensityTypes } from 'constants/badgeIntensity.constant';
import React, { useContext, useMemo, useState } from 'react';
import { toast } from 'react-toastify';
import { Button } from 'semantic-ui-react';
import { Store } from 'store/store';
import { createUserBadge } from '../helpers';

const NewFreelanceBadge = ({
    userId,
    setUserBadges,
    userBadges
}) => {
    const [update, setUpdate] = useState()
    const { state: { badges } } = useContext(Store)
    const userKeys = useMemo(() => Object.keys(userBadges), [userBadges])
    const inputs = useMemo(() => [
        {
            type: 'select',
            name: 'name',
            required: true,
            silentRequired: true,
            options: Object.keys(badges)
                .filter(name => !userKeys.includes(name))
                .map((name) => ({ key: name, value: name, text: name })),
            placeholder: 'Type de badge',
            label: 'Type de badge',
        },
    ], [badges, userKeys])

    const sendForm = async (values) => {
        try {
            await createUserBadge(userId, values.name)
            setUserBadges({
                ...userBadges,
                [values.name]: 0
            })
            setUpdate(false)
            toast('Badge ajouté')
        } catch (error) {
            console.log('error', error)
        }
    }
    return (
        update ? <div>
            <h3>Ajouter un nouveau badge</h3>
            <FormComponent
                inputs={inputs}
                sendForm={sendForm}
                centered={true}
                responsive={false}
                segment={false}
            />
        </div> : <Button onClick={() => setUpdate(true)} primary>
            Ajouter un nouveau badge
        </Button>

    );
};

export default NewFreelanceBadge;
import { isNull } from 'containers/helpers'
import { useState, useContext, useEffect } from 'react'
import { Form } from 'semantic-ui-react'

import { Store } from '../../store/store'
import Checkbox from './CheckBox'
import FormAutocomplete from './FormAutocomplete'
import IbanInput from './IbanInput'
import InlineSelect from './InlineSelect'
import InlineTextArea from './InlineTextArea'
import InputEmail from './InputEmail'
import InputPassword from './InputPassword'
import KeyWordsInput from './KeyWordsInput'
import TextInput from './TextInput'
import { isUndefined } from './utils'

export default ({
    onKeyPress = () => { },
    type,
    name,
    onChange,
    value: initialValue,
    label: labelObject,
    styles = { container: '' },
    placeholder,
    required,
    silentRequired = false,
    toCheck = false,
    disabled,
    options = [],
    maxLength = null,
    fluid = false,
    legend = null,
    inline = true,
    responsive = true,
    signIn = false,
    style = {},
    labelStyle = {},
    labelRight = false,
    centered = false,
    info = null,
    placeholders,
    classNameKeyWords,
    error: errorLogic = '',
    preview = false,
    formError = false
}) => {
    const {
        state: {
            languageValue,
            language: {
                form: { required: requiredLang, file: fileLang, fileError: fileErrorLang, fileUpdate: fileUpdateLang },
            },
        },
    } = useContext(Store)
    const label =
        typeof labelObject === 'object'
            ? labelObject[languageValue]
                ? labelObject[languageValue]
                : labelObject
            : labelObject
    const [error, setError] = useState(required && !initialValue)
    const [value, setValue] = useState(initialValue == null ? '' : initialValue)
    useEffect(() => {
        if (!isNull(initialValue)) {
            setValue(initialValue)
        }
    }, [initialValue])
    const getNewValue = (eventValue, initialValue, receivedValue) => {
        return eventValue || /* initialValue || */ receivedValue
    }

    const handleEffect = (event, { checked, value: receivedValue } = {}) => {
        const value = getNewValue(event.target.value, initialValue, receivedValue)
        required && setError(isUndefined(required, value))
        setValue(value)
        onChange({
            name,
            value,
            checked,
            label,
            type,
        })
    }
    // implementer next ici pour Radio et checkbox
    return type === 'radio' ? (
        <Form.Radio label={label} value={initialValue} checked={toCheck} onChange={handleEffect} />
    ) : type === 'checkbox' ? (
        <Checkbox
            error={error}
            formError={formError}
            value={initialValue}
            disabled={disabled}
            label={label}
            labelRight={labelRight}
            checked={toCheck}
            onChange={handleEffect}
            info={info}
            required={required}
            styles={styles}
            responsive={responsive}
            centered={centered}
            inline={inline}
            labelStyle={labelStyle}
            name={name}
            initialValue={initialValue}
        />
    ) : type === 'textArea' ? (
        <InlineTextArea
            requiredLang={requiredLang}
            id={name + (initialValue ? `-${initialValue}` : '')}
            initialValue={initialValue}
            handleEffect={handleEffect}
            name={name}
            value={value || ''}
            placeholder={placeholder}
            onChange={handleEffect}
            onBlur={handleEffect}
            maxLength={maxLength}
            label={label}
            legend={legend}
            required={required}
            error={error}
            silentRequired={silentRequired}
            styles={styles}
            info={info}
            formError={formError}
        />
    ) : type === 'select' ? (
        <InlineSelect
            requiredLang={requiredLang}
            id={name + (initialValue ? `-${initialValue}` : '')}
            initialValue={initialValue}
            handleEffect={handleEffect}
            name={name}
            value={value == null ? '' : value}
            placeholder={placeholder}
            onChange={handleEffect}
            onBlur={handleEffect}
            maxLength={maxLength}
            label={label}
            legend={legend}
            required={required}
            error={error}
            silentRequired={silentRequired}
            options={options}
            info={info}
            formError={formError}
        />
    ) : type === 'autocompleteAdd' ? (
        <FormAutocomplete
            requiredLang={requiredLang}
            id={name + (initialValue ? `-${initialValue}` : '')}
            initialValue={initialValue}
            handleEffect={handleEffect}
            name={name}
            value={value == null ? '' : value}
            placeholder={placeholder}
            onChange={handleEffect}
            onBlur={handleEffect}
            maxLength={maxLength}
            label={label}
            legend={legend}
            required={required}
            error={error}
            silentRequired={silentRequired}
            options={options}
            info={info}
            formError={formError}
        />
    ) : type === 'keyWords' ? (
        <KeyWordsInput
            onKeyPress={onKeyPress}
            type={type}
            name={name}
            onChange={onChange}
            value={value}
            initialValue={initialValue}
            error={error}
            label={label}
            labelObject={labelObject}
            styles={styles}
            placeholder={placeholder}
            required={required}
            silentRequired={silentRequired}
            toCheck={toCheck}
            disabled={disabled}
            options={options}
            maxLength={maxLength}
            fluid={fluid}
            legend={legend}
            inline={inline}
            responsive={responsive}
            style={style}
            labelStyle={labelStyle}
            centered={centered}
            info={info}
            handleEffect={handleEffect}
            requiredLang={requiredLang}
            fileLang={fileLang}
            fileUpdateLang={fileUpdateLang}
            fileErrorLang={fileErrorLang}
            placeholders={placeholders}
            classNameKeyWords={classNameKeyWords}
            formError={formError}
        />
    ) : type === 'password' ? (
        <InputPassword
            onKeyPress={onKeyPress}
            name={name}
            onChange={onChange}
            value={value}
            initialValue={initialValue}
            error={error}
            label={label}
            labelObject={labelObject}
            styles={styles}
            placeholder={placeholder}
            required={required}
            silentRequired={silentRequired}
            toCheck={toCheck}
            disabled={disabled}
            options={options}
            maxLength={maxLength}
            fluid={fluid}
            legend={legend}
            inline={inline}
            responsive={responsive}
            style={style}
            labelStyle={labelStyle}
            centered={centered}
            info={info}
            handleEffect={handleEffect}
            requiredLang={requiredLang}
            signIn={signIn}
            formError={formError}
        />
    ) : type === 'email' ? (
        <InputEmail
            onKeyPress={onKeyPress}
            type={type}
            name={name}
            onChange={onChange}
            value={value}
            initialValue={initialValue}
            error={error}
            label={label}
            labelObject={labelObject}
            styles={styles}
            placeholder={placeholder}
            required={required}
            silentRequired={silentRequired}
            toCheck={toCheck}
            disabled={disabled}
            options={options}
            maxLength={maxLength}
            fluid={fluid}
            legend={legend}
            inline={inline}
            responsive={responsive}
            style={style}
            labelStyle={labelStyle}
            centered={centered}
            info={info}
            handleEffect={handleEffect}
            requiredLang={requiredLang}
            fileLang={fileLang}
            fileUpdateLang={fileUpdateLang}
            fileErrorLang={fileErrorLang}
            formError={formError}
        />
    ) : type === 'iban' ? (
        <IbanInput
            onKeyPress={onKeyPress}
            type={type}
            name={name}
            onChange={onChange}
            value={value}
            initialValue={initialValue}
            error={error}
            label={label}
            labelObject={labelObject}
            styles={styles}
            placeholder={placeholder}
            required={required}
            silentRequired={silentRequired}
            toCheck={toCheck}
            disabled={disabled}
            options={options}
            maxLength={maxLength}
            fluid={fluid}
            legend={legend}
            inline={inline}
            responsive={responsive}
            style={style}
            labelStyle={labelStyle}
            centered={centered}
            info={info}
            handleEffect={handleEffect}
            requiredLang={requiredLang}
            formError={formError}
        />
    ) : (
        <TextInput
            onKeyPress={onKeyPress}
            type={type}
            name={name}
            onChange={onChange}
            value={value}
            initialValue={initialValue}
            error={error}
            label={label}
            labelObject={labelObject}
            styles={styles}
            placeholder={placeholder}
            required={required}
            silentRequired={silentRequired}
            toCheck={toCheck}
            disabled={disabled}
            options={options}
            maxLength={maxLength}
            fluid={fluid}
            legend={legend}
            inline={inline}
            responsive={responsive}
            style={style}
            labelStyle={labelStyle}
            centered={centered}
            info={info}
            handleEffect={handleEffect}
            requiredLang={requiredLang}
            fileLang={fileLang}
            fileUpdateLang={fileUpdateLang}
            fileErrorLang={fileErrorLang}
            errorLogic={errorLogic}
            preview={preview}
            formError={formError}
        />
    )
}

import React, { useState } from 'react';
import classes from './FreelanceMarketplace.module.scss'
import classNames from 'classnames'
import SearchFilters from './components/SearchFilter/SearchFilters';
import Typography from 'components/Typography';
import FreelanceFound from './FreelanceFound';

const FreelanceMarketplace = ({
    APIfreelances,
    location = null
}) => {
    const [freelances, setFreelances] = useState([])
    return (
        <div className={classes.searchContainer}>
            <div className={classes.filterContainer}>
                <SearchFilters
                    location={location}
                    freelances={APIfreelances}
                    setFreelances={setFreelances}
                    actualFreelances={freelances}
                />
            </div>
            <div className={classNames(classes.flex, classes.flexWrap)}>
                {freelances.length === 0 && (
                    <Typography variant='subTitle3'>Aucun profil disponible.</Typography>
                )}
                {freelances.map((freelance, index) => (
                    <FreelanceFound
                        key={index + 'freelance'}
                        freelance={freelance}
                        location={location}
                    // mission={mission}
                    />
                ))}
            </div>
        </div>
    );
};

export default FreelanceMarketplace;
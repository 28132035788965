import { dayStatuses } from 'constants/DayStatuses';
import { toDateInput } from 'containers/level/LevelUp/dates';
import React, { useMemo } from 'react';
import ContractConfirmations from './ContractConfirmations';
import ContractsButtons from './ContractsButtons';
import DateTooltip from './DateTooltip';
import FreelanceInfos from './FreelanceInfos';
import InvoicesButtons from './InvoicesButtons';
import MissionContractReviews from './MissionContractReviews/MissionContractReviews';
import PricesIconTooltip from './PricesIconTooltip';
import StudioInfos from './StudioInfos';
import { roundTo2Dec } from 'util/utils';
import { isEmpty } from 'lodash';
import { GLOBAL_STYLEEP_PERCENTILE } from 'constants/stripe.constant';

const AppointmentInfos = ({
	created_at,
	id: appointmentId,
	mission,
	payment,
	reference,
	status,
	studio_contract,
	freelance_contract,
	studio_invoice,
	price,
	studio_receipt,
	updated_at,
	user,
	confirmations = [],
	contract = null,
	users = {},
	days = {},
	prepayment,
}) => {
	const { studio, freelance } = useMemo(() => users || {}, [users]);
	const isContract = useMemo(() => !!contract, [contract]);

	const getFreelancePrices = useMemo(() => {
		if (!price) return {};
		const { price: total, prices: pricePerDays } = price;

		return {
			price: roundTo2Dec(total * (1 - GLOBAL_STYLEEP_PERCENTILE)),
			prices: pricePerDays.map((dayPrice) => roundTo2Dec(dayPrice * (1 - GLOBAL_STYLEEP_PERCENTILE))),
		};
	}, [price]);

	return (
		<div>
			{isContract ? "C'est un contract!!" : "CE N'EST PAS UN CONTRAT"}
			<div>
				dates: <DateTooltip dates={days} />
			</div>
			<div>id: {appointmentId}</div>
			<div>mission: {mission}</div>
			<div>payment: {payment}</div>
			<div>reference: {reference}</div>
			<div>status: {status}</div>
			<div>studio_contract: {studio_contract}</div>
			<div>studio_invoice: {studio_invoice}</div>
			<div>studio_price: {roundTo2Dec(price?.price)}€HT</div>
			<div>freelance_price: {getFreelancePrices?.price}€HT (mais micro-entreprise donc TTC)</div>
			<div>
				freelance_prices: <PricesIconTooltip prices={getFreelancePrices?.prices} />
			</div>
			<div>
				studio_prices: <PricesIconTooltip prices={price?.prices} />
			</div>
			<div>studio_receipt: {studio_receipt}</div>
			<div>created_at: {toDateInput(created_at)}</div>
			<div>updated_at: {toDateInput(updated_at)}</div>
			<div className="flex space-between">
				{freelance && (
					<div>
						<h3>Freelance</h3>
						<FreelanceInfos {...freelance} />
					</div>
				)}
				{studio && (
					<div>
						<h3>Studio</h3>
						<StudioInfos {...studio} />
					</div>
				)}
			</div>
			{isContract && (
				<ContractsButtons
					prices={price?.prices}
					contractId={contract}
					studio_contract={studio_contract}
					freelance_contract={freelance_contract}
					status={status}
					appointment={appointmentId}
					prepayment={prepayment}
				/>
			)}
			{isContract && status > dayStatuses.WAITING_ADMIN_REVIEW_DAYS_CONFIRMATION && (
				<InvoicesButtons studioInvoiceId={studio_invoice} appointmentId={appointmentId} />
			)}
			{isContract && (
				<ContractConfirmations confirmations={confirmations} contractId={contract} status={status} />
			)}
			<MissionContractReviews appointmentId={appointmentId} />
		</div>
	);
};

export default AppointmentInfos;

import { fetchApi } from "containers/helpers"

export const updateStudio = (
	body,
	studioId
) =>
	fetchApi(`/studio/${studioId}`, {
		method: 'PATCH',
		body,
		multiform: true,
	})

export const updateFreelance = (
	body,
	freelanceId
) =>
	fetchApi(`/freelance/${freelanceId}`, {
		method: 'PATCH',
		body,
		multiform: true,
	})
export const updateUserStatusAPI = (userId, status) => fetchApi(`/user/onboardStatus`, {
	method: 'PATCH',
	body: {
		status,
		userId
	}
})

export const manageSubscriptionAPI = (userId, status) => fetchApi(`/user/manageSubscription/${userId}`, {
	method: 'PATCH',
	body: {
		status
	}
})
export const getBrands = () => fetchApi('/brands')


export const getBankAccount = (userId) => fetchApi('/user/bankAccount/'+userId)
export const createBankAccount = (body) => fetchApi('/bankAccount', { body })
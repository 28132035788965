import { Dropdown } from 'semantic-ui-react'

function DataFilters({ filters, onFilter }) {
	return (
		<Dropdown clearable text='Filters'>
			<Dropdown.Menu>
				{filters.map((filter, i) => (
					<Dropdown.Item
						onClick={onFilter.bind(null, filter)}
						key={i}
					>
						{filter.label}
					</Dropdown.Item>
				))}
			</Dropdown.Menu>
		</Dropdown>
	)
}

export default DataFilters

import { fetchApi } from "containers/helpers";

export const getContractConfirmations = contractId => fetchApi('/confirmations/' + contractId)
export const resendConfirmationsAPI = contractId => fetchApi('/contract/confirmationStep/' + contractId, {
    method: 'PATCH'
})

export const getFreelancePaymentByContract = appointmentId => fetchApi('/payment/appointment/' + appointmentId)
export const getInvoice = invoiceId => fetchApi('/invoice/' + invoiceId)
export const invoiceIsPaid = (invoiceId, isPaid) => fetchApi('/invoice/' + invoiceId, {
    method: 'PATCH',
    body: {
        paid: isPaid
    }
})
import { useEffect, useMemo, useState } from 'react';
import {
	getHoursCountFromSlots,
} from 'modules/dates'
import { getImageUrl } from '../containers/helpers';
import useLanguage from './useLanguage';
import { getFreelanceBadges } from 'containers/freelance/helpers';

export const useFreelanceUtils = (freelance = {}) => {
	const level = useMemo(() => Math.trunc(parseFloat(freelance.level)), [freelance])
	const [priceHour, setPriceHour] = useState('En cours de calcul')
	const [priceHours, setPriceHours] = useState('En cours de calcul')
	// const [missionPrice, setMissionPrice]  = useState('En cours de calcul')

	const initPrices = async (freelance, level) => {
		try {
			const missionHours = getHoursCountFromSlots(Object.values(freelance.days).map(({ dates }) => dates).flat())
			const price = missionHours * Number(freelance.hourly_rate)

			setPriceHour(freelance.hourly_rate)
			setPriceHours(price)
		} catch (e) {
			console.log('e', e)
		}
	}
	const [badges, setBadges] = useState({})

	const initBadges = async (freelance) => {
		try {
			const newBadges = await getFreelanceBadges(freelance.id)
			setBadges(newBadges)
		} catch (error) {
			console.log('error', error)
		}
	}

	useEffect(() => {
		if (freelance && freelance.days && freelance.id) {
			initPrices(freelance, level)
			initBadges(freelance)
		}
	}, [freelance, level])
	const imageUrl = useMemo(() => freelance.image && getImageUrl(freelance.image), [freelance])
	return {
		...freelance,
		level,
		priceHour,
		priceHours,
		// withoutReductionPriceDay,
		// withoutReductionPriceDays,
		// reductionPercentile,
		badges,
		imageUrl,
	};
};

import { FormControlLabel, Switch } from '@material-ui/core';
import React, { useCallback, useEffect, useState } from 'react';
import Typography from 'components/Typography'
import classes from './SearchFilter.module.scss'
import Divider from 'components/Divider'
import useLanguage from 'hooks/useLanguage'
import Button from 'components/Button';
import classNames from 'classnames';
import LevelFilter from '../../LevelFilter';
import DistanceService from 'modules/distance';

const sortByEnums = {
    LEVEL: 0,
    DISTANCE: 1,
}

const SearchFilters = ({
    freelances,
    setFreelances,
    location = null
}) => {
    const lang = useLanguage('marketPlace')
    const [filters, setFilters] = useState({
        allDatesOnly: false,
        levels: [0],
        sortBy: sortByEnums.LEVEL
    })

    const onChange = (newFilters) => {
        setFilters(filters => ({
            ...filters,
            ...newFilters
        }))
    }


    const getOnlyFullDates = freelances => freelances.filter(({ matchWithAllDates }) => !!matchWithAllDates)


    const applyFilters = useCallback(() => {
        const filterByLevels = (freelances) => {
            if (filters.levels.includes(0)) {
                return freelances
            }
            return freelances.filter(
                freelance => filters.levels.findIndex(
                    level =>
                        Math.trunc(parseFloat(freelance.level)) === level
                ) > -1
            )
        }
        const getOnlyAcceptableDistance = freelances => freelances
            .filter(freelance =>
                DistanceService.isDistanceAcceptable(location, freelance, freelance.radius))

        const sortFreelancesBy = (freelances, sortType) => {
            const sortByFunc = {
                [sortByEnums.DISTANCE]: (a, b) =>
                    DistanceService.getDistanceBetweenCoords(location, a)
                    -
                    DistanceService.getDistanceBetweenCoords(location, b),
                [sortByEnums.LEVEL]: (a, b) => parseFloat(a.level) - parseFloat(b.level),
            }
            return freelances.sort(sortByFunc[sortType])
        }
        let filteredFreelances = freelances
        if (filters.allDatesOnly) {
            filteredFreelances = getOnlyFullDates(filteredFreelances)
        }
        filteredFreelances = filterByLevels(filteredFreelances)
        if (!!location) {
            if (filters.distanceAvailable) {
                filteredFreelances = getOnlyAcceptableDistance(filteredFreelances)
            }
            if (filters.sortBy === sortByEnums.DISTANCE) {
                filteredFreelances = sortFreelancesBy(filteredFreelances, sortByEnums.DISTANCE)
            }
        }
        if (filters.sortBy === sortByEnums.LEVEL) {
            filteredFreelances = sortFreelancesBy(filteredFreelances, sortByEnums.LEVEL)
        }
        setFreelances([...filteredFreelances])
    }, [freelances, filters, location, setFreelances])

    useEffect(() => {
        applyFilters()
    }, [applyFilters, filters, freelances, setFreelances])

    const updateLevels = (levels) => setFilters(filters => ({
        ...filters,
        levels
    }))

    return (
        <div className={classes.root}>
            <Button link='/reductions/table'>
                {lang.seeOffTable}
            </Button>
            <div className={classNames(classes.header, classes.mt2)}>
                <Typography classes={{ root: classes.componentHeader }}>{lang.filters}</Typography>
                {/* <div className={classes.resetWrapper}>
                    <img src={ResetIcon} alt='reset' />
                    <Typography>{lang.reset}</Typography>
                </div> */}
            </div>
            <Divider />
            <div className={classes.section}>
                <Typography>{lang.level}</Typography>
                <LevelFilter
                    updateLevels={updateLevels}
                    freelances={freelances}
                />
            </div>
            <Divider />
            <div className={classes.section}>
                <Typography classes={{ root: classes.sectionHeader }}>{lang.availability}</Typography>
                <FormControlLabel control={<Switch onChange={() => onChange({ allDatesOnly: !filters.allDatesOnly })} />}
                    label={lang.wholeMissionAvailable} />
            </div>
            {!!location && <>
                <div className={classes.section}>
                    <Typography classes={{ root: classes.sectionHeader }}>{lang.distanceAvailable}</Typography>
                    <FormControlLabel control={<Switch onChange={() => onChange({ distanceAvailable: !filters.distanceAvailable })} />}
                    />
                </div>
                <div className={classes.section}>
                    <Typography classes={{ root: classes.sectionHeader }}>{lang.sortBy}</Typography>
                    <div className={classes.centeredContainer}>
                        <Typography classes={{ root: classes.sectionHeader }}>{lang.level}</Typography>

                        <FormControlLabel control={<Switch onChange={() => onChange({
                            sortBy: filters.sortBy === sortByEnums.DISTANCE ? sortByEnums.LEVEL : sortByEnums.DISTANCE
                        })} />}
                        />
                        <Typography classes={{ root: classes.sectionHeader }}>{lang.distance}</Typography>
                    </div>
                </div>
            </>}
        </div>

    );
};

export default SearchFilters;
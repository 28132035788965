import { IconButton } from "@material-ui/core";
import { Tooltip } from "antd";
import MyTable from "components/MyTable/MyTable";
import AutoGraphIcon from '@mui/icons-material/AutoGraph';
import PersonIcon from '@mui/icons-material/Person';
import { useMemo } from "react";
import { Link } from "react-router-dom";

const FreelanceListComponent = ({
	freelances
}) => {
	const datas = useMemo(() => freelances, [freelances])

	const columns = [
		{
			title: 'Nom',
			render: ({ firstname, lastname }) => firstname + ' ' + lastname
		},
		{
			title: 'Actions',
			render: ({ id }) => (
				<>
                	<Link to={"/freelance/badges/" + id}>
						<Tooltip title="Accéder à la page des badges">
							<IconButton>
								<AutoGraphIcon />
							</IconButton>
						</Tooltip>
					</Link>
					{/* <Link to={"/freelance/levelup/" + id}>
						<Tooltip title="Accéder à la page d'augmentation de level">
							<IconButton>
								<AutoGraphIcon />
							</IconButton>
						</Tooltip>
					</Link> */}
					<Link to={"/user/" + id}>
						<Tooltip title="Accéder au profil de l'utilisateur">
							<IconButton>
								<PersonIcon />
							</IconButton>
						</Tooltip>
					</Link>
				</>
			)
		}
	]

	return (
		<MyTable
            columns={columns}
            data={datas}
            title={'Studios'}
            options={{ paging: false }}
        />
	);
};

export default FreelanceListComponent;

import FormComponent from 'components/Form/Form';
import FileTypes from 'constants/FileTypes';
import { UserTypes } from 'constants/UserTypes';
import { getOnlyUpdatedValues, isNull, objectToFormData } from 'containers/helpers';
import { toDateInput } from 'containers/level/LevelUp/dates';
import { isEmpty } from 'lodash';
import React, { useContext, useEffect, useMemo, useState } from 'react';
import { toast } from 'react-toastify';
import { Store } from 'store/store';
import { getBrands, updateFreelance, updateStudio } from '../helpers';

const UserFiscalForm = ({
    ...user
}) => {
    const [loading, setLoading] = useState(false)
    const { state: { language: { onboard: lang } } } = useContext(Store)
    const [franchises, setFranchises] = useState([])

    useEffect(() => {
        getBrands()
            .then(brands => setFranchises(brands.map(({ name }) => name)))
    }, [])
    const knowByChoices = useMemo(() => lang.knowByChoices[user.type], [lang, user])

    const studioInfosInputs = useMemo(() => [
        {
            type: 'image',
            name: 'image',
            label: lang.image,
            value: user.image,
            preview: true,
            fluid: true,
            info: lang.studioImageInfo
        },
        {
            type: 'text',
            name: 'studio_name',
            value: user.studio_name,
            placeholder: lang.studioName,
            label: lang.studioName,
            required: true,
            silentRequired: true,
            fluid: true,
        },
        {
            type: 'select',
            name: 'collaborators',
            value: String(user.collaborators),
            fluid: true,
            options: [
                { key: '0', value: '0', text: '1' },
                { key: '1', value: '1', text: '2 - 3' },
                { key: '2', value: '2', text: '3 - 5' },
                { key: '3', value: '3', text: '5 - 10' },
                { key: '4', value: '4', text: '10 - +' },
            ],
            required: true,
            silentRequired: true,
            placeholder: lang.collaborators,
            label: lang.collaborators,
        },
        {
            type: 'date',
            name: 'since',
            value: toDateInput(user.since),
            fluid: true,
            placeholder: lang.creationDate,
            required: true,
            silentRequired: true,
            label: lang.creationDate,
        },
        {
            type: 'phone',
            name: 'phone',
            value: user.phone,
            placeholder: lang.phone,
            label: lang.phone,
            required: true,
            silentRequired: true,
            fluid: true,
        },
        {
            type: 'autocompleteAdd',
            name: 'franchise',
            value: user.franchise_name,
            placeholder: lang.franchise,
            label: lang.franchise,
            fluid: true,
            options: franchises,
        },
        {
            type: 'autocompleteAdd',
            name: 'know_by',
            placeholder: lang.knowBy,
            label: lang.knowBy,
            value: user.know_by,
            required: true,
            silentRequired: true,
            fluid: true,
            options: knowByChoices,
            info: lang.knowByChoicesInfo
        },
    ], [franchises, knowByChoices, lang, user])

    const freelanceInfosInputs = useMemo(() => [
        {
            type: 'image',
            name: 'image',
            label: lang.image,
            value: user.image,
            preview: true,
            fluid: true,
            info: lang.studioImageInfo
        }, {
            type: 'text',
            name: 'company_name',
            value: user.company_name,
            placeholder: lang.companyName,
            label: lang.companyName,
            required: true,
            silentRequired: true,
            fluid: true,
        },
        {
            type: 'date',
            name: 'since',
            value: toDateInput(user.since),
            fluid: true,
            placeholder: lang.creationDate,
            required: true,
            silentRequired: true,
            label: lang.creationDate,
        },
        {
            type: 'phone',
            name: 'phone',
            value: user.phone,
            placeholder: lang.phone,
            label: lang.phone,
            required: true,
            silentRequired: true,
            fluid: true,
        },
        {
            type: 'select',
            name: 'degree',
            value: String(user.degree),
            fluid: true,
            options: [
                { key: '0', value: '0', text: lang.noDiploma },
                { key: '1', value: '1', text: lang.diplomaA },
                { key: '2', value: '2', text: lang.diplomaB },
            ],
            required: true,
            silentRequired: true,
            placeholder: lang.diploma,
            label: lang.diploma,
        },
        {
            type: 'autocompleteAdd',
            name: 'know_by',
            placeholder: lang.knowBy,
            label: lang.knowBy,
            value: user.know_by,
            required: true,
            silentRequired: true,
            fluid: true,
            options: knowByChoices,
            info: lang.knowByChoicesInfo
        }], [knowByChoices, lang, user])

    const inputs = useMemo(() => [
        ...(user.type === UserTypes.STUDIO ? studioInfosInputs : freelanceInfosInputs),
        ...user.type === UserTypes.STUDIO ? [{
            type: 'text',
            name: 'legal_name',
            value: user.legal_name,
            placeholder: lang.legal_name,
            label: lang.legal_name,
            required: true,
            silentRequired: true,
            fluid: true,
        }] : [],
        {
            type: 'number',
            name: 'siret',
            value: user.siret,
            placeholder: lang.siret,
            label: lang.siret,
            required: true,
            silentRequired: true,
            fluid: true,
        },
        {
            type: 'text',
            name: 'rcs_city',
            value: user.rcs_city,
            placeholder: lang.rcs_city,
            label: lang.rcs_city,
            required: true,
            silentRequired: true,
            fluid: true,
        },
        {
            type: 'text',
            name: 'hq_city',
            value: user.hq_city,
            placeholder: lang.hq_city,
            label: lang.hq_city,
            required: true,
            silentRequired: true,
            fluid: true,
        },
        {
            type: 'text',
            name: 'tva_number',
            value: user.tva_number,
            placeholder: lang.tva_number,
            label: lang.tva_number,
            required: user.type === UserTypes.STUDIO ? true : false,
            silentRequired: true,
            fluid: true,
        },
        ...user.type === UserTypes.STUDIO ? [{
            type: 'select',
            name: 'company_role',
            value: String(user.company_role),
            fluid: true,
            options: [
                { key: '0', value: '0', text: lang.company_roles.president },
                { key: '1', value: '1', text: lang.company_roles.ceo },
                { key: '2', value: '2', text: lang.company_roles.director },
            ],
            required: true,
            silentRequired: true,
            placeholder: lang.company_role,
            label: lang.company_role,
        }] : [],
        ...(user.type === UserTypes.STUDIO ? [{
            type: 'number',
            name: 'capital',
            value: user.capital,
            placeholder: "1000",
            label: lang.capital,
            required: true,
            silentRequired: true,
            fluid: true,
        }] : []),
        {
			type: 'text',
			name: 'head_office',
			value: user.head_office,
			placeholder: lang.head_office,
			label: lang.head_office,
			required: true,
			silentRequired: true,
			fluid: true,
			info: lang.fullHeadOffice
		},
		...user.type === UserTypes.STUDIO ? [{
			type: 'select',
			name: 'company_type',
			value: !isNull(user.company_type) ? String(user.company_type) : null,
			fluid: true,
			options: lang.company_types.map((company_type, index) => ({
				key: String(index), value: String(index), text: company_type
			})),
			required: true,
			silentRequired: true,
			placeholder: lang.company_type,
			label: lang.company_type,
		}] : [],
		...(user.type === UserTypes.STUDIO ? [{
			type: 'number',
			name: 'capital',
			value: user.capital || null,
			placeholder: "1000",
			label: lang.capital,
			fluid: true,
			info: lang.capitalContentCentimes
		}] : []),
        {
            type: 'file',
            name: 'fiscal',
            value: (user.files && user.files.length > 0 ? user.files.find(({ type }) => FileTypes.FISCAL === type) : {}).name,
            preview: true,
            label: lang.kbisDoc,
            required: true,
            silentRequired: true,
            info: 'Pour pouvoir accéder à notre plateforme, vous devez nous fournir votre document attestant la création de votre entreprise.',
        },
    ], [lang, user, freelanceInfosInputs, studioInfosInputs])

    const getUpdateFunc = () =>
        user.type === UserTypes.FREELANCE ? updateFreelance : updateStudio

    const sendForm = async (values) => {
        const updatedValues = getOnlyUpdatedValues(user, values)

        if (isEmpty(updatedValues)) {
            toast('Rien n\'a été actualisé dans le formulaire.')
            return
        }
        setLoading(true)
        try {
            const { status } = await getUpdateFunc()(objectToFormData(updatedValues), user.type_id)
            toast('Actualisé')
        } catch (e) {
            console.error(e)
            toast('Erreur lors de l\'actualisation : ' + e.mesage)
        } finally {
            setLoading(false)
        }
    }

    return (
        <div className='shadow mr-2em padding-10 borderRadius'>
            <h3>Informations fiscales</h3>
            <FormComponent
                inputs={inputs}
                sendForm={sendForm}
                centered={true}
                responsive={false}
                segment={false}
                messages={{ submit: 'Modifier les infos' }}
                loading={loading}
            />
        </div>
    );
};

export default UserFiscalForm;
import { FreelanceOnboardingStatuses, onboardingStatuses, StudioOnboardingStatuses } from 'constants/OnboardingStatuses.constant';
import React, { useCallback, useMemo } from 'react';
import { toast } from 'react-toastify';
import { Button } from 'semantic-ui-react';
import { updateUserStatusAPI } from '../helpers'
import { UserTypes } from 'constants/UserTypes'

const UserPageActionButton = ({
    user = {}
}) => {
    const updateStatus = useCallback(async (status) => {
        try {
            await updateUserStatusAPI(user.id, status)
            toast("Compte activé")
        } catch (error) {
            toast('Erreur : ' + error.message)
        }
    }, [user])

    const nextStatus = useMemo(() => {
        const statuses = user.type === UserTypes.FREELANCE ? FreelanceOnboardingStatuses : StudioOnboardingStatuses
        const keysOnboardingTypped = Object.keys(statuses)
        const actualUserStepKey = (Object.entries(onboardingStatuses).find(([key, value]) => value === user.status) || [])[0]
        const nextKey = keysOnboardingTypped[keysOnboardingTypped.indexOf(actualUserStepKey) + 1]
        return statuses[nextKey]
    }, [user])

    switch (user.status) {
        case onboardingStatuses.WAITING_FISCAL_REVIEW:
            return <Button primary onClick={() => updateStatus(nextStatus)}>
				Valider les informations de l'utilisateur
			</Button>
        case onboardingStatuses.ACTIVE:
            return 'Profil validé'
        default:
            return null
    }
};

export default UserPageActionButton;
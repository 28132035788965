const StudioInfos = ({
    studio_name,
    address,
    collaborators,
    since,
    franchise,
    email,
    phone,
    firstname,
    lastname,
}) => {
    return (
        <div>
            {!!studio_name && <div><span className='mr-3'>studio_name : </span><span>{studio_name}</span></div>}
            {!!email && <div><span className="mr-3">email : </span><span>{email}</span></div>}
            {!!phone && <div><span className="mr-3">phone : </span><span>{phone}</span></div>}
            {!!firstname && <div><span className="mr-3">firstname : </span><span>{firstname}</span></div>}
            {!!lastname && <div><span className="mr-3">lastname : </span><span>{lastname}</span></div>}
            {!!address && <div><span className='mr-3'>address : </span><span>{address}</span></div>}
            {!!collaborators && <div><span className='mr-3'>collaborators : </span><span>{collaborators}</span></div>}
            {!!since && <div><span className='mr-3'>since : </span><span>{since}</span></div>}
            {!!franchise && <div><span className='mr-3'>franchise : </span><span>{franchise}</span></div>}
        </div>
    )
}

export default StudioInfos
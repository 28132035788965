import { getContractConfirmations } from 'containers/contract/helpers';
import { getContract, getContractIssues } from 'containers/payment/UnlockFunds/helpers';
import { useCallback, useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { removeEvent, socket } from 'socket';
import { Store } from '../store/store';

export const useContract = (contractId) => {
    const { dispatch, state: { socketConnected } } = useContext(Store);
    const [contract, setContract] = useState({})
    const [issues, setIssues] = useState([])
    const navigate = useNavigate()

    const loadContract = useCallback(async () => {
        try {
            const newContract = await getContract(contractId)
            newContract.confirmations = await getContractConfirmations(contractId)
            setContract(newContract)
            const newIssues = await getContractIssues(contractId)
            setIssues(newIssues)
        } catch (error) {
            console.log('error', error)
        }

    }, [contractId])

    useEffect(() => {
        if (contractId) {
            loadContract(contractId)
        }
    }, [contractId])

    useEffect(() => {
        if (socketConnected && socket) {
            socket.emit('join_contract', contractId);
            socket.on(`contract updated`, () => {
                loadContract()
            });
            socket.on(`contract removed`, () => {
                toast('Le contrat a été supprimé.')
                navigate('/missions')
            });
        }
        return () => {
            if (socketConnected && socket) {
                socket.emit('leave_contract', contractId);
                removeEvent(`contract updated`);
                removeEvent(`contract removed`);
            }
        };
    }, [contractId, socketConnected])


    return {
        contract,
        setContract,
        issues,
        setIssues
    };
};

import React, { useCallback, useMemo, useState } from 'react';
import { UserTypes } from 'constants/UserTypes'
import { toast } from 'react-toastify';
import { updateFreelance, updateStudio } from '../helpers';
import Loader from 'components/Loader';
import { isEmpty } from 'lodash';
import { getOnlyUpdatedValues, objectToFormData } from 'containers/helpers';
import FormComponent from 'components/Form/Form';

const AddAdminAddress = ({
    user = {}
}) => {
    const [loading, setLoading] = useState(false)
    const getAPIFunc = useMemo(() => user.type === UserTypes.FREELANCE ? updateFreelance : updateStudio, [user])
    const updateAddressClick = useCallback(async (values) => {
        setLoading(true)
        try {
            const updatedValues = getOnlyUpdatedValues(user, values)
            if (isEmpty(updatedValues)) {
                toast('Rien n\'a été actualisé dans le formulaire.')
                return
            }
            const newUser = await getAPIFunc(objectToFormData(updatedValues), user.type_id)
            toast('Adresse Modifiée')
        } catch (e) {
            console.log(e)
        } finally {
            setLoading(false)
        }
    }, [user, getAPIFunc])

    const inputs = useMemo(() => [
        {
            type: 'text',
            name: 'address',
            label: 'Adresse',
            placeholder: 'Adresse',
            required: true,
            silentRequired: true,
            fluid: true,
            value: user.address,
        },
        {
            type: 'text',
            name: 'lon',
            label: 'Longitude',
            placeholder: '10.124214',
            required: true,
            silentRequired: true,
            fluid: true,
            value: user.lon,
        },
        {
            type: 'text',
            name: 'lat',
            label: 'Latitude',
            placeholder: '20.124214',
            required: true,
            silentRequired: true,
            fluid: true,
            value: user.lat,
        },
        {
            type: 'number',
            name: 'radius',
            label: 'À combien de kms voulez-vous travailler ?',
            placeholder: 'À combien de kms voulez-vous travailler ?',
            required: true,
            silentRequired: true,
            fluid: true,
            value: user.radius,
        },
    ], [user]);

    return (
        loading ? <Loader /> :
            <div>
                <FormComponent
                    inputs={inputs}
                    sendForm={updateAddressClick}
                    loading={loading}
                    centered={true}
                    responsive={false}
                />
            </div>
    );
};

export default AddAdminAddress;
import MyTable from "components/MyTable/MyTable";
import { dayStatuses } from "constants/DayStatuses";
import useLanguage from "hooks/useLanguage";
import React, { useMemo } from "react";

const ContractTable = ({
	contracts = []
}) => {
	const lang = useLanguage("statistics");

	const contractsColumns = useMemo(() => [
		{ title: lang.count, render: ({ count }) => count },
		{ title: lang.status, render: ({ status }) => Object.keys(dayStatuses).find(key => dayStatuses[key] === status) },
		{ title: lang.freelancePrice, render: ({ freelance_price }) => freelance_price + '€ HT' },
		{ title: lang.studioPrice, render: ({ studio_price }) => studio_price + '€ HT' },
		{ title: lang.benefit, render: ({ studio_price, freelance_price }) => ((studio_price - freelance_price) * 1.2).toFixed(2) + '€ TTC' }
	], [lang]);

	return (
		<MyTable
			columns={contractsColumns}
			data={contracts}
			options={{ paging: false }}
		/>
	)
};

export default ContractTable;

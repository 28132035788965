import { fetchApi } from "containers/helpers";

export const getContractIssues = (contractId) => fetchApi('/issues/contract/'+contractId)

export const getContract = contractId =>  fetchApi('/contract/'+contractId)

export const getFreelance = userId => fetchApi('/freelance/'+userId)

export const unlockFundsAPI = contractId => fetchApi('/contract/unlockFunds/'+contractId, {
    method: 'PATCH'
})

export const sendBankAccountRequiredNotification = userId => fetchApi('/notification/bankAccountRequired/'+userId, {
    method: 'POST'
})
import { getDistanceBetweenTwoPoints } from 'calculate-distance-between-coordinates'

export default class DistanceService {
    //radius en km
    static DBFloatToJsFloat(DBFloat) {
        return parseFloat(DBFloat)
    }

    static getUserCoordonates({ lat, lon }) {
        return {
            lat: this.DBFloatToJsFloat(lat),
            lon: this.DBFloatToJsFloat(lon)
        }

    }

    static isDistanceAcceptable(studio = { lat: 1, lon: 1 }, freelance = { lat: 1, lon: 1 }, radius = 1) {
        const studioCoordinate = this.getUserCoordonates(studio)
        const freelanceCoordinate = this.getUserCoordonates(freelance)
        const distance = getDistanceBetweenTwoPoints(studioCoordinate, freelanceCoordinate, 'km')
        return distance <= radius
    }

    //in KMS
    static getDistanceBetweenCoords(studio = { lat: 1, lon: 1 }, freelance = { lat: 1, lon: 1 }) {
        const studioCoordinate = this.getUserCoordonates(studio)
        const freelanceCoordinate = this.getUserCoordonates(freelance)
        const distance = getDistanceBetweenTwoPoints(studioCoordinate, freelanceCoordinate, 'km')
        return distance
    }
}
import { UserTypes } from 'constants/UserTypes';
import React from 'react';
import FreelanceInfos from './FreelanceInfos';
import StudioInfos from './StudioInfos';

const UserInfos = ({
    user = {}
}) => {
    return (
        <div>
            <div>
                <span>Type:  {Object.keys(UserTypes)[user.type]}</span>
                <span>    |    </span>
                <span>ID:  {user.id}</span>
            </div>
            {user.type === UserTypes.FREELANCE
                ? <FreelanceInfos {...user} />
                : <StudioInfos {...user} />}
        </div>
    );
};

export default UserInfos;
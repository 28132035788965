export const FR = {
	address: 'Adresse',
	city: 'Ville',
	country: 'Pays',
	zipcode: 'Code postal',
	noLocationFound: 'Aucune localisation trouvée',
}
  
export const EN = {
	address: 'Address',
	city: 'City',
	country: 'Country',
	zipcode: 'Zipcode',
	noLocationFound: 'No location found',
}
  
export default {
	fr: FR,
	us: EN,
}
  
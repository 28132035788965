import classes from './FreelanceProfileCard.module.scss'
import useLanguage from 'hooks/useLanguage'
import useLocale from 'hooks/useLocale'
import Typography from 'components/Typography'
import StarRating from 'components/StarRating/StarRating'
import { getDateFormatted, shortDateFormat } from 'modules/dates'
import FreelanceBadges from 'components/FreelanceBadges'
import classNames from 'classnames'
import { useMemo } from 'react'
import { getPercentile } from 'containers/availabilities/Availabilities/helpers'
import DistanceService from 'modules/distance'
import { roundTo2Dec } from 'containers/helpers'

function FreelanceProfileCard({
	img,
	firstName,
	city,
	rating,
	nRatings,
	isAvailableForWholeMission,
	hourRate,
	totalRate,
	availableDays,
	badges,
	reductionPercentile = 0,
	created_at,
	lon,
	lat,
	location = null,
	address,
	radius,
	is_active
}) {
	const lang = useLanguage('marketPlace')
	const locale = useLocale()
	const profileLang = useLanguage('profile')

	const percentileText = useMemo(() => getPercentile(reductionPercentile), [reductionPercentile])
	const distance = useMemo(() => location
		? roundTo2Dec(DistanceService.getDistanceBetweenCoords({ lon, lat }, location))
		: null, [lon, lat, location])
	const distanceIsAcceptable = useMemo(() => location
		? roundTo2Dec(DistanceService.isDistanceAcceptable(location, { lon, lat }, radius))
		: true, [lon, lat, location, radius])

	return (<div className={classes.container}>
		<div className={classes.topContainer}>
			<div className={classes.circleWrapper}>
				{!!!is_active && <div className={classes.blockedAccount} >
					Compte bloqué
				</div>}
				<span
					className={
						classNames(
							classes.circle,
							isAvailableForWholeMission && classes.success
						)
					}
				/>
			</div>
			<div
				style={{
					background: `url(${img || '/assets/default-img.png'}`,
				}}
				className={classes.img}
			/>
			<div className={classes.freelanceInfosContainer}>
				{!!(distance != null) && <Typography
					variant='subTitle3'
					classes={{ root: classes.distance }}
				>
					{distance + ' kms'}
				</Typography>}
				<div>
					<div className={classes.inlineBlock}>
						<Typography
							classes={{ root: classNames(classes.name, classes.inlineBlock) }}
							primaryColor
							variant='subTitle2'
						>
							{firstName}
						</Typography>
					</div>
					<div className={classes.ratingLine}>
						<StarRating size='little' rating={rating} />
						<span className={classes.ratingsCount}>
							({nRatings || 0})
						</span>
					</div>
					<div className={classes.labelContainer}>
						<div className={classNames(classes.label, classes.tarif, classes.relative)}>
							{lang.tarif} : {hourRate}€ HT/{lang.day}
							{/* {reductionPercentile !== 0 && <InfoReduction
								percentile={percentileText}
								initialPrice={withoutReductionPriceDay}
								price={priceDay}
							/>} */}
						</div>
						<div className={classNames(classes.label, classes.tarifTotal, classes.relative)}>
							{lang.tarifTotal} : {totalRate}€ HT
							{/* {reductionPercentile !== 0 && <InfoReduction
								percentile={percentileText}
								initialPrice={withoutReductionPriceDays}
								price={priceDays}
							/>} */}
						</div>
					</div>
				</div>
			</div>
		</div>
		<div className={classes.bottomContainer}>
			<div className={classNames(classes.padding1, classes.fullWidth, classes.bottomContainerContent)}>
				{/* <LevelBar level={level} displayLevel /> */}
				<div>
					{isAvailableForWholeMission ? (
						<div className={classes.isAvailableContainer}>
							<Typography italic>{lang.availableForAll}</Typography>
							<span className={classNames(classes.circle, classes.success)} />
						</div>
					) : (
						<div className={classNames(classes.isAvailableContainer, classes.flexWrap)}>
							<Typography italic>
								{lang.availableFor(availableDays.length)} :
							</Typography>
							<div className={classes.daysWrapper}>
								{availableDays.slice(0, 2).map((day, i) => (
									<span className={classNames(classes.dayItem, classes.bullets)} key={i}>
										{shortDateFormat(day, locale)}
									</span>
								))}
								{availableDays.length > 2 &&
									<span className={classNames(classes.dayItem, classes.bullets)}>
										...
									</span>}
								<div className={classes.fullDays}>
									{availableDays.map((day, i) => (
										<span className={classes.dayItem} key={i}>
											{shortDateFormat(day, locale)}
										</span>
									))}
								</div>
							</div>
						</div>
					)}
					{reductionPercentile !== 0 && <div>
						{lang.reductionAboutDays(percentileText, availableDays.length)}
					</div>}
					<Typography
						// variant='subTitle3'
						classes={{ root: classes.mb1 }}
					>
						{address}
					</Typography>
				</div>
				<div className={classes.badgesContainer}>
					<FreelanceBadges badges={badges} />
				</div>
				<div className={classes.bottomContainerOfBottom}>
					{created_at && <Typography italic >{profileLang.memberSince} {getDateFormatted(created_at)}</Typography>}
					<Typography
						italic
						classes={{ root: distanceIsAcceptable ? classes.radiusAcceptable : classes.radiusForbidden }}
					>
						max {radius} kms
					</Typography>
				</div>

			</div>
		</div>
	</div >)
}

export default FreelanceProfileCard

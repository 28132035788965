import React, { useCallback, useMemo, useState } from 'react';
import { resendConfirmationsAPI } from 'containers/contract/helpers';
import { toDateInput } from 'containers/level/LevelUp/dates';
import { Button } from 'semantic-ui-react';
import { toast } from 'react-toastify';
import { dayStatuses } from 'constants/DayStatuses';
import MyTable from './MyTable/MyTable';

const ContractConfirmations = ({
    confirmations = [],
    contractId,
    status
}) => {
    const [loading, setLoading] = useState(false)

    const searchInDate = (value, { date }) => {
        return toDateInput(date).includes(value)
    }
    const columns = [
        { title: 'Date de la mission', render: ({ date }) => toDateInput(date), customFilterAndSearch: searchInDate },
        { title: 'Confirmation du Freelance', render: ({ freelance }) => !!freelance ? 'Confirmé' : 'Non confirmé' },
        { title: 'Confirmation du Studio', render: ({ studio }) => !!studio ? 'Confirmé' : 'Non confirmé' },

    ]

    const resendConfirmations = useCallback(async () => {
        setLoading(true)
        try {
            await resendConfirmationsAPI(contractId)
            toast('Succès')
        } catch (error) {
            toast('Erreur : ' + error.message)
        } finally {
            setLoading(false)
        }
    }, [contractId])

    const isSendable = useMemo(() => status === dayStatuses.WAITING_ADMIN_REVIEW_DAYS_CONFIRMATION, [status])
    return (
        !!confirmations && <div style={{ maxWidth: "100%" }}>
            <MyTable
                columns={columns}
                data={confirmations}
                title={'Confirmations'}
                options={{
                    paging: false,
                }}
            />
            {isSendable && <Button onClick={resendConfirmations} color="pink" loading={loading}>
                Renvoyer une demande de validation des confirmations
            </Button>}
        </div>
    );
};

export default ContractConfirmations;
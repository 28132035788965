import io from "socket.io-client";
const apiUrl = process.env.REACT_APP_WEBSOCKET_URL // 'https://localhost:9000'

export const socketRooms = [
    'update user connected list',
    'connect',
    'notification',
    'delete notification',
    'new mail',
    'update_message',
    'update_docx',
    'update valid email',
    'subscriptionPayment',
    'singlePayment',
]

export const initSocket = () => io(apiUrl, {
    transports: ['websocket'],
    query: { token: localStorage.getItem('token') },
    secure: true,
    reconnection: true
});

export let socket

export const removeEvent = (name) => {
    socket.removeListener(name)
}
export const reloadSocket = () => {
    if (socket) {
        socketRooms.map(room => socket.removeListener(room, timestamp => {
            console.log(timestamp)
        }))
    }
    socket = initSocket();
}

export default {
    socket,
    socketRooms,
    reloadSocket,
    removeEvent
}

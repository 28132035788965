import Button from 'components/Button';
import MultipleDatePicker from 'components/MultipleDatePicker/MultipleDatePicker';
import React, { useCallback, useState } from 'react';
import ChooseStudio from './ChooseStudio';
import { getFreelancesForPotentialMission } from './helpers';
import classes from 'styles/classes.module.scss'
import classNames from 'classnames';
import SearchAddressFields from 'components/SearchAddressFields/SearchAddressFields';

const SearchAvailabilities = ({
    setFreelances = () => { },
    location,
    setLocation = () => { }
}) => {
    const [selectedDates, setSelectedDates] = useState([])
    const [isExistingStudio, setIsExistingStudio] = useState(true)

	const searchFreelances = useCallback(() => {
        getFreelancesForPotentialMission(selectedDates, location)
            .then(setFreelances)
    }, [location, selectedDates, setFreelances])
    return (
        <div>
            <MultipleDatePicker
                selectedDates={selectedDates}
                setSelectedDates={setSelectedDates}
            />
            <Button
                className={classNames(classes.mb1, classes.mt1)}
                type='warning'
                onClick={() => setIsExistingStudio(!isExistingStudio)}>
                {isExistingStudio ? 'Saisir une nouvelle adresse' : 'Chercher pour une entreprise existant'}
            </Button>
            {
                isExistingStudio
                    ? <ChooseStudio setLocation={setLocation} location={location} />
                    : <SearchAddressFields onSelect={setLocation} />
            }
            <Button
                className={classes.mt1}
                type='success'
                disabled={selectedDates.length <= 0}
                onClick={searchFreelances}>Lancer la recherche</Button>
        </div>
    );
};

export default SearchAvailabilities;
import React, { useMemo, useContext } from 'react';
import StatusGodson from './StatusGodson';
import { Store } from 'store/store';
import { toDateInput } from 'containers/level/LevelUp/dates';
import MyTable from 'components/MyTable/MyTable';

const GodsonsTable = ({godsons = [], updateGodsons = () => {}}) => {
    const { state: { language: { godson: godsonLang } } } = useContext(Store)
    const columns = useMemo(() => [
        {
            title: godsonLang.status, render: ({status, user}) => <StatusGodson status={status} user={user} updateGodsons={updateGodsons} />,
        },
        {
            title: godsonLang.createdAt,  render: ({created_at}) => toDateInput(created_at),
        },
    ], [godsonLang, updateGodsons])

    return (
            <MyTable
                columns={columns}
                data={godsons}
                title={godsonLang.arrayOf}
                options={{ paging: false }}
            />
    );
};

export default GodsonsTable;
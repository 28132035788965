import RightSide from 'components/RightSide';
import React, { useEffect, useMemo, useState } from 'react';
import MissionListComponent from './MissionListComponent';
import { getMissions } from './helpers';
import useElemSocketUpdates from 'hooks/useElemSocketUpdates'
import TitlePage from 'components/TitlePage';
import { useStoreUtils } from 'hooks/useStoreUtils';

const MissionList = () => {
    const [missions, setMissions] = useState([])
	const {
        setTableLoadingOn,
        setTableLoadingOff
    } = useStoreUtils()
    // const { socketConnected } = useSockets()

    useElemSocketUpdates('mission', setMissions)

    // useEffect(() => {
    //     if (socketConnected && socket) {
    //         socket.on('add mission', (newMission) => {
    //             setMissions(missions => [...missions, newMission])
    //         });
    //         socket.on('remove mission', (missionRemoved) => {
    //             setMissions(missions => missions.filter(mission => missionRemoved.id !== mission.id))
    //         });
    //     }
    //     return () => {
    //         if (socketConnected && socket) {
    //             removeEvent('add mission');
    //             removeEvent('remove mission');
    //         }
    //     };
    // }, [socketConnected])

    useEffect(() => {
		setTableLoadingOn();
        getMissions()
            .then(setMissions)
			.finally(setTableLoadingOff)
    }, [])

    const getlastUpdated = ({ days }) => days
        .reduce((prev, actual) =>
            prev > new Date(actual.updated_at)
                ? prev
                : new Date(actual.updated_at)
            , new Date(days[0].updated_at))


    //chercher par updated_at
    const missionsOrdered = useMemo(() => [...missions]
        .filter(({ days }) => !!days && Object.keys(days).length > 0)
        .sort((a, b) => {
            //la valeur la plus haute est la plus récente
            const aLast = getlastUpdated(a).getTime()
            const bLast = getlastUpdated(b).getTime()

            return bLast - aLast
        }), [missions])

    return (
        <RightSide>
            <TitlePage>Liste des missions</TitlePage>
            <MissionListComponent missions={missionsOrdered} />
        </RightSide>
    );
};

export default MissionList;
import { IconButton, Tooltip } from '@material-ui/core';
import RightSide from 'components/RightSide';
import UserInfos from 'components/UserInfos';
import { UserTypes } from 'constants/UserTypes';
import { blockAccountAPI, deleteUser, getUserFromApi, unlockAccountAPI, unsubscribeApi } from 'containers/helpers';
import { useSockets } from 'hooks/useSockets';
import React, { useEffect, useMemo, useState } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { removeEvent, socket } from 'socket';
import FiscalFileComponent from './FiscalFileComponent';
import UserPageActionButton from './UserPageActionButton';
import LocalPoliceIcon from '@mui/icons-material/LocalPolice';
// import AutoGraphIcon from '@mui/icons-material/AutoGraph';
import Button from 'components/Button';
import toast from 'components/toast';
import { onboardingStatuses } from 'constants/OnboardingStatuses.constant';

const UserPage = () => {
	const navigate = useNavigate();
	const userId = Number(useParams().userId);
	const [user, setUser] = useState();
	const { socketConnected } = useSockets();
	useEffect(() => {
		getUserFromApi(userId).then(setUser);
	}, [userId]);

	useEffect(() => {
		if (socketConnected && socket) {
			socket.on('users updated', (userIdFromSocket) => {
				if (Number(userIdFromSocket) === userId) {
					console.log('user updated');
					getUserFromApi(userId).then(setUser);
				}
			});
		}
		return () => {
			if (socketConnected && socket) {
				removeEvent('users updated');
			}
		};
	}, [socketConnected, userId]);

	const isFreelance = useMemo(() => user && user.type === UserTypes.FREELANCE, [user]);
	const removeUser = async () => {
		try {
			await deleteUser(userId);
			toast.success('Compte supprimé');
			navigate('/');
		} catch (error) {
			toast.error(error.message);
		}
	};
	const blockAccount = async () => {
		try {
			await blockAccountAPI(userId);
			toast.success('Compte bloqué');
		} catch (error) {
			toast.error(error.message);
		}
	};
	const unlockAccount = async () => {
		try {
			await unlockAccountAPI(userId);
			toast.success('Compte bloqué');
		} catch (error) {
			toast.error(error.message);
		}
	};

	const handleUnsubscribe = async () => {
		try {
			await unsubscribeApi(userId);
			toast.success('Abonnement annulé');
		} catch (err) {
			toast.error(err.message);
		}
	};

	const isActive = useMemo(() => user && user.is_active, [user]);
	const isPremium = useMemo(() => user && !!user.premium, [user]);
	const isRemoveable = useMemo(() => user && user.status !== onboardingStatuses.ACTIVE, [user]);

	return (
		<RightSide>
			<Button type="danger" onClick={isActive ? blockAccount : unlockAccount}>
				{isActive ? 'Bloquer' : 'Débloquer'} l'utilisateur
			</Button>
			<Button type="cancel" onClick={isActive ? blockAccount : unlockAccount}>
				{isActive ? 'Bloquer' : 'Débloquer'} l'utilisateur
			</Button>
			{isRemoveable && (
				<Button type="black" onClick={removeUser}>
					Supprimer l'utilisateur
				</Button>
			)}
			{isPremium && (
				<Button type="cancel" onClick={handleUnsubscribe}>
					Annuler l'abonnement de l'utilisateur
				</Button>
			)}
			<UserInfos user={user} />
			{isFreelance && (
				<>
					<Button link={'/godsons/' + userId}>Accéder à la page des codes de parrainage</Button>
					<Link to={'/freelance/badges/' + userId}>
						<Tooltip title="Accéder aux badges de l\'utilisateur">
							<IconButton>
								<LocalPoliceIcon />
							</IconButton>
						</Tooltip>
					</Link>
					{/* <Link to={"/freelance/levelup/" + userId}>
						< Tooltip title="Accéder à la page d'augmentation de level">
							<IconButton>
								<AutoGraphIcon />
							</IconButton>
						</Tooltip>
					</Link> */}
				</>
			)}
			<UserPageActionButton user={user} />
			<FiscalFileComponent user={user} />
		</RightSide>
	);
};

export default UserPage;

import { IconButton } from "@material-ui/core";
import { Tooltip } from "antd";
import MyTable from "components/MyTable/MyTable";
import React, { useMemo } from "react";
import { Link } from "react-router-dom";
import AutoGraphIcon from '@mui/icons-material/AutoGraph';
import PersonIcon from '@mui/icons-material/Person';
import moment from "moment";
import useLanguage from "hooks/useLanguage";

const FreelanceProposalTable = ({
	freelanceWithProposal = []
}) => {
	const lang = useLanguage("statistics");

	const freelanceWithProposalColumns = useMemo(() => [
		{ title: lang.name, render: ({ company_name }) => company_name },
		{
			title: lang.actions,
			render: ({ id }) => (
				<>
                	<Link to={"/freelance/badges/" + id}>
						<Tooltip title="Accéder à la page d'augmentation de level">
							<IconButton>
								<AutoGraphIcon />
							</IconButton>
						</Tooltip>
					</Link>
					<Link to={"/freelance/levelup/" + id}>
						<Tooltip title="Accéder à la page d'augmentation de level">
							<IconButton>
								<AutoGraphIcon />
							</IconButton>
						</Tooltip>
					</Link>
					<Link to={"/user/" + id}>
						<Tooltip title="Accéder au profil de l'utilisateur">
							<IconButton>
								<PersonIcon />
							</IconButton>
						</Tooltip>
					</Link>
				</>
			)
		},
		{
			title: lang.requestDate,
			render: ({ dates }) => moment(dates[dates.length - 1]).format('DD/MM/YYYY')
		},
		{
			title: lang.numberDaysSinceRequest,
			render: ({ updated_at }) => moment().diff(moment(updated_at), 'days') + ' jours'
		}
	], [lang]);

	return (
		<MyTable
			columns={freelanceWithProposalColumns}
			data={freelanceWithProposal}
			options={{ paging: false }}
		/>
	)
};

export default FreelanceProposalTable;

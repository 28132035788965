import { useCallback, useEffect, useReducer, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import DataFilters from 'components/DataFilters'
import DataActions from 'components/DataActions'
import columns from './columns'
import { filters, reducer, initial } from './filters'
import { actions } from '../actions'
import useElemSocketUpdates from 'hooks/useElemSocketUpdates'
import MyTable from 'components/MyTable/MyTable'
import TitlePage from 'components/TitlePage'
import RightSide from 'components/RightSide'
import { useStoreUtils } from 'hooks/useStoreUtils'
import { getIssues } from './helper'

const IssuesAdminPage = () => {
	const [selected, setSelected] = useState([])
	const navigate = useNavigate()
	const [data, setData] = useState([])
	const [state, dispatch] = useReducer(reducer, initial)
	const { setTableLoadingOn, setTableLoadingOff } = useStoreUtils()
	const initIssues = useCallback(
		() => getIssues(state.src)
			.then((issues) => setData(issues.sort((a, b) => b.id - a.id)))
			.finally(setTableLoadingOff)
		, [setTableLoadingOff, state.src])

	useEffect(() => {
		setTableLoadingOn()
		initIssues()
	}, [])

	useElemSocketUpdates('issue', (issues) => setData(issues.sort((a, b) => a.id - b.id)))

	return (
		<RightSide>
			<TitlePage>{'Issues'}</TitlePage>
			{filters && <DataFilters onFilter={({ key }) => dispatch({ type: key })} filters={filters} />}
			{actions && (
				<DataActions
					onAction={(action) => action(selected)}
					actions={actions}
				/>
			)}
			<MyTable
				onRowClick={(event, rowData) => navigate(`/issue/${rowData.id}`)}
				onSelectionChange={setSelected}
				title={'Issues'}
				options={{ selection: true, sorting: true }}
				columns={columns}
				data={data}
			/>
		</RightSide>
	)
}

export default IssuesAdminPage

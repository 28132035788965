import { orderBy } from 'lodash'
import ReviewQuestionTypes from 'constants/ReviewQuestionTypes'
import Typography from 'components/Typography'
import StarRating from 'components/StarRating/StarRating'
import { useMemo } from 'react'
import useLanguage from 'hooks/useLanguage'

const ReviewWrapper = ({ title = false, reviews:reviewsFromParams = [] }) => {
	const lang = useLanguage('review')
	const reviews = useMemo(() => reviewsFromParams
		.map(review => ({ ...review, text: lang.questions[review.text_key] })),
		[reviewsFromParams, lang])
	return (
		<>
			{title && <Typography variant='subTitle2'>{title}</Typography>}
			{orderBy(reviews, 'question').map(({ text, type, answer }, key) => (
				<div key={key}>
					<Typography variant='subTitle3'>{text}</Typography>
					{type === ReviewQuestionTypes.SLIDE ? (
						<StarRating rating={answer} />
					) : type === ReviewQuestionTypes.TEXT ? (
						<Typography>{answer}</Typography>
					) : null}
				</div>
			))}
		</>
	)
}
export default ReviewWrapper

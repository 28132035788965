import { useMemo } from "react";
import MenuNavigationComponent from 'components/MenuNavigationComponent';
import ContractTable from "./ContractTable";
import MissionTable from "./MissionTable";
import FreelanceTable from "./FreelanceTable";
import FreelanceProposalTable from "./FreelanceProposalTable";
import useLanguage from "hooks/useLanguage";

const StatisticsListComponent = ({
	contracts,
	missions,
	freelance,
	freelanceWithProposal
}) => {
	const lang = useLanguage("statistics");

	const components = useMemo(() => [
		{
			title: lang.titleContract,
			component: <ContractTable contracts={contracts} />
		},
		{
			title: lang.titleMissionWithoutFreelance,
			component: <MissionTable missions={missions} />
		},
		{
			title: lang.titleFreelances,
			component: <FreelanceTable freelances={freelance} />
		},
		{
			title: lang.titleFreelancesProposal,
			component: <FreelanceProposalTable freelanceWithProposal={freelanceWithProposal} />
		}
	], [contracts, missions, freelance, freelanceWithProposal, lang]);

	return (
		<MenuNavigationComponent components={components} />	
	);
};

export default StatisticsListComponent;

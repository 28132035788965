import React from 'react'
import MeetingPlannedFor from './MeetingPlannedFor'

function AdminMeetingInfos({ meeting, userId }) {
    if (!meeting || !userId) {
        return null
    }

    return (
        <div>
            <span>
                {meeting && meeting.admin === userId
                    ? 'My Meeting'
                    : !meeting.admin
                        ? 'No one assigned to this meeting'
                        : 'Other admin assigned to this meeting'}
            </span>
            <MeetingPlannedFor meeting={meeting} />

        </div>
    )
}

export default AdminMeetingInfos
import RightSide from 'components/RightSide';
import TitlePage from 'components/TitlePage';
import React, { useState } from 'react';
import FreelanceMarketplace from './FreelanceMarketplace';
import SearchAvailabilities from './SearchAvailabilities';

const Availabilities = () => {

    const [APIfreelances, setAPIFreelances] = useState([])
    const [location, setLocation] = useState(null)
    return (
        <RightSide>
            <TitlePage>
                Rechercher un profil
            </TitlePage>
            <SearchAvailabilities
                location={location}
                setLocation={setLocation}
                setFreelances={setAPIFreelances} />
            <FreelanceMarketplace
                APIfreelances={APIfreelances}
                location={location}
            />
            {/* <SearchDates />
            <SearchLocation />
            <div>
                <SwitchButton />
                {
                    marketplace ? <Marketplace /> : <Calendar /> //calendar pour les stats // estcequil y a un jour ou ya personne || bc de monde || ....
                }
            </div> */}

        </RightSide>
    );
};

export default Availabilities;
import { IconButton } from "@material-ui/core";
import { Tooltip } from "antd";
import MyTable from "components/MyTable/MyTable";
import { useContext, useMemo, useState } from "react";
import { toast } from "react-toastify";
import { Store } from "store/store";
import { deleteBadgeAPI, updateImageAPI } from "../helpers";
import DeleteIcon from '@mui/icons-material/Delete';
import ReplayIcon from '@mui/icons-material/Replay';
import { getImageUrl } from "containers/helpers";
import { Modal } from "semantic-ui-react";
import FormComponent from "components/Form/Form";
import { intensityTypes } from "constants/badgeIntensity.constant";

const BadgeListComponent = () => {
	const [imageToUpdate, setImageToUpdate] = useState()
    const { state: { badges }, dispatch } = useContext(Store)
	const datas = useMemo(() => Object.entries(badges), [badges]);
	const inputs = useMemo(() => [
        {
            type: 'image',
            name: 'icon',
            label: 'Icone',
            required: true,
            silentRequired: true,
            fluid: true,
        }
    ], [])

	const deleteBadge = async name => {
        try {
            await deleteBadgeAPI(name)
            toast('Supprimé')
            dispatch({
                type: 'INIT_BADGES',
                payload: Object
                    .entries(badges)
                    .reduce((prev, [key, value]) => key === name ? prev : {
                        ...prev,
                        [key]: value
                    }, {})
            })
        } catch (error) {
            toast('Error', error.message)
        }
    }

	const updateImage = async (values) => {
        try {
            await updateImageAPI(imageToUpdate, values.icon)
            setImageToUpdate(false)
            toast('Modifié')
        } catch (error) {
            console.log('error', error)
            toast('Error', error.message)
        }
    }

	const columns = [
		{
			title: "Name",
			render: (data) => data[0],
		},
		{
			title: "Action",
			render: (data) => (
				<Tooltip title={'Supprimer tous les badges ' + data[0]}>
					<IconButton onClick={() => deleteBadge(data[0])} >
						<DeleteIcon />
					</IconButton>
				</Tooltip>
			)
		}
	]

	Object.keys(intensityTypes).forEach((intensity, idx) => {
		columns.push({
			title: intensity,
			render: (data) => {
				return(
				<div
					key={'image' + data[1][idx]}
				>
					<img
						alt='Icone'
						src={getImageUrl(data[1][idx])}
						style={{
							width: 50,
							height: 50
						}}
					/>
					<Tooltip title={'Remplacer l\'image'}>
						<IconButton onClick={() => setImageToUpdate(data[1][idx])}>
							<ReplayIcon />
						</IconButton>
					</Tooltip>
				</div>
			)}
		}
	)})

	return (
		<>
		<MyTable
            columns={columns}
            data={datas}
            title={'Studios'}
            options={{ paging: false }}
		/>
		<Modal
			open={!!imageToUpdate}
			onClose={() => setImageToUpdate(false)}
			dimmer="inverted"
			closeIcon
			className="modal-login-container"
		>
			<Modal.Header>{'Veuillez ajouter la nouvelle image'}</Modal.Header>
			<Modal.Content>
				<FormComponent
					inputs={inputs}
					sendForm={updateImage}
					centered={true}
					responsive={false}
					segment={false}
				/>
			</Modal.Content>
		</Modal> 
		</>
	);
};

export default BadgeListComponent;

import MyTable from 'components/MyTable/MyTable';
import { formatDateWithHour } from 'modules/dates';
import React, { useEffect, useState } from 'react';
import classes from 'styles/classes.module.scss'
import { getEmailAdminSent } from './helpers';

const LastEmailsSent = () => {
    const [emails, setEmails] = useState([])

    useEffect(() => {
        getEmailAdminSent()
            .then(setEmails)
    }, [])

    const columns = [
        { title: 'Template', render: email => email.template },
        { title: 'À', render: email => email.to },
        { title: 'Date de création', render: email => email.created_at ? formatDateWithHour(new Date(email.created_at)) : 'Aucune' },
        { title: 'Fin d\'envoi', render: email => email.end_date ? formatDateWithHour(new Date(email.end_date)) : 'En cours d\'envoi' },
        { title: 'Type de donnée', render: email => email.key || 'Aucune' },
        { title: 'Valeur', render: email => email.value || 'Aucune' },
        { title: 'Temps d\'envoi estimé', render: email => email.timer + ' secondes' },
    ]
    return (
        <div className={classes.mt1}>
            <MyTable
                title='Derniers mails envoyés'
                columns={columns}
                data={emails}
                options={{
                    paging: false,
                }}
            />
        </div>
    );
};

export default LastEmailsSent;
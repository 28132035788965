import { onboardingStatuses } from 'constants/OnboardingStatuses.constant';
import React, { useCallback } from 'react';
import { toast } from 'react-toastify';
import Button from 'components/Button'
import { sendEmailValidationAPI } from './helpers';

const StatusUserOnboardComponent = ({
    email,
    status,
    id,
    type
}) => {

    const sendEmailValidation = useCallback(async () => {
        try {
            await sendEmailValidationAPI(email)
            toast('Email renvoyé')
        } catch (error) {
            toast('Error: ' + error.message)

        }
    }, [email])

    switch (status) {
        case onboardingStatuses.WAITING_EMAIL_VALIDATION:
            return <Button type="primary" onClick={sendEmailValidation}>Renvoyer l'email de validation</Button>
        case onboardingStatuses.WAITING_INFORMATIONS:
			case onboardingStatuses.WAITING_STRIPE:
        case onboardingStatuses.WAITING_ADDRESS:
        case onboardingStatuses.WAITING_FISCAL:
            return <Button type="warning" link={'/user/' + id}>{Object.keys(onboardingStatuses)[status]}</Button>
        case onboardingStatuses.WAITING_FISCAL_REVIEW:
            return <Button type="danger" link={'/user/' + id}>
                Vérifier les informations fiscales
            </Button>
        case onboardingStatuses.WAITING_MEETING_PLAN:
        case onboardingStatuses.WAITING_MEETING:
            return <Button type="secondary" link={'/user/' + id}>
                Voir le profil de l'user et modifier son statut
            </Button>
        case onboardingStatuses.WAITING_ONBOARD_VALIDATION:
            return <Button type="secondary" link={'/user/' + id}>
                Voir le profil de l'user et modifier son statut
            </Button>
        case onboardingStatuses.ACTIVE:
            return <Button type="success" link={'/user/' + id}>
                COMPTE ACTIVÉ - Accéder au profil
            </Button>
        default:
            return null
    }


};

export default StatusUserOnboardComponent;
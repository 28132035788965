import RightSide from 'components/RightSide';
import UserInfos from 'components/UserInfos';
import { godfatherStatuses } from 'constants/godfather.constant';
import BankTransferFreelanceInformation from 'containers/payment/UnlockFunds/BankTransferFreelanceInformation';
import { useGodsons } from 'hooks/useGodsons';
import { useUser } from 'hooks/useUser';
import React, { useCallback, useContext } from 'react';
import { useParams } from 'react-router-dom';
import { Store } from 'store/store';
import GodsonsTable from './GodsonsTable';

const GodsonsPage = () => {
    const { state: { language: { godson: godsonLang } } } = useContext(Store)
    const userId = Number(useParams().userId)
    const { godsons, setGodsons } = useGodsons(userId)
    const { user, bankAccount } = useUser(userId)

    const updateGodsons = useCallback(userId => setGodsons(godsons => godsons.map(godson => ({
        ...godson,
        status: godson.user === userId ? godfatherStatuses.PAYMENT_DONE : godson.status
    }))), [setGodsons])

    return (
        <RightSide>
            <UserInfos user={user} />
            {!!bankAccount && <BankTransferFreelanceInformation bankAccount={bankAccount} />}
            <div>{godsonLang.myCode} :{user.code}</div>
            {godsons.length > 0 ? <GodsonsTable updateGodsons={updateGodsons} godsons={godsons} /> : <div>
                {godsonLang.noGodson}
            </div>}
        </RightSide>
    );
};

export default GodsonsPage;
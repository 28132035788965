import { Grid } from '@material-ui/core'
import { Calendar as BigCalendar, momentLocalizer } from 'react-big-calendar'
import { Loader } from 'semantic-ui-react'
import './Calendar.css'
import moment from 'moment'
import 'react-big-calendar/lib/css/react-big-calendar.css'
import 'moment/locale/fr'

const localizer = momentLocalizer(moment)

const defaultStyle = {
	width: '100%',
	height: '100%',
}

const Calendar = ({
	defaultView,
	events,
	views,
	min,
	max,
	step,
	onSelecting,
	onSelectEvent,
	eventPropGetter,
	timeslots,
	components,
	selected,
	onSelectSlot,
	onRangeChange,
	loading,
	selectable,
	style,
}) => {
	return (
		<div className={loading ? 'calendar-loading' : ''}>
			
			{loading && (
				<Loader className='calendar-loader' active size='big' />
			)}
			<Grid
				container
				// className='page-container border-radius-5 shadow bg-white'
				style={{ padding: 8 }}
			>
				<BigCalendar
					messages={{
						next: 'suivant',
						previous: 'précédent',
						today: `aujourd'hui`,
						month: 'Mois',
						week: 'Semaine',
						day: 'Journée',
						noEventsInRange: `Il n'y a pas d'événements dans cette plage de dates.`,
					}}
					onSelectEvent={onSelectEvent}
					eventPropGetter={eventPropGetter}
					culture='fr'
					onRangeChange={onRangeChange}
					onSelecting={onSelecting}
					step={step}
					components={components}
					views={views}
					min={min}
					max={max}
					timeslots={timeslots}
					defaultView={defaultView}
					selectable={selectable}
					onSelectSlot={onSelectSlot}
					selected={selected}
					localizer={localizer}
					events={events}
					style={style || defaultStyle}
				/>
			</Grid>
		</div>
	)
}

export default Calendar

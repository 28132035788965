import Button from 'components/Button';
import RightSide from 'components/RightSide';
import toast from 'components/toast';
import { useTestMode } from 'hooks/useTestMode';
import React, { useState } from 'react';
import { launchChroneForTestAPI } from './helpers';

const Profile = () => {
	const [loading, setLoading] = useState(false);
	const launchChroneTest = async () => {
		setLoading(true);
		try {
			await launchChroneForTestAPI();
			toast.success('Succès');
		} catch (error) {
			toast.error('Erreur: ' + error.message);
		} finally {
			setLoading(false);
		}
	};
	const { isTestMode } = useTestMode();
	return (
		<RightSide>
			{isTestMode && (
				<Button type="danger" onClick={launchChroneTest} loading={loading}>
					Faire passer tous les contrats confirmés à terminés.
				</Button>
			)}
		</RightSide>
	);
};

export default Profile;

import React from 'react';
import AddAddress from './AddAddress';
import AddAdminAddress from './AddAdminAddress';

const UserAddressInfosForm = ({
   user
}) => {
    return (
        <div className='shadow padding-10 borderRadius'>
            <h3>Adresse</h3>
           <AddAddress user={user}  />
            <h3>Modifier l'adresse à la main</h3>
           <AddAdminAddress user={user} />
        </div>
    );
};

export default UserAddressInfosForm;
import { Routes as RoutesFromRouter, Route } from 'react-router-dom'
import { UnAuthRoute, DefaultRoute } from 'components/GuardRoutes'
import ForgotPassword from 'containers/account/ForgotPassword'
import Login from 'containers/account/Login'
import ResetPassword from 'containers/account/ResetPassword'
import ConnectedRoutes from '../ConnectedRoutes/ConnectedRoutes'

export const Routes = () => (
	<RoutesFromRouter>
		<Route
			index
			exact
			path='/login'
			element={
				<UnAuthRoute>
					<Login />
				</UnAuthRoute>
			}
		/>
		<Route
			path='/forgot'
			element={
				<UnAuthRoute>
					<ForgotPassword />
				</UnAuthRoute>
			}
		/>
		<Route
			path='/resetPassword/:token'
			element={
				<UnAuthRoute>
					<ResetPassword />
				</UnAuthRoute>
			}
		/>
		<Route path='/*' element={<ConnectedRoutes />} />
		<Route path='*' element={<DefaultRoute />} />
	</RoutesFromRouter>
)

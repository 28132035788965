import React from 'react';

const FreelanceInfos = ({
    email,
    phone,
    gender,
    firstname,
    lastname,
    birth,
    company_name,
    address,
    radius,
    since,
    degree,
    know_by,
}) => {
    return (
        <div>
            {!!email && <div><span className='mr-3'>email : </span><span>{email}</span></div>}
            {!!phone && <div><span className='mr-3'>phone : </span><span>{phone}</span></div>}
            {!!gender && <div><span className='mr-3'>gender : </span><span>{gender}</span></div>}
            {!!firstname && <div><span className='mr-3'>firstname : </span><span>{firstname}</span></div>}
            {!!lastname && <div><span className='mr-3'>lastname : </span><span>{lastname}</span></div>}
            {!!birth && <div><span className='mr-3'>birth : </span><span>{birth}</span></div>}
            {!!company_name && <div><span className='mr-3'>company_name : </span><span>{company_name}</span></div>}
            {!!address && <div><span className='mr-3'>address : </span><span>{address}</span></div>}
            {!!radius && <div><span className='mr-3'>radius : </span><span>{radius}</span></div>}
            {!!since && <div><span className='mr-3'>since : </span><span>{since}</span></div>}
            {!!degree && <div><span className='mr-3'>degree : </span><span>{degree}</span></div>}
            {!!know_by && <div><span className='mr-3'>know_by : </span><span>{know_by}</span></div>}
        </div>
    );
};

export default FreelanceInfos;
import RightSide from 'components/RightSide';
import React, { useEffect, useState } from 'react';
import { getUsers } from './helpers';
import UserOnboardListComponent from './UserOnboardListComponent';
import { socket, removeEvent } from 'socket';
import { useSockets } from 'hooks/useSockets';
import TitlePage from 'components/TitlePage';
import { useStoreUtils } from 'hooks/useStoreUtils';

const UserOnboardList = () => {
    const [users, setUsers] = useState([])
    const {
        setTableLoadingOn,
        setTableLoadingOff
    } = useStoreUtils()

    const initUsers = () => getUsers()
        .then(setUsers)
        .finally(setTableLoadingOff)

    useEffect(() => {
        setTableLoadingOn()
        initUsers()
    }, [])


    const { socketConnected } = useSockets()

    useEffect(() => {
        if (socketConnected && socket) {
            socket.on('users updated', () => {
                initUsers()
            });
        }
        return () => {
            if (socketConnected && socket) {
                removeEvent('users updated');
            }
        };
    }, [socketConnected])

    return (
        <RightSide>
            <TitlePage>Liste des utilisateurs en cours d'onboard</TitlePage>
            <UserOnboardListComponent users={users} />
        </RightSide>
    );
};

export default UserOnboardList;
import { dayStatuses } from 'constants/DayStatuses';
import { getFile, getFileByNameVisualisationUrl, getToken } from 'containers/helpers';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import Button from 'components/Button';
import NewPageLinkButton from './NewPageLinkButton';
import PriceContractUpdate from './PriceContractUpdate/PriceContractUpdate';
import {
	captureFundsByAppointmentIdApi,
	getPrepaymentApi,
	launchChroneForTestAPI,
	unlockFundsByAppointmentIdApi,
} from 'containers/profile/Profile/helpers';
import toast from './toast';
import { useTestMode } from 'hooks/useTestMode';
import { isEmpty } from 'lodash';
import { PrepaymentTypes } from 'constants/PrepaymentTypes';
import Typography from './Typography';

const ALLOWED_PREPAYMENT_TYPES = [PrepaymentTypes.PENDING, PrepaymentTypes.WAITING];

const ContractsButtons = ({
	studio_contract,
	freelance_contract,
	contractId,
	prices,
	status,
	appointment,
	prepayment,
}) => {
	const [contractFreelanceUrl, setContractFreelanceUrl] = useState('');
	const [contractStudioUrl, setContractStudioUrl] = useState('');
	const [loading, setLoading] = useState(false);
	const getContractsUrl = useCallback(async (studioContract, freelanceContract) => {
		try {
			const { name: studioName } = await getFile(studioContract);
			const { name: freelanceName } = await getFile(freelanceContract);
			setContractStudioUrl(getFileByNameVisualisationUrl(getToken(), studioName));
			setContractFreelanceUrl(getFileByNameVisualisationUrl(getToken(), freelanceName));
		} catch (error) {
			console.log('error', error);
		}
	}, []);

	useEffect(() => {
		if (studio_contract && freelance_contract) getContractsUrl(studio_contract, freelance_contract);
	}, [studio_contract, freelance_contract, getContractsUrl]);

	const launchChroneTest = async () => {
		setLoading(true);
		try {
			await launchChroneForTestAPI(contractId);
			toast.success('Succès');
		} catch (error) {
			toast.error('Erreur: ' + error.message);
		} finally {
			setLoading(false);
		}
	};
	const { isTestMode } = useTestMode();

	const contentByPrepaymentStatus = useMemo(() => {
		if (!prepayment) return '';

		switch (Number(prepayment.status)) {
			case PrepaymentTypes.PENDING:
				return 'En attente de la caputre des fonds sur le compte du client';
			case PrepaymentTypes.WAITING:
				return 'Les fonds sont réservés sur le compte du client';
			case PrepaymentTypes.PAID:
				return 'Les fonds sont capturés sur le compte du client';
			case PrepaymentTypes.CANCELED:
				return 'Le paiement a été annulé';
			default:
				return '';
		}
	}, [prepayment]);

	const reserveFunds = useCallback(async () => {
		setLoading(true);
		try {
			await unlockFundsByAppointmentIdApi(appointment);
			toast.success('Le réservation des fonds a été effectuée avec succès');
		} catch (err) {
			toast.error(err.message);
		} finally {
			setLoading(false);
		}
	}, [appointment]);

	const captureFunds = useCallback(async () => {
		setLoading(true);
		try {
			await captureFundsByAppointmentIdApi(appointment);
			toast.success('Le capture des fonds a été effectuée avec succès');
		} catch (err) {
			toast.error(err.message);
		} finally {
			setLoading(false);
		}
	}, [appointment]);

	return (
		<div>
			<h3>Contrats</h3>
			{isTestMode && status === dayStatuses.BOOKED && (
				<Button onClick={launchChroneTest} loading={loading}>
					Faire passer le contrat à terminé
				</Button>
			)}
			<div>
				<h3>Gestion du paiement stripe</h3>
				<Typography variant="h6">{contentByPrepaymentStatus}</Typography>
				{isTestMode && !isEmpty(prepayment) && ALLOWED_PREPAYMENT_TYPES.includes(prepayment.status) && (
					<Button
						onClick={prepayment.status === PrepaymentTypes.PENDING ? reserveFunds : captureFunds}
						loading={loading}
					>
						{prepayment.status === PrepaymentTypes.PENDING
							? 'Réserver les fonds sur le compte du client'
							: 'Capturer les fonds sur le compte du client'}
					</Button>
				)}
			</div>

			<PriceContractUpdate contractId={contractId} prices={prices} />
			<div className="flex">
				<div>
					<h4>Freelance</h4>
					<NewPageLinkButton href={contractFreelanceUrl}>
						Voir le contrat en pleine page
					</NewPageLinkButton>{' '}
				</div>
				<div>
					<h4>Studio</h4>
					<NewPageLinkButton href={contractStudioUrl}>Voir le contrat en pleine page</NewPageLinkButton>
				</div>
			</div>
		</div>
	);
};

export default ContractsButtons;

import { UserTypes } from 'constants/UserTypes'
import { getUserFromApi } from 'containers/helpers'
import { useEffect, useState } from 'react'
import { getBankAccount } from 'containers/user/helpers'

export const useUser = (userId) => {
	const [user, setUser] = useState([])
	const [bankAccount, setBankAccount] = useState()

	useEffect(() => {
		if (userId) {
			getUserFromApi(userId).then(setUser)
		}
	}, [userId])

	useEffect(() => {
		if (user && user.type === UserTypes.FREELANCE) {
			getBankAccount(user.id).then(setBankAccount)
			.catch(err => console.log(err))
		}
	}, [user])

	return {
		user,
		setUser,
		bankAccount,
	}
}

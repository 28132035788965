import { dayStatuses } from 'constants/DayStatuses';
import { getUserFromApi } from 'containers/helpers';
import { toDateInput } from 'containers/level/LevelUp/dates';
import React, { useEffect, useState } from 'react';
import ContractConfirmations from './ContractConfirmations';
import ContractsButtons from './ContractsButtons';
import DateTooltip from './DateTooltip';
import FreelanceInfos from './FreelanceInfos';
import InvoicesButtons from './InvoicesButtons';
import MissionContractReviews from './MissionContractReviews/MissionContractReviews';
import PricesIconTooltip from './PricesIconTooltip';
import StudioInfos from './StudioInfos';

const ContractInfos = ({
	created_at,
	dates = [],
	freelance_level,
	freelances = [],
	id,
	mission,
	payment,
	reference,
	status,
	studio_contract,
	freelance_contract,
	studio_invoice,
	studio_price,
	freelance_price,
	freelance_prices,
	studio_prices,
	studio_receipt,
	updated_at,
	user,
	confirmations = [],
	appointment_id: appointmentId
}) => {

	const [freelance, setFreelance] = useState()

	useEffect(() => {
		if (freelances && freelances.length > 0) {
			getUserFromApi(freelances[0])
				.then(setFreelance)
		}
	}, [freelances])

	const [studio, setStudio] = useState()

	useEffect(() => {
		if (user) {
			getUserFromApi(user)
				.then(setStudio)
		}
	}, [user])

	return (
		<div>
			<div>dates:  <DateTooltip dates={dates} /></div>
			{/* <div>freelance_level:  {freelance_level}</div> */}
			<div>id:  {id}</div>
			<div>mission:  {mission}</div>
			<div>payment:  {payment}</div>
			<div>reference:  {reference}</div>
			<div>status:  {status}</div>
			<div>studio_contract:  {studio_contract}</div>
			<div>studio_invoice:  {studio_invoice}</div>
			<div>studio_price:  {studio_price}€HT</div>
			<div>freelance_price:  {freelance_price}€HT (mais micro-entreprise donc TTC)</div>
			<div>freelance_prices:  <PricesIconTooltip prices={freelance_prices} /></div>
			<div>studio_prices:  <PricesIconTooltip prices={studio_prices} /></div>
			<div>studio_receipt:  {studio_receipt}</div>
			<div>created_at:  {toDateInput(created_at)}</div>
			<div>updated_at:  {toDateInput(updated_at)}</div>
			<div className='flex space-between'>
				{freelance && <div><h3>Freelance</h3><FreelanceInfos {...freelance} /></div>}
				{studio && <div><h3>Studio</h3><StudioInfos {...studio} /></div>}
			</div>
			<ContractsButtons prices={studio_prices} contractId={id} studio_contract={studio_contract} freelance_contract={freelance_contract} status={status} />
			{status > dayStatuses.WAITING_ADMIN_REVIEW_DAYS_CONFIRMATION && <InvoicesButtons studioInvoiceId={studio_invoice} contractId={id} />}
			<ContractConfirmations confirmations={confirmations} contractId={id} status={status} />
			<MissionContractReviews
				appointmentId={appointmentId}
			/>
		</div>
	);
};

export default ContractInfos;
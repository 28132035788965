import FormComponent from 'components/Form/Form';
import { getOnlyUpdatedValues } from 'containers/helpers';
import { useStoreUtils } from 'hooks/useStoreUtils';
import { isEmpty } from 'lodash';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { toast } from 'react-toastify';
import { createBankAccount, getBankAccount } from '../helpers';

const BankAccountForm = ({
    user
}) => {
    const { state } = useStoreUtils()
    const { language: { onboard: lang, form: formLang } } = state


    const [bankAccount, setBankAccount] = useState({
        iban: '',
        swift: '',
        owner: ''
    })

    useEffect(() => {
        if (user && user.id) {
            getBankAccount(user.id)
                .then(setBankAccount)
				.catch(err => console.log(err))
        }
    }, [user])

    const inputs = useMemo(() => [
        {
            type: 'iban',
            name: 'iban',
            value: bankAccount.iban,
            placeholder: lang.iban,
            label: lang.iban,
            required: true,
            silentRequired: true,
            fluid: true,
        },
        {
            type: 'text',
            name: 'swift',
            value: bankAccount.swift,
            placeholder: lang.swift,
            label: lang.swift,
            required: true,
            silentRequired: true,
            fluid: true,
        },
        {
            type: 'text',
            name: 'owner',
            value: bankAccount.owner,
            placeholder: lang.bankAccountOwner,
            label: lang.bankAccountOwner,
            required: true,
            silentRequired: true,
            fluid: true,
        }
    ], [lang, bankAccount])

    const sendForm = useCallback(async (values) => {
        try {
            const updatedValues = getOnlyUpdatedValues(bankAccount, values)
			if (isEmpty(updatedValues)) {
                toast(formLang.nothingUpdated)
                return
            }
			//j'ai mis values pour faire exprès, sinon il me demande les paramètres manquants
			//on update totalement le bankAccount (même si 2/3 mêmes infos)
            await createBankAccount({ ...values, user: user.id })
            toast(formLang.success)
        } catch (e) {
            console.error(e)
            toast(formLang.error + ' : ' + e.message)
        }
    }, [bankAccount, user.id, formLang])

    return (
        <>
            <div>
                <h2>Compte bancaire</h2>
            </div>
            <FormComponent
                inputs={inputs}
                sendForm={sendForm}
                centered={true}
                responsive={false}
                segment={false}
            />
        </>
    )
};

export default BankAccountForm;
import { IconButton, Tooltip } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { getFreelances } from '../helpers';
import LocalPoliceIcon from '@mui/icons-material/LocalPolice';
import AutoGraphIcon from '@mui/icons-material/AutoGraph';
import { Link } from 'react-router-dom'
import PersonIcon from '@mui/icons-material/Person';
import RightSide from 'components/RightSide';
import TitlePage from 'components/TitlePage';
import FreelanceListComponent from './FreelanceListComponent';
import { useStoreUtils } from 'hooks/useStoreUtils';

const FreelanceList = () => {
	const {
        setTableLoadingOn,
        setTableLoadingOff
    } = useStoreUtils()
    const [freelances, setFreelances] = useState([])
    useEffect(() => {
		setTableLoadingOn()
        getFreelances()
            .then(setFreelances)
			.finally(setTableLoadingOff)
    }, [])
    return (
        <RightSide>
            <TitlePage>Liste des freelances</TitlePage>
            <FreelanceListComponent freelances={freelances} />
        </RightSide>
    );
};

export default FreelanceList;
import FormComponent from 'components/Form/Form';
import React, { useMemo, useState } from 'react';
import RightSide from '../../../components/RightSide';
import { intensityTypes } from '../../../constants/badgeIntensity.constant';
import { createBadge } from '../helpers'
import { useNavigate } from 'react-router-dom'
import TitlePage from 'components/TitlePage';
const CreateBadge = () => {
    const [name, setName] = useState()
    const [step, setStep] = useState(0)
    const navigate = useNavigate()
    const inputs = useMemo(() => [
        !name && {
            type: 'text',
            name: 'name',
            placeholder: 'Nom du badge',
            label: 'Nom du badge',
            initialValue: name,
            value: name,
            required: true,
            silentRequired: true,
            fluid: true,
        },
        {
            type: 'image',
            name: 'icon',
            label: 'Icon de l\'intensité ' + Object.keys(intensityTypes)[step],
            required: true,
            silentRequired: true,
            fluid: true,
        }
    ].filter(e => e), [step, name])
    const sendForm = async (values) => {
        if (!name) { setName(values.name) }
        try {
            await createBadge({
                name: name || values.name,
                icon: values.icon,
                intensity: step
            })
            if (!Object.values(intensityTypes).includes(step + 1)) {
                navigate('/badges')
            }
            setStep(step => step + 1)
        } catch (error) {

        }
    }
    return (
        <RightSide>
            <TitlePage>Créer un nouveau badge</TitlePage>
            <FormComponent
                inputs={inputs}
                sendForm={sendForm}
                centered={true}
                responsive={false}
                segment={false}
            />
        </RightSide>
    );
};

export default CreateBadge;
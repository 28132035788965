import BadgeList from 'containers/badge/BadgeList/BadgeList'
import ContractPage from 'containers/contract/ContractPage/ContractPage'
import FreelanceList from 'containers/freelance/FreelanceList/FreelanceList'
import ManageFreelanceBadges from 'containers/freelance/FreelanceList/ManageFreelanceBadges'
import AdminIssuePage from 'containers/issue/components/AdminIssuePage'
import LevelUp from 'containers/level/LevelUp/LevelUp'
import MissionList from 'containers/mission/MissionList/MissionList'
import UserOnboardList from 'containers/onboard/UserOnboardList/UserOnboardList'
import PaymentList from 'containers/payment/PaymentList/PaymentList'
import UnlockFunds from 'containers/payment/UnlockFunds/UnlockFunds'
import StudioList from 'containers/studio/StudioList/StudioList'
import IssuesAdminPage from 'containers/issue/IssuesAdminPage'
import GodsonsPage from 'containers/user/GodsonsPage/GodsonsPage'
import UserPage from 'containers/user/UserPage/UserPage'
import AdminCalendar from 'containers/meeting/AdminCalendar'
import Statistics from 'containers/statistics/Statistics/Statistics'
import React from 'react'
import { Route, Routes } from 'react-router-dom'

import { AuthRoute } from 'components/GuardRoutes'
import LogGrid from 'components/LogGrid/LogGrid'
import CreateBadge from 'containers/badge/CreateBadge/CreateBadge'
import AdminMeetingPage from 'containers/meeting/AdminMeetingPage'
import Availabilities from 'containers/availabilities/Availabilities'
import Profile from 'containers/profile/Profile'
import SendEmail from 'containers/email/SendEmail'
import Invoices from 'containers/invoices/Invoices'
import AppointmentPage from 'containers/appointment/AppointmentPage'

const ConnectedRoutes = () => (
	<LogGrid>
		<Routes>
			<Route
				path='/profile'
				element={
					<AuthRoute>
						<Profile />
					</AuthRoute>
				}
			/>
			<Route
				path='/badge/create'
				element={
					<AuthRoute>
						<CreateBadge />
					</AuthRoute>
				}
			/>
			<Route
				path='/badges'
				element={
					<AuthRoute>
						<BadgeList />
					</AuthRoute>
				}
			/>
			<Route
				path='/freelances'
				element={
					<AuthRoute>
						<FreelanceList />
					</AuthRoute>
				}
			/>
			<Route
				path='/freelance/badges/:userId'
				element={
					<AuthRoute>
						<ManageFreelanceBadges />
					</AuthRoute>
				}
			/>
			<Route path='/meetings' element={<AdminCalendar />} />
			<Route path='/meeting/:id' element={<AdminMeetingPage />} />
			{/* <Route
				path='/freelance/levelup/:userId'
				element={
					<AuthRoute>
						<LevelUp />
					</AuthRoute>
				}
			/> */}
			<Route
				path='/studios'
				element={
					<AuthRoute>
						<StudioList />
					</AuthRoute>
				}
			/>
			<Route
				path='/payment/:contractId'
				element={
					<AuthRoute>
						<UnlockFunds />
					</AuthRoute>
				}
			/>
			<Route
				path='/payments'
				element={
					<AuthRoute>
						<PaymentList />
					</AuthRoute>
				}
			/>
			<Route
				path='/appointments/b2b'
				element={
					<AuthRoute>
						<PaymentList isBtoB />
					</AuthRoute>
				}
			/>
			<Route
				path='/appointments/b2c'
				element={
					<AuthRoute>
						<PaymentList isBtoB={false}  />
					</AuthRoute>
				}
			/>
			<Route
				path='/missions'
				element={
					<AuthRoute>
						<MissionList />
					</AuthRoute>
				}
			/>
			<Route
				path='/users/onboard'
				element={
					<AuthRoute>
						<UserOnboardList />
					</AuthRoute>
				}
			/>
			<Route
				path='/user/:userId'
				element={
					<AuthRoute>
						<UserPage />
					</AuthRoute>
				}
			/>
			<Route
				path='/godsons/:userId'
				element={
					<AuthRoute>
						<GodsonsPage />
					</AuthRoute>
				}
			/>
			<Route
				path='/issue/:id'
				element={
					<AuthRoute>
						<AdminIssuePage />
					</AuthRoute>
				}
			/>
			<Route
				path='/issues'
				element={
					<AuthRoute>
						<IssuesAdminPage />
					</AuthRoute>
				}
			/>
			<Route
				path='/appointment/b2c/:appointmentId'
				element={
					<AuthRoute>
						<AppointmentPage isBtob={false} />
					</AuthRoute>
				}
			/>
			<Route
				path='/appointment/b2b/:appointmentId'
				element={
					<AuthRoute>
						<AppointmentPage isBtob />
					</AuthRoute>
				}
			/>
			<Route
				path='/contract/:contractId'
				element={
					<AuthRoute>
						<ContractPage />
					</AuthRoute>
				}
			/>
			<Route
				path='/availabilities'
				element={
					<AuthRoute>
						<Availabilities />
					</AuthRoute>
				}
			/>
			<Route
				path='/statistics'
				element={
					<AuthRoute>
						<Statistics />
					</AuthRoute>
				}
			/>
			<Route
				path='/invoices'
				element={
					<AuthRoute>
						<Invoices />
					</AuthRoute>
				}
			/>
			<Route
				path='/sendEmail'
				element={
					<AuthRoute>
						<SendEmail />
					</AuthRoute>
				}
			/>
			<Route
				index
				path='*'
				element={
					<AuthRoute>
						<UserOnboardList />
					</AuthRoute>
				}
			/>
		</Routes>
	</LogGrid>
)

export default ConnectedRoutes

import BadgeComponent from 'components/BadgeComponent';
import React, { useMemo } from 'react';
import { capitalizeFirstLetter } from 'containers/helpers';
import StatusGesture from './StatusGesture';
import MyTable from 'components/MyTable/MyTable';
import { Button } from 'semantic-ui-react';
import { Link } from 'react-router-dom'
import DateTooltip from 'components/DateTooltip';

const AppointmentPaymentListComponent = ({
	appointments = []
}) => {
	const columns = useMemo(() => [
		{ title: 'Status', render: (props) => <StatusGesture {...props} /> },
		{ title: 'Référence du contrat', render: ({ reference }) => reference },
		{ title: 'Titre de la mission', render: ({ title }) => title },
		{ title: 'Nombre de jours', render: ({ days }) => Object.keys(days).length },
		{
			title: 'Freelance', render: ({ freelance }) => freelance
		},
		{ title: 'Studio', render: ({ studio }) => studio },

		{
			title: 'Jours', render: ({ days }) => <DateTooltip dates={days} />
		},
		{ title: 'Level', render: ({ freelance_level }) => freelance_level },
		{
			title: 'Voir les avis Review', render: ({ id }) => <Button as={Link}
				to={`/appointment/b2b/${id}`}>
				Accéder à la page du rdv
			</Button>
		},
		{
			title: 'Badges obtenus', render: ({ badges = [] }) =>
				<div className='flex'>
					{badges
						.map(({ name, intensity }, index) => <div key={index}>
							<BadgeComponent
								intensity={intensity}
								name={name}
							/>
						</div>)
					}
				</div>
		},
	], [])

	const datas = useMemo(() => appointments
		.map((appointment) => ({
			...appointment,
			...({ freelance: `${capitalizeFirstLetter(appointment.firstname)} ${capitalizeFirstLetter(appointment.lastname)} [${appointment.freelance}]` }),
			studio: `${appointment.studio_name} [${appointment.studio}]`
		})), [appointments])
	return (
		<MyTable
			columns={columns}
			data={datas}
			title={'Contrats'}
			sear
		/>
	);
};

export default AppointmentPaymentListComponent;
import LangLinkKeyword from 'constants/LangLinkKeyword'
import { UserTypes } from 'constants/UserTypes'

const FR = {
	goBack: "Retourner à cette étape",
	companyName: `Nom de votre société`,
	address: 'Adresse',
	radius: 'Maximum distance willing to travel',
	creationDate: 'Date de création de votre société',
	phone: 'Numéro de téléphone',
	diploma: 'Diplôme',
	noDiploma: 'Sans diplôme',
	diplomaA: 'BP Coiffure',
	diplomaB: 'CAP Coiffure',
	knowBy: 'Comment avez-vous connu Styleep ?',

	pageHeader: `Veuillez compléter les étapes suivantes pour s'integrer`,

	obStepTitles: {
		validateEmail: 'Validez votre email',
		completeProfile: 'Votre profil',
		fiscal: 'Vos données fiscales',
		completeAddress: 'Indiquez votre adresse',
		reviewSalon: 'Analyse de l\'entreprise',
		bankInfos: 'Informations bancaires',
		reviewFreelance: 'Review fiscal documents',
		scheduleMeeting: 'Prenez rendez-vous',
		meetSoon: 'Nous espérons vous rencontrer bientôt'
	},

	siret: 'Numéro SIRET',
	rcs_city: `Ville d’immatriculation aux Registre du commerce et des sociétés (RCS)`,
	hq_city: 'Ville de siège social',
	company_role: `Emploi dans l'entreprise`,
	tva_number: 'Numéro de TVA',
	kbisDoc: 'Votre document KBIS',
	legal_name: 'Dénomination Sociale',

	company_roles: {
		president: 'Président',
		ceo: 'PDG',
		director: 'Directeur'
	},
	company_type: "Type d'entreprise",
	company_types: [
		'EI',
		'EURL',
		'SARL',
		'SASU',
		'SAS',
		'SA',
		'SNC',
		'SCS',
		'SCA',
	],
	yourAreNotEISo: 'Vous n\'êtes pas une EI (entreprise individuelle). Vous avez donc un capital social. Renseignez-le svp.',
	fullHeadOffice: 'Votre siège social en entier incluant ville et code postal.',
	head_office: 'Votre siège social',
	capitalContentCentimes:'Si il contient des centimes, ajoutez ",".',
	reviewingDocsHelp: `Nous examinons vos informations. Vous devriez avoir de nos nouvelles dans les 24 heures.`,
	emailSent: 'Email envoyé',
	emailValidationHelp: `Si vous avez accepté l'e-mail, veuillez actualiser la page. Si vous n'avez pas reçu d'e-mail, veuillez ${LangLinkKeyword} pour en renvoyer un.`,
	link_emailValidationHelp: 'Cliquer ici',
	knowByChoicesInfo: 'Je peux renseigner un choix spécifique en le tappant dans la barre de selection.',
	knowByChoices: {
		[UserTypes.FREELANCE]: [
			'Par un ami',
			'Sur internet',
			'Sur un prospectus',
			'Dans mon école',
			'Sur Facebook',
			'Sur Instagram @Styleepgram',
			'Styleep m\'a contacté par téléphone',
			'Styleep m\'a contacté par Facebook',
			'Styleep m\'a contacté par Instagram',
			'Styleep m\'a contacté par Email',
			'Styleep m\'a contacté par Indeed',
		],
		[UserTypes.STUDIO]: [
			'Par un ami',
			'Dans mon entreprise',
			'Sur internet',
			'Sur un prospectus',
			'Sur Facebook',
			'Sur Instagram @Styleepgram',
			'Styleep m\'a contacté par téléphone',
			'Styleep m\'a contacté par Facebook',
			'Styleep m\'a contacté par Instagram',
			'Styleep m\'a contacté par Email',
		]
	},
	infoFiscal: 'Pour pouvoir accéder à notre plateforme, vous devez nous fournir votre document attestant la création de votre entreprise.',
	image: "Votre photo de profil",
	studioImageInfo: 'Vous pouvez par exemple mettre une photo de votre entreprise.',
	bankTitle: 'Indiquer vos informations bancaires afin que nous puissions vous verser votre rémunération à la fin de chaque mission.',
	bankSubtitle: 'Cette étape n\'est pas obligatoire. Vous pourrez ajouter ces informations dans votre profil une fois votre profil actif.',
	iban: "Votre IBAN",
	swift: "Votre code swift",
	capital: "Capital social de votre entreprise",
	franchise: "Êtes-vous une entreprise franchisé ? Si oui, de quelle marque ?",
	bankAccountOwner: "Nom du titulaire du compte",
}

const EN = {
	goBack: "Go back to this step",
	companyName: 'Name of your company',
	address: 'Address',
	radius: 'Maximum distance willing to travel',
	creationDate: 'Creation date of your company',
	phone: 'Phone number',
	diploma: 'Diploma',
	noDiploma: 'No diploma',
	diplomaA: 'BP Hairstyle',
	diplomaB: 'CAP Hairstyle',
	knowBy: 'How did you hear about Styleep ?',

	pageHeader: 'Please complete the following to onboard',
	emailSent: 'Email sent',

	obStepTitles: {
		validateEmail: 'Validate your email',
		completeProfile: 'Complete Profile',
		fiscal: 'Your fiscal data',
		completeAddress: 'Complete your address',
		bankInfos: 'Bank transfer informations',
		reviewSalon: 'Review salon',
		reviewFreelance: 'Review fiscal documents',
		scheduleMeeting: 'Schedule meeting',
		meetSoon: 'We look forward to meeting you soon'
	},

	siret: 'SIRET number',
	rcs_city: `Registration city of RCS`,
	hq_city: 'City of headquarters',
	company_role: 'Role at company',
	tva_number: 'VAT Number',
	kbisDoc: 'Your KBIS document',
	legal_name: 'Legal name of company',

	company_roles: {
		president: 'President',
		ceo: 'CEO',
		director: 'Director'
	},

	reviewingDocsHelp: `We're reviewing your information. You should hear from us within 24 hours`,
	emailValidationHelp: `If you've accepted the email, please refresh the page. If you did not recieve an email, please click ${LangLinkKeyword} to send one again.`,
	link_emailValidationHelp: 'here',
	knowByChoicesInfo: 'I can fill in a specific choice by typing it in the selection bar.',
	knowByChoices: {
		[UserTypes.FREELANCE]: [
			'By a friend',
			'On the web',
			'On brochure',
			'In my school',
			'On Facebook',
			'On Instagram @Styleepgram',
			'Styleep contacted me by téléphone',
			'Styleep contacted me by Facebook',
			'Styleep contacted me by Instagram',
			'Styleep contacted me by Email',
			'Styleep contacted me by Indeed',
		],
		[UserTypes.STUDIO]: [
			'By a friend',
			'On the web',
			'On brochure',
			'On Facebook',
			'On Instagram @Styleepgram',
			'In my studio',
			'Styleep contacted me by téléphone',
			'Styleep contacted me by Facebook',
			'Styleep contacted me by Instagram',
			'Styleep contacted me by Email',
		]
	},
	company_type: "Company type",
	company_types: [
		'EI',
		'EURL',
		'SARL',
		'SASU',
		'SAS',
		'SA',
		'SNC',
		'SCS',
		'SCA',
	],
	yourAreNotEISo: 'You are not an EI, so you have a capital social. Please reseign it.',
	fullHeadOffice: 'Your head office full of city and postal code.',
	head_office: 'Your head office',
	capitalContentCentimes:'If contains centimes, add ",".',
	infoFiscal: 'To access to our platform, you have to provide your company creation proof document.',
	image: "Your profile picture",
	studioImageInfo: 'You can, for example, put image of your studio.',
	bankTitle: 'Please provide your bank account informations to send your money at the end of each mission.',
	bankSubtitle: 'This step isn\'t required, you will be able to renseign them in your profile page when your account will be active.',
	iban: "Your IBAN",
	swift: "Your SWIFT code",
	bankAccountOwner: "Name of bank account owner",
	capital: "Capital social of your company",
	franchise: "Are you a franchise of a brand ? If which one ?",
}

export default {
	fr: FR,
	us: EN,
}

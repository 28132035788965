import { fetchApi } from "containers/helpers";

export const getInvoices = async(start, end, isStudio) => fetchApi(`
	/invoice/admin
	?start=${start}
	&end=${end}
	&isStudio=${isStudio}
`);

export const download = (filename, data) => {
	var element = document.createElement('a');
	element.setAttribute('href', 'data:text/plain;base64,' + data);
	element.setAttribute('download', filename);
  
	element.style.display = 'none';
	document.body.appendChild(element);
  
	element.click();
  
	document.body.removeChild(element);
}
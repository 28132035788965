import FormComponent from 'components/Form/Form';
import Typography from 'components/Typography';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { toast } from 'react-toastify';
import classes from 'styles/classes.module.scss'
import { getEmailSendTypes, getEmailTemplates, sendEmailAdmin } from './helpers';

const SendEmailToUsers = () => {
    const [templates, setTemplates] = useState([])
    const [values, setValues] = useState({})
    const [sendTypes, setSendTypes] = useState([])
    const [loading, setLoading] = useState(false)

    useEffect(() => {
        getEmailSendTypes()
            .then(setSendTypes)
        getEmailTemplates()
            .then(setTemplates)
    }, [])

    const inputs = useMemo(() => {
        const inputs = [
            {
                type: 'select',
                name: 'template',
                value: values.template || null,
                fluid: true,
                options: templates.map(({ title }, index) => ({
                    key: String(index), value: String(index), text: title
                })),
                required: true,
                silentRequired: true,
                label: 'Type d\'email',
            },
            {
                type: 'autocompleteAdd',
                name: 'to',
                placeholder: 'axel.2341@styleep.com,aaaa@gmail.com,ok@gmail.com',
                label: 'Envoyer à',
                value: values.to || '',
                required: true,
                silentRequired: true,
                fluid: true,
                options: sendTypes,
                info: 'Mettez les emails séparés par une virgule sans espace si email, sinon choisissez une option.'
            }]
        const template = (values.template && templates[values.template]) || {}
        if (template.data) {
            if (template.data === 'date') {
                inputs.push({
                    type: 'text',
                    name: 'data',
                    value: values.data || '',
                    placeholder: '21/12/2022',
                    label: 'La date voulue',
                    required: true,
                    silentRequired: true,
                    fluid: true,
                })
            }
            else if (template.data === 'dates') {
                inputs.push({
                    type: 'text',
                    name: 'data',
                    value: values.data || '',
                    placeholder: '21/12/2022,22/12/2022',
                    label: 'Les dates voulues',
                    required: true,
                    silentRequired: true,
                    fluid: true,
                })
            }
        }
        return inputs
    }, [templates, sendTypes, values])

    const sendEmail = useCallback(async ({ to, template, data: dataFromValues }) => {
        setLoading(true)
        try {
            const templateObj = (template && templates[template]) || {}
            let data = null
            if (templateObj.data) {
                if (templateObj.data === 'date') {
                    data = dataFromValues
                }
                else if (templateObj.data === 'dates') {
                    const indexOfLast = dataFromValues.lastIndexOf(',')
                    if (indexOfLast && indexOfLast > 0) {
                        data = (dataFromValues
                            .substring(0, indexOfLast)
                            .split(',')
                            .reduce((p, a, index) => p + (index !== 0 ? ', ' : '') + a)
                            + ' et ' + dataFromValues.substring(indexOfLast + 1)
                        )
                    } else {
                        data = dataFromValues
                    }
                }
            }
            await sendEmailAdmin(template, to, data)
            toast.success('Les emails sont en cours d\'envoi. Vous recevrez un mail lorsqu\'ils auront été envoyés.')
        } catch (error) {
            toast.error('Problème durant l\'envoi des mails :' + error.message)
        } finally {
            setLoading(false)
        }
    }, [templates])

    const onUpdate = datas => setValues(datas)
    return (
        <div className={classes.mt1}>
            <Typography variant='subTitle2'>Envoyer un mail aux utilisateurs</Typography>
            <FormComponent
                onUpdate={onUpdate}
                inputs={inputs}
                sendForm={sendEmail}
                centered={true}
                responsive={false}
                segment={true}
                messages={{ submit: 'Envoyer le mail' }}
                loading={loading}
            />
        </div>
    );
};

export default SendEmailToUsers;
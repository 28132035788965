import Button from "components/Button";
import toast from "components/toast";
import { useCallback, useEffect, useState } from "react";
import { manageSubscriptionAPI } from "../helpers";
import { onboardingStatuses } from "constants/OnboardingStatuses.constant";


const ManageSubscription = ({
	id,
	status
}) => {
	const [active, setActive] = useState(false)

	useEffect(() => {
		setActive(status === onboardingStatuses.ACTIVE)
	}, [status])

	const activeSubscription = useCallback(async () => {
		try {
			const status = await manageSubscriptionAPI(id, active ? onboardingStatuses.WAITING_SUBSCRIPTION : onboardingStatuses.ACTIVE);
			setActive(!active);
			toast.success(status === onboardingStatuses.ACTIVE ? 'Abonnement activé' : 'Abonnement désactivé');
		} catch (error) {
			toast.error(error.message);
		}
	}, [id, active])

	return (
		<Button onClick={activeSubscription}>
			{active ? 'Désactiver' : 'Activer'} l'abonnement
		</Button>
	)
}

export default ManageSubscription;
import { toIbanFormat } from 'containers/helpers';
import React from 'react';

const BankTransferFreelanceInformation = ({
    bankAccount:{
        iban,
        swift,
        owner
    } = {}
}) => {
    return (
        <div>
          <div>IBAN : {toIbanFormat(iban)}</div>
          <div>SWIFT : {swift}</div>
          <div>OWNER : {owner}</div>
        </div>
    );
};

export default BankTransferFreelanceInformation;
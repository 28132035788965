import { IconButton, Tooltip } from '@material-ui/core';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import React from 'react';

const PricesIconTooltip = ({
    prices = []
}) => {
    return (
        <Tooltip title={prices.map(nbr => nbr + ' €').join('\n')}>
            <IconButton>
                <AttachMoneyIcon />
            </IconButton>
        </Tooltip>
    );
};

export default PricesIconTooltip;
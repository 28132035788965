export const FR = {
	badgeCreate: "Créer un badge",
	badges: "Badge list",
	freelances: "Freelance list",
	studios: "Studio list",
	payments: "Payment list",
	appointmentsB2C: "RDV list (B2C)",
	appointmentsB2B: "RDV list (B2B)",
	missions: "Missions list",
	issues: "Issues",
	usersOnboard: "Utilisateurs en cours d'onboard",
	meetings: "Meetings",
	onboard: 'Démarrage',
	calendar: 'Calendrier',
	profile_studio: 'Mon Entreprise',
	profile_freelance: 'Mon profil',
	bookkeeping: 'Ma compta',
	settings: 'Réglages',
	notifications: 'Notifications',
	signOut: 'Déconnexion',
	availabilities: 'Chercher un freelance',
	sendEmail: 'Envoyer un email',
	statistics: 'Statistiques',
	invoices: 'Factures',
}

export const EN = {
	badgeCreate: "Créer un badge",
	badges: "Badge list",
	freelances: "Freelance list",
	studios: "Studio list",
	payments: "Payment list",
	appointments: "Appointments list",
	missions: "Missions list",
	issues: "Issues",
	usersOnboard: "Utilisateurs en cours d'onboard",
	onboard: 'Onboard',
	calendar: 'Calendar',
	profile_studio: 'My Salon',
	profile_freelance: 'My Profile',
	bookkeeping: 'My bookkeeping',
	settings: 'Settings',
	notifications: 'Notifications',
	signOut: 'Sign Out',
	availabilities: 'Search a hairdresser',
	sendEmail: 'Send an email',
	statistics: 'Statistics',
	invoices: 'Invoices',
}

export default {
	fr: FR,
	us: EN,
}

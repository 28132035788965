import { Label } from 'semantic-ui-react'
import typeLabels from 'containers/issue/constants/IssueTypeLabels'
import issuePriorityLabels from 'containers/issue/constants/IssuePriorityLabels'
import issueStatusLabels from 'containers/issue/constants/IssueStatusLabels'

const priorityColors = {
	0: 'green',
	1: 'yellow',
	2: 'orange',
	3: 'max',
}

const columns = [
	{ field: 'id', title: 'Id' },
	{
		field: 'type',
		title: 'Type',
		render: ({ type }) => <Label>{typeLabels[type] || typeLabels[5]}</Label>,
	},
	{ field: 'mission', title: 'Mission' },
	{ field: 'contract', title: 'Contract' },
	{ field: 'user', title: 'User' },
	{ field: 'title', title: 'Title' },
	{ field: 'description', title: 'Description' },
	{ field: 'meeting', title: 'Meeting' },
	{
		field: 'status',
		title: 'Status',
		render: ({ status }) => <Label>{issueStatusLabels[status]}</Label>,
	},
	{ field: 'freelance', title: 'Freelance' },
	{ field: 'studio', title: 'Studio' },
	{
		field: 'priority',
		title: 'Priority',
		render: ({ priority }) => (
			<Label color={priorityColors[priority]}>
				{priority ? issuePriorityLabels[priority] : 'None'}
			</Label>
		),
	},
	{ field: 'admin', title: 'Admin' },
	{ field: 'created_at', title: 'Created At' },
	{ field: 'updated_at', title: 'Updated At' },
]

export default columns

import React, { useCallback, useEffect, useState } from "react";
import { DatePicker, Button } from "antd";
import moment from "moment-timezone";
import "antd/dist/antd.css";
import './MultipleDatePicker.scss'
import useLocale from "hooks/useLocale";
import localeFr from 'antd/es/date-picker/locale/fr_FR'
import localeEn from 'antd/es/date-picker/locale/en_US'
import frLocaleMoment from 'moment/locale/fr'
import enLocaleMoment from 'moment/locale/en-gb'
localeFr.lang.locale = 'fr'
localeEn.lang.locale = 'en'
const MultipleDatePicker = ({
  selectedDates = [],
  setSelectedDates,
}) => {
  const [open, setOpen] = useState(false)
  const onValueChange = (date) => {
    const newDate = moment(date).startOf("day").unix()
    if (selectedDates.includes(newDate)) {
      setSelectedDates([...selectedDates.filter(item => item !== newDate)])
    } else {
      setSelectedDates([...selectedDates, newDate])
    }
  };

  const dateRender = useCallback( (currentDate) => {
	  const isSelected = selectedDates.includes(currentDate.startOf("day").unix())
      let selectStyle = isSelected ?
      {
        position: 'relative',
        zIndex: 2,
        display: 'inlineBlock',
        width: "24px",
        height: "22px",
        lineHeight: "22px",
        backgroundColor: "#1890ff",
        color: "#fff",
        margin: "auto",
        borderRadius: "2px",
        transition: "background 0.3s, border 0.3s"
      }
      : {}
    return (<div style={selectStyle} > {currentDate.date()}  </div >)
  }, [selectedDates])
  
  const locale = useLocale()

  useEffect(() => {
    if (locale === 'fr') {
      moment.locale('fr', frLocaleMoment)
    } else {
      moment.locale('en', enLocaleMoment)
    }
  }, [locale])
  return (
    <div>
      <DatePicker
        locale={locale === 'fr' ? localeFr : localeEn}
        open={open}
        dateRender={dateRender}
        onChange={onValueChange}
        showToday={false}
        value={""}
      />
      <Button type='primary' onClick={() => setOpen(!open)}>
        {(open ? 'Arrêter la saisie' : 'Saisir') + '  des dates'}
      </Button>
    </div>
  )
}
export default MultipleDatePicker
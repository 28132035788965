import RightSide from "components/RightSide";
import TitlePage from "components/TitlePage";
import { DatePicker, Switch } from "antd";
import { useCallback, useState } from "react";
import classes from "./Invoices.module.scss";
import { download, getInvoices } from "./helper";
import { Typography } from "@material-ui/core";
import { toast } from "react-toastify";
import Button from "components/Button";
const { RangePicker } = DatePicker;

const Invoices = () => {
	const [studio, setStudio] = useState(false);
	const [isLoading, setIsLoading] = useState(false);
	const [selectedDates, setSelectedDates] = useState(null);

	const handleSubmit = useCallback(async () => {
		setIsLoading(true)
		const start = selectedDates[0].format('YYYY-MM-DD');
		const end = selectedDates[1].format('YYYY-MM-DD');

		try {
			const zipBase64 = await getInvoices(start, end, studio);
			download(`invoice-${start}-${end}-${studio ? 'studio' : 'freelance'}.zip`, zipBase64);
		} catch (err) {
			toast.error(err.message);
		} finally {
			setIsLoading(false)
		}
	}, [selectedDates, studio]);

	return (
		<RightSide>
			<TitlePage>
				Factures
			</TitlePage>

			<div className={classes.container}>
				<RangePicker
					onChange={setSelectedDates}
				/>

				<div className={classes.switchContainter}>
					<Switch
						checked={studio}
						onChange={() => setStudio(!studio)}
					/>
					<Typography>
						{studio ? 'Studio' : 'Freelance'}
					</Typography>
				</div>

				<Button
					type='primary'
					disabled={!selectedDates}
					loading={isLoading}
					onClick={handleSubmit}
				>
					Télécharger les factures
				</Button>
			</div>
		</RightSide>
	);
};

export default Invoices;
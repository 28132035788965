import { getUser } from 'containers/helpers'
import obj2url from 'util/url/obj2QueryStr'

export const filterKeys = {
	ALL : 'ALL',
	ASSIGNED_TO_ME: 'ASSIGNED_TO_ME',
	UNASSIGNED: 'UNASSIGNED',
}

export const filters = [
	{
		key: filterKeys.ALL,
		label: 'All'
	},
	{
		key: filterKeys.ASSIGNED_TO_ME,
		label: 'Assigned to me',
	},
	{
		key: filterKeys.UNASSIGNED,
		label: 'Unassigned',
	},
]

export const initial = { src: `/issues` }

export function reducer(state, action) {
	switch (action.type) {
		case filterKeys.ALL:
			return { src: `/issues` }
		case filterKeys.ASSIGNED_TO_ME:
			const { id } = getUser()
			return { src: `/issues/${obj2url({ admin: id })}` }
		case filterKeys.UNASSIGNED:
			return { src: `/issues/${obj2url({ admin: null })}` }
		default:
			throw new Error()
	}
}

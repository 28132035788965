export const EN = {
	range: 'Range',
	year: 'Year',
	month: 'Month',

	statistics: 'Statistics',

	requestDate: 'Request date',
	numberDaysSinceRequest: 'Number of days since request',

	name: 'Name',
	actions: 'Actions',
	pastDates: 'Past dates',
	futureDates: 'Future dates',

	id: 'Id',
	title: 'Title',
	description: 'Description',

	count: 'Count',
	status: 'Status',
	freelancePrice: 'Freelance price',
	studioPrice: 'Studio price',

	titleFreelancesProposal: 'Freelances proposal',
	titleFreelances: 'Freelances',
	titleMissionWithoutFreelance: 'Missions without freelance',
	titleContract: 'Contracts',

	freelancerRequest: 'Freelancers requests',
	studioWithoutMission: 'Studio without mission',
	freelanceWithoutAvaibility: 'Freelance without availability',
	missionWithoutFreelance: 'Missions without freelance',
	unpaidContracts: 'Unbooked contracts',
	benefit: 'Benefit',
	turnover: 'Turnover',
	noStatistics: 'There is no statistics for this month',
	numberOfUnbookedMissionDays: 'Number of mission days unbooked',
	numberOfBookedMissionDays: 'Number of mission days booked',
}

export const FR = {
	range: 'Période',
	year: 'Année',
	month: 'Mois',

	statistics: 'Statistiques',

	requestDate: 'Date de la demande',
	numberDaysSinceRequest: 'Nombre de jours depuis la demande',

	name: 'Nom',
	actions: 'Actions',
	pastDates: 'Dates passées',
	futureDates: 'Dates futures',

	id: 'Id',
	title: 'Titre',
	description: 'Description',

	count: 'Nombre',
	status: 'Statut',
	freelancePrice: 'Prix freelance',
	studioPrice: 'Prix studio',

	titleFreelancesProposal: 'Freelances avec demande en attente',
	titleFreelances: 'Freelances',
	titleMissionWithoutFreelance: 'Missions sans freelance',
	titleContract: 'Contrats',

	freelancerRequest: 'Demande des freelances',
	studioWithoutMission: 'Studio sans mission',
	freelanceWithoutAvaibility: 'Freelances sans disponibilité',
	missionWithoutFreelance: 'Missions sans freelance',
	unpaidContracts: 'Contrats non réservés',
	benefit: 'Bénéfice',
	turnover: 'Chiffre d\'affaire',
	numberOfUnbookedMissionDays: 'Nombre de jours de mission non validés',
	numberOfBookedMissionDays: 'Nombre de jours de mission validés (payés)',
	noStatistics: 'Il n\'y a pas de statistiques pour ce mois',
}

export default {
	fr: FR,
	us: EN,
}
import { getFreelanceGodsons } from 'containers/user/GodsonsPage/helpers';
import React, { useEffect, useState } from 'react';

export const useGodsons = (userId) => {
    const [godsons, setGodsons] = useState([])

    useEffect(() => {
        getFreelanceGodsons(userId)
            .then(setGodsons)
    }, [userId])

    return {
        godsons,
        setGodsons
    }
};

import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { FreelanceOnboardingStatuses, StudioOnboardingStatuses, onboardingStatuses as allOnboardingStatuses } from 'constants/OnboardingStatuses.constant'
import { Autocomplete } from '@mui/material';
import { UserTypes } from 'constants/UserTypes';
import { TextField } from '@material-ui/core';
import { updateUserStatusAPI } from '../helpers';
import { toast } from 'react-toastify';
const UpdateUserStatusSelect = ({
    status,
    type,
    id
}) => {
    const onboardingStatuses = useMemo(() => type === UserTypes.FREELANCE
        ? FreelanceOnboardingStatuses
        : StudioOnboardingStatuses, [type])
    const onboardingStatusesKeys = useMemo(() => Object.keys(onboardingStatuses), [onboardingStatuses])
    const [value, setValue] = useState(Object.keys(allOnboardingStatuses)[status] || '')

    useEffect(() => {
        setValue(Object.keys(allOnboardingStatuses)[status])
    }, [status, onboardingStatuses])

    const updateStatus = useCallback(async (evt, newStatusKey) => {
        try {
            updateUserStatusAPI(id, onboardingStatuses[newStatusKey])
            setValue(newStatusKey)
            toast('Actualisé')
        } catch (error) {
            toast('Erreur : ' + error.message)
        }

    }, [id, onboardingStatuses])

    return (
        <div>
            Modifier le statut de l'utilisateur - Actuel = { }
            <Autocomplete
                value={value}
                freeSolo
                onChange={updateStatus}
                disablePortal
                id="combo-box-demo"
                options={onboardingStatusesKeys}
                sx={{ width: 300 }}
                renderInput={(params) => <TextField {...params} label="Status" />}
            />
        </div>
    );
};

export default UpdateUserStatusSelect;
import { IconButton } from '@material-ui/core';
import { Tooltip } from 'antd';
import BadgeComponent from 'components/BadgeComponent';
import RightSide from 'components/RightSide';
import { getImageUrl } from 'containers/helpers';
import { omit } from 'lodash';
import React, { useContext, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Store } from 'store/store';
import { createUserBadge, deleteUserBadge, getFreelanceBadges } from '../helpers';
import NewFreelanceBadge from './NewFreelanceBadge';

const ManageFreelanceBadges = () => {
  const userId = Number(useParams().userId)
  const [freelanceBadges, setFreelanceBadges] = useState({})
  useEffect(() => {
    getFreelanceBadges(userId)
      .then(setFreelanceBadges)

  }, [userId])

  const decrement = async (name, intensity) => {
    try {
      await deleteUserBadge(userId, name, intensity)
      setFreelanceBadges(freelanceBadges[name] === 0 ? omit(freelanceBadges, name) : {
        ...freelanceBadges,
        [name]: freelanceBadges[name] - 1
      })
      toast('Downgraded')
    } catch (error) {
      toast('Error', error.message)
      console.log('error', error)
    }
  }
  const increment = async (name, intensity) => {
    try {
      const newIntensity = intensity + 1
      await createUserBadge(userId, name, newIntensity)
      setFreelanceBadges({
        ...freelanceBadges,
        [name]: newIntensity
      })
      toast('Downgraded')
    } catch (error) {
      toast('Error', error.message)
      console.log('error', error)
    }
  }
  return (
    <RightSide>
      <div className='flex'>
        {Object.entries(freelanceBadges)
          .map(([name, intensity], index) => <div key={index}>
            <BadgeComponent
              intensity={intensity}
              name={name}
              decrement={() => decrement(name, intensity)}
              increment={() => increment(name, intensity)}
            />
          </div>)
        }
      </div>
      <NewFreelanceBadge
        userId={userId}
        setUserBadges={setFreelanceBadges}
        userBadges={freelanceBadges}
      />
    </RightSide>
  );
};

export default ManageFreelanceBadges;
import React, { useState, useContext, useEffect, useMemo } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Modal, Button } from 'semantic-ui-react';

import UnLogGrid from '../../../components/UnLogGrid/UnLogGrid';
import { Store } from '../../../store/store';
import { resetPassword } from './helpers';

const ForgotPassword = () => {
    const params = useParams()
    const { state: { language : { login: copy } }} = useContext(Store);
    const navigate = useNavigate();
    const [email, setEmail] = useState(params.email && params.email !== 'null' ? params.email : '');
    const inputs = useMemo(() => [
        {
            type: 'text',
            name: 'email',
            placeholder: copy.mail,
            required: true,
            silentRequired: true,
            fluid: true,
            value: email,
        },
    ], [copy, email]);
    const [loader, setLoader] = useState(false);
    const [modal, setModal] = useState(false);
    const goToReset = () => navigate('/');
    const sendMail = async (values) => {
        setLoader(true);
        try {
            await resetPassword(values);
            setEmail(values.email);
            setModal(true);
            setLoader(false);
        } catch (e) {
            setLoader(false);
            alert(e.message);
        }
    };
    useEffect(() => {
        if (modal === true) {
            const timer = setTimeout(goToReset, 5000);
            return () => clearTimeout(timer);
        }
    }, [modal, email]);

    return (
        <>
            <UnLogGrid
                inputs={inputs}
                sendForm={sendMail}
                page="forgot"
                message={copy.forgotPassword}
                submitMessage={copy.validForgot}
                loading={loader}
            />

            <Modal open={modal} onClose={goToReset} dimmer="inverted" closeIcon className="modal-login-container">
                <Modal.Header>{copy.titleModal}</Modal.Header>
                <Modal.Content>
                    <Modal.Description>{copy.contentModal}</Modal.Description>
                </Modal.Content>

                <Button className="modal-login-button" onClick={goToReset}>
                    {copy.goTo}
                </Button>
            </Modal>
        </>
    );
};

export default ForgotPassword;

import React from 'react';
import classes from './UnAuthLayout.module.scss'
import Typography from 'components/Typography'

const AuthFormHeader = ({ page, copy }) => {
    const title = page === 'login' ? copy.logIn : copy.signUp

    return (
        <div className={classes.authFormHeader}>
            <div>
                <Typography variant='subTitle1' classes={{ root: classes.confirmAccountText }}>
                    {title}
                </Typography>
            </div>
        </div>
    )
}

export default AuthFormHeader;
import { getBadges } from 'containers/badge/helpers';
import { isLoggedIn, updateSocketId } from 'containers/helpers';
import { getUserLevelsReference } from 'containers/level/helpers';
import React, { useContext, useEffect } from 'react';
import { socket, reloadSocket } from '../../socket'
import { Store } from '../../store/store';
import useNotification from 'hooks/useNotification';
import { useSockets } from 'hooks/useSockets';
import { useStoreUtils } from 'hooks/useStoreUtils';
import LogGridComponent from './LogGridComponent';


const LogGrid = ({ children }) => {
    const {
		reloadUser
	} = useStoreUtils()
    // si pas de header au final justea enlever les grid row
    const {
        receiveNotification,
        initNotification
    } = useNotification()
    const {
        dispatch,
    } = useContext(Store);
    const {socketIsConnected} = useSockets()

    const initBadges = async () => {
        const badges = await getBadges()
        dispatch({
            type: 'INIT_BADGES',
            payload: badges
        })
    }
    const initUserLevels = async () => {
        const userLevels = await getUserLevelsReference()
        dispatch({
            type: 'INIT_USER_LEVELS',
            payload: userLevels
        })
    }

    const initSockets = () => {
        socket.on('connect', () => {
            updateSocketId(socket.id)
            socketIsConnected()
            console.log('SOCKET CONNECT')
            console.log('socket.id :>> ', socket.id)
        })

        socket.on('delete notification', (notificationId) => {
            dispatch({ type: 'REMOVE_NOTIFICATION', payload: notificationId })
        })
        socket.on('notification', notification => {
            receiveNotification(notification)
        })
        socket.on('new mail', (mail) => {
            dispatch({ type: 'ADD_MAIL', payload: mail.mail })
        })
        socket.on('subscriptionPayment', data => {
            dispatch({ type: 'STRIPE/PAYMENT', payload: { ...data } })
        })
        socket.on('singlePayment', data => {
            dispatch({ type: 'STRIPE/PAYMENT', payload: { ...data } })
        })
    }
    useEffect(() => {
        if (isLoggedIn()) {
			reloadUser()
			initNotification()
            initBadges()
            initUserLevels()
            reloadSocket()
            initSockets()
        }
    }, [])

    return (
        <LogGridComponent>
        {/* {loading ? <Loader /> : children} */}
        {children}
    </LogGridComponent>
    //     <Grid className={`full-height text-color log-grid-container ${menuClassName}`}>
    //         <Header />
    //         <div className="menu-hide-icon-container">
    //             <Checkbox onClick={handleMenuState} toggle checked={menu} />
    //             {/* <Icon color="yellow" name={'hand pointer'} size="big" /> */}
    //         </div>
    //         {/* <GridRow className="padding-0" style={{ height: '7vh' }}>
    //   </GridRow> */}
    //         <GridRow className="padding-0" style={{ height: '100vh' }}>
    //             <Grid.Column className="bg-violet padding-0 log-grid-menu-container">
    //                 <Menu />

    //             </Grid.Column>
    //             <Grid.Column className="padding-0 log-grid-right-side-container">
    //                 <RightSide>{children}</RightSide>
    //             </Grid.Column>
    //         </GridRow>
    //     </Grid>
    );
};

export default LogGrid;

import ContractInfos from 'components/ContractInfos';
import RightSide from 'components/RightSide';
import { getContract } from 'containers/payment/UnlockFunds/helpers';
import { useSockets } from 'hooks/useSockets';
import React, { useCallback, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { removeEvent, socket } from 'socket';
import { getContractConfirmations } from '../helpers';

const ContractPage = () => {
    const contractId = Number(useParams().contractId)
    const { socketConnected } = useSockets()
    const [contract, setContract] = useState({})

    const loadContract = useCallback(async () => {
        try {
            const newContract = await getContract(contractId)
            newContract.confirmations = await getContractConfirmations(contractId)
            setContract(newContract)
        } catch (error) {
            console.log('error', error)
        }

    }, [contractId])

    useEffect(() => {
        if (contractId) {
            loadContract(contractId)
        }
    }, [contractId])

    const navigate = useNavigate()

    useEffect(() => {
        if (socketConnected && socket) {
            socket.emit('join_contract', contractId);
            socket.on(`contract updated`, () => {
                loadContract()
            });
            socket.on(`contract removed`, () => {
                toast('Le contrat a été supprimé.')
                navigate('/missions')
            });
        }
        return () => {
            if (socketConnected && socket) {
                socket.emit('leave_contract', contractId);
                removeEvent(`contract updated`);
                removeEvent(`contract removed`);
            }
        };
    }, [contractId, socketConnected])

    return (
        <RightSide>
                <ContractInfos {...contract} />
        </RightSide>
    );
};

export default ContractPage;
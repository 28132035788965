const NotificationTypes = {
	USER: 0,

	MISSION_CREATED: 1,
	MISSION_PROPOSAL: 2,
	DECLINE_PROPOSAL: 3,

	FREELANCE_CONFIRMED: 4,
	STUDIO_CONFIRMED: 5,

	WAITING_ALL_CONTRACTS: 6,
	WAITING_FREELANCE_CONTRACT: 7,
	WAITING_STUDIO_CONTRACT: 8,
	CONTRACT_DELETED: 9,

	ISSUE: 10,
	ISSUE_MISSION: 11,


	WAITING_FUNDS: 20,
	BANK_TRANSFER_RECEIVED: 21,
	BANK_TRANSFER_SENT: 22,

	BOOKED: 23,

	WAITING_STUDIO_DAYS_CONFIRMATION: 24,
	WAITING_FREELANCE_DAYS_CONFIRMATION: 25,
	WAITING_ADMIN_REVIEW_DAYS_CONFIRMATION: 26,


	CONTRACT_UPDATED: 27,

	COMPLETED_REVIEW: 28,

	WAITING_REVIEW: 29,
	WAITING_FREELANCE_REVIEW: 30,
	WAITING_STUDIO_REVIEW: 31,
	REVIEWED: 32,

	FUNDS_UNLOCKED: 33,

	ALLOW_SCHEDULE_MEETING: 34,
	CANCELLED: 35,


	SEND_USER_MEETING_INFORMATION: 36,
	CANCEL_USER_MEETING: 37,

	NEW_REVIEW_STUDIO_CONTRACT_CONFIRMATIONS: 38,
	ASK_TO_ENTER_BANK_ACCOUNT: 39,
	CONTRACT_FINISHED: 40,
	
	ACCOUNT_ACTIVATED: 41,
	ACCOUNT_VALIDATION_IN_PROGRESS: 42,
	CHOOSE_ONBOARD_MEETING: 43,
	CREATE_NEW_MISSION: 44,
	CHOOSE_ISSUE_MEETING: 45,
	YOU_HAVE_MISSION_TOMORROW: 46,
	DECLARE_CA: 47,
	NEW_PROFILES_AVAILABLES_FOR_MISSION: 48,
	NEW_MISSIONS_AVAILABLES: 49,
	MISSION_UPDATED: 50,
	PAYMENT_DECLINED: 51,
	PAYMENT_ACCEPTED: 52,
	ERROR_DURING_PAYMENT: 53,
}

module.exports = NotificationTypes
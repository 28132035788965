import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Button } from 'semantic-ui-react';
import SearchAddressFields from 'components/SearchAddressFields/SearchAddressFields';
import { UserTypes } from 'constants/UserTypes'
import RadiusInput from 'components/RadiusInput';
import { toast } from 'react-toastify';
import { FormControlLabel, Switch } from '@material-ui/core';
import DisplayAddressComponent from './DisplayAddressComponent';
import { updateFreelance, updateStudio } from '../helpers';
import Loader from 'components/Loader';
import { isEmpty, pick } from 'lodash';
import { getOnlyUpdatedValues, objectToFormData } from 'containers/helpers';

const AddAddress = ({
    user = {}
}) => {
    const [address, setAddress] = useState(user && user.address ? pick(user, ['address', 'lat', 'lon']) : null)
    useEffect(() => {
        if (user) {
            setAddress(user && user.address ? pick(user, ['address', 'lat', 'lon']) : null)
        }
    }, [user])
    const [radius, setRadius] = useState(user.radius || null)
    const [loading, setLoading] = useState(false)
    const updateAddress = (newAddress) => setAddress(newAddress)
    const updateRadius = (newRadius) => setRadius(newRadius)
    const active = useMemo(() => !!address && !!(user.type === UserTypes.FREELANCE ? radius : true), [user.type, address, radius])
    const [updateMode, setUpdateMode] = useState(false)

    const getAPIFunc = useMemo(() => user.type === UserTypes.FREELANCE ? updateFreelance : updateStudio, [user])

    const updateAddressClick = useCallback(async () => {
        setLoading(true)
        try {
            const values = {
                ...pick(address, ['lat', 'lon']),
                address: address.display_name,
                radius
            }
                const updatedValues = getOnlyUpdatedValues(user, values)
            if (isEmpty(updatedValues)) {
                toast('Rien n\'a été actualisé dans le formulaire.')
                return
            }
            const newUser = await getAPIFunc(objectToFormData(updatedValues), user.type_id)
            toast('Adresse Modifiée')
            setUpdateMode(false)
        } catch (e) {
            console.log(e)
        } finally {
            setLoading(false)
        }
    }, [user, address, radius, getAPIFunc])


    return (
        loading ? <Loader /> :
            <div>
                <FormControlLabel
                    control={<Switch checked={updateMode} onChange={() => setUpdateMode(updateMode => !updateMode)} />}
                    label="Mode édition"
                />
                {updateMode ? <div>
					<SearchAddressFields initialAddress={user.address} onSelect={updateAddress} />
                    {user.type === UserTypes.FREELANCE && <RadiusInput initialRadius={user.radius} onSelect={updateRadius} />}
                    <Button loading={loading} disabled={!active || loading} onClick={updateAddressClick} secondary>
                        Sauvegarder mon adresse
                    </Button>
                </div> : <DisplayAddressComponent user={user} />}
            </div>
    );
};

export default AddAddress;
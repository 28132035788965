import cn from 'classnames'
import { useMemo } from 'react'
import { Link } from 'react-router-dom'
import classes from './Typography.module.scss'

// See https://www.figma.com/file/3zIW8GUO2eqtilzPXf7E8p/SIMULATEUR-FLAMMEBLEUE?node-id=8%3A2.

const Typography = ({
	// Variant can be either 'body', 'title', 'subTitle1', 'subTitle2' or 'button'. Default is 'body'.
	variant = 'body',
	component: Component = 'span',
	classes: receivedClasses = {},
	children: childrenFromParams,
	link = false,
	linkStyle = false,
	primaryColor = false,
	html: htmlFromParams = null,
	italic = false,
	fontWeight = false,
	...other
}) => {
	const stylizeStyleepWord = (str) =>
		str.replace(/(S|s)tyleep/g, '<styleep>Styleep</styleep>')

	const html = useMemo(
		() =>
			htmlFromParams &&
			stylizeStyleepWord(htmlFromParams),
		[htmlFromParams]
	)

	const children = useMemo(() => childrenFromParams === 'string' ? stylizeStyleepWord(childrenFromParams) : childrenFromParams, [childrenFromParams])

	const typo = useMemo(() => {
		const props = {
			className: cn(
				classes.root,
				classes[variant],
				receivedClasses.root,
				linkStyle && classes.linkStyle,
				primaryColor && classes.primaryColor,
				italic && classes.italic
			),
			...(html ? { dangerouslySetInnerHTML: { __html: html } } : {}),
			...other,
			...(fontWeight ? {style: {...(other.style || {}), fontWeight}} : {}),
		}
		return html ? (
			<Component {...props} />
		) : variant === 'title' ? (
			<h1 {...props}>{children}</h1>
		) : variant === 'subTitle1' ? (
			<h2 {...props}>{children}</h2>
		) : variant === 'subTitle2' ? (
			<h3 {...props}>{children}</h3>
		) : variant === 'subTitle3' ? (
			<h4 {...props}>{children}</h4>
		) : (
			<span {...props}>{children}</span>
		)
	}, [
		children,
		html,
		linkStyle,
		other,
		primaryColor,
		receivedClasses,
		variant,
	])

	const LinkableTypo = () => <Link to={link}>{typo}</Link>

	return link ? <LinkableTypo /> : typo
}

export default Typography
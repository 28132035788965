import PdfViewer from 'components/PdfViewer';
import { dayStatuses } from 'constants/DayStatuses';
import { getFileByNameVisualisationUrl, getToken } from 'containers/helpers';
import { getBankAccount } from 'containers/user/helpers';
import React, { useEffect, useMemo, useState } from 'react';
import { Button } from 'semantic-ui-react';
import BankTransferFreelanceInformation from './BankTransferFreelanceInformation';
import CreateBankAccountButton from './CreateBankAccountButton';
import { getFreelance, unlockFundsAPI } from './helpers';

const UnlockFundsComponent = ({
    contract
}) => {
    const [invoice, setInvoice] = useState()
    const [freelance, setFreelance] = useState({})
    const [bankAccount, setBankAccount] = useState()
    const freelanceUserId = useMemo(() => !!contract && contract.freelance, [contract])

    useEffect(() => {
        if (!!freelanceUserId) {
            getFreelance(freelanceUserId)
                .then(setFreelance)
            getBankAccount(freelanceUserId)
                .then(setBankAccount)
				.catch(err => console.log(err))
        }
    }, [freelanceUserId])

    const [bankTransferDone, setBankTransferDone] = useState(contract.status > dayStatuses.WAITING_UNLOCK_FUNDS)

    useEffect(() => {
        if (contract && contract.status) {
            setBankTransferDone(contract.status > dayStatuses.WAITING_UNLOCK_FUNDS)
        }
    }, [contract])

    const onClick = async () => {
        try {
            const { payment: { name } } = await unlockFundsAPI(contract.id)
            setInvoice(getFileByNameVisualisationUrl(getToken(), name))
            setBankTransferDone(true)
        } catch (error) {
            console.log('error', error)
        }
    }

    return (
        <div>
            {!!bankAccount && <BankTransferFreelanceInformation bankAccount={bankAccount} />}
            {bankTransferDone ? <div>
                Le virement a été effectué
            </div> :
                !bankAccount ? <CreateBankAccountButton userId={freelanceUserId} />
                    : <Button onClick={onClick}>J'ai effectué le virement</Button>}
            {invoice && <PdfViewer file={invoice} />}
        </div>
    );
};

export default UnlockFundsComponent;
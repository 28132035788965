import React, { useCallback, useState } from 'react';
import { toast } from 'react-toastify';
import { Button } from 'semantic-ui-react';
import { sendBankAccountRequiredNotification } from './helpers';

const CreateBankAccountButton = ({
    userId
}) => {
    const [loading, setLoading] = useState(false)
    const sendRequest = useCallback(async () => {
        setLoading(true)
        try {
            await sendBankAccountRequiredNotification(userId)
            toast('Notification envoyée')
        } catch (error) {
            toast('Erreur: ' + error.message)
            console.log('error', error)
        } finally {
            setLoading(false)
        }
    }, [userId])
    return (
        <Button onClick={sendRequest} loading={loading}>
            Envoyer une demande de création de coordonnées bancaires
        </Button>
    );
};

export default CreateBankAccountButton;
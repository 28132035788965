import NewPageLinkButton from 'components/NewPageLinkButton';
import PdfViewer from 'components/PdfViewer';
import FileTypes from 'constants/FileTypes';
import { getFileByNameVisualisationUrl, getToken } from 'containers/helpers';
import React, { useMemo } from 'react';
import UserFiscalForm from './UserFiscalForm';
import UserAddressInfosForm from './UserAddressInfosForm'
import UpdateUserStatusSelect from './UpdateUserStatusSelect';
import BankAccountForm from './BankAccountForm';
import { UserTypes } from 'constants/UserTypes';
import ManageSubscription from './ManageSubscription';

const FiscalFileComponent = ({
    user
}) => {
    const fiscalFileUrl = useMemo(() => {
        if (!user || !user.files || user.files.length === 0) {
            return false
        }
        const fiscalFile = user.files.find(({ type }) => FileTypes.FISCAL === type)
        if (!fiscalFile) {
            return false
        }
        return getFileByNameVisualisationUrl(getToken(), fiscalFile.name)
    }, [user])

    return (
        <div className='flex'>
            <div>
                {user && <UpdateUserStatusSelect {...user} />}
				{(user && user.type === UserTypes.STUDIO) && <ManageSubscription {...user} />}
                {user && <UserFiscalForm {...user} />}
                {user && <UserAddressInfosForm user={user} />}
                {user && <BankAccountForm user={user} />}
            </div>
            {fiscalFileUrl && <div className='flex column'>
                <NewPageLinkButton href={fiscalFileUrl}>
                    Voir le document en pleine page
                </NewPageLinkButton>
                <div>
                    <div>Fichier Kbis</div><div> <PdfViewer file={fiscalFileUrl} />
                    </div>
                </div>
            </div>}
        </div>
    );
};

export default FiscalFileComponent;
import React, { useCallback, useMemo } from 'react';
import { ReactComponent as IconOnboard } from 'icons/onboard.svg'
import { ReactComponent as IconMissions } from 'icons/missions.svg'
import QuestionMarkIcon from '@mui/icons-material/QuestionMark';
import LocalPoliceIcon from '@mui/icons-material/LocalPolice';
import StorefrontIcon from '@mui/icons-material/Storefront';
import Search from '@mui/icons-material/Search';
import EuroIcon from '@mui/icons-material/Euro';
import ArticleIcon from '@mui/icons-material/Article';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import PeopleIcon from '@mui/icons-material/People';
import MailIcon from '@mui/icons-material/Mail';
import BarChartIcon from '@mui/icons-material/BarChart';
import ReceiptIcon from '@mui/icons-material/Receipt';
import classNames from 'classnames';
import { Link, useLocation } from 'react-router-dom';
import useLanguage from 'hooks/useLanguage';
import Typography from 'components/Typography'
import classes from './SideBar.module.scss'

const pageKeys = {
    badges: 'badges',
    freelances: 'freelances',
    studios: 'studios',
    payments: 'payments',
    appointmentsB2B: 'appointments/b2b',
    appointmentsB2C: 'appointments/b2c',
    missions: 'missions',
    issues: 'issues',
    usersOnboard: 'users/onboard',
    meetings: 'meetings',
    availabilities: 'availabilities',
    sendEmail: 'sendEmail',
    statistics: 'statistics',
	invoices: 'invoices',
}


const SideBarLinksComponent = ({ setOpositeToActualOpen = () => { } }) => {
    const location = useLocation()
    const pathname = useMemo(() => location ? location.pathname : '', [location])
    const lang = useLanguage('sideBar')

    const links = useMemo(() => [
        { key: pageKeys.payments, disabled: false, label: lang.payments, Icon: () => <EuroIcon style={{ color: 'white' }} /> },
        { key: pageKeys.appointmentsB2B, disabled: false, label: lang.appointmentsB2B, Icon: () => <ArticleIcon style={{ color: 'white' }} /> },
        { key: pageKeys.appointmentsB2C, disabled: false, label: lang.appointmentsB2C, Icon: () => <ArticleIcon style={{ color: 'white' }} /> },
        { key: pageKeys.missions, disabled: false, label: lang.missions, Icon: () => <IconMissions /> },
        { key: pageKeys.issues, disabled: false, label: lang.issues, Icon: () => <QuestionMarkIcon style={{ color: 'white' }} /> },
        { key: pageKeys.usersOnboard, disabled: false, label: lang.usersOnboard, Icon: () => <IconOnboard /> },
        { key: pageKeys.meetings, disabled: false, label: lang.meetings, Icon: () => <CalendarMonthIcon style={{ color: 'white' }} /> },
        { key: pageKeys.availabilities, disabled: false, label: lang.availabilities, Icon: () => <Search style={{ color: 'white' }} /> },
        { key: pageKeys.badges, disabled: false, label: lang.badges, Icon: () => <LocalPoliceIcon style={{ color: 'white' }} /> },
        { key: pageKeys.freelances, disabled: false, label: lang.freelances, Icon: () => <PeopleIcon style={{ color: 'white' }} /> },
        { key: pageKeys.studios, disabled: false, label: lang.studios, Icon: () => <StorefrontIcon style={{ color: 'white' }} /> },
        { key: pageKeys.sendEmail, disabled: false, label: lang.sendEmail, Icon: () => <MailIcon style={{ color: 'white' }} /> },
        { key: pageKeys.statistics, disabled: false, label: lang.statistics, Icon: () => <BarChartIcon style={{ color: 'white' }} /> },
        { key: pageKeys.invoices, disabled: false, label: lang.invoices, Icon: () => <ReceiptIcon style={{ color: 'white' }} /> },
    ].filter(e => !!e), [lang])

    const isActive = useCallback((key) => {
        const homePageKey = pageKeys.profile
        if (key === homePageKey && (pathname === '' || pathname === '/')) {
            return true
        }
        //issueCase
        if (pathname.includes('issue') && key !== pageKeys.issues) {
            return false
        }

        if (pathname.includes('user/update') && key === pageKeys.settings) {
            return true
        }

        return pathname.includes(key)
            || (key.endsWith('s')
                && pathname.includes(key.substring(0, key.length - 1)))
    }, [pathname])

    return (
        <div className={classes.linkContainer}>
            {links.map(({ label, key, Icon, disabled }, i) => (
                <div key={i}>
                    <div
                        className={
                            classNames(
                                classes.link,
                                isActive(key) && classes.active,
                                disabled && classes.disabled
                            )
                        }
                    >
                        <Icon />
                        {disabled
                            ? <span className={classes.label}>{label}</span>
                            : <Link onClick={setOpositeToActualOpen} to={`/${key}`} className={classes.label}>
                                <Typography variant='text'> {label}</Typography>
                            </Link>}
                    </div>
                </div>
            ))}
        </div>
    );
};

export default SideBarLinksComponent;
export const FR = {
	forThe: {
		studio: 'Pour les entreprises',
		freelance: 'Pour les freelances',
	},
	freelanceValueProps: [
		'Testez vos produits',
		'Définissez votre audience',
		'Détectez de nouvelles opportunités',
		'Prenez des décisions qui impacteront votre marque',
	],
	salonValueProps: [
		'Testez vos produits',
		'Définissez votre audience',
		'Détectez de nouvelles opportunités',
		'Prenez des décisions qui impacteront votre marque',
	],
	logIn: 'Se connecter',
	signUp: "S'inscrire",
	firstname: 'Prénom',
	godfather: 'Code de parrainage',
	lastname: 'Nom',
	mail: 'Adresse mail',
	company: 'Entreprise',
	phone: 'Numéro de téléphone',
	position: 'Poste occupé',
	password: 'Mot De Passe',
	forgotPassword: 'Mot de passe oublié ?',
	noConfirmationMail: "Je n'ai pas reçu de mail de confirmation !",
	valid: 'Se connecter',
	validUp: "S'inscrire",
	addInfo: 'Ajouter informations',
	chooseUserTypeFreelance: 'Je suis un freelance',
	chooseUserTypeStudio: 'Je gère une entreprise',
	addInfoTitle:
		"Nous avons besoin de quelques informations en plus avant que vous ne puissiez accéder votre page d'accueil.",
	successMessage:
		'Votre compte a été créé avec succès, veuillez valider votre adresse email 🤗.',
	errorTitle: 'Erreur de connexion',
	unlock: 'Débloquer votre compte',
	typeCode: 'Tappez le code reçu par mail',
	forgotPasswordTitle:
		'Renseignez votre email afin de recevoir votre nouveau mot de passe',
	validForgot: 'Envoyer',
	newPasswordTitle:
		'Pour activer votre compte, renseignez votre email et votre nouveau mot de passe',
	newPassword: 'Nouveau mot de passe',
	accountNotValidated:
		'Veuillez confirmer votre compte grâce au mail ou renvoyer un lien dans la page suivante',
	redirectConfirm: 'Allez sur la page de renvoi de mail',
	signupModalTitle: 'Inscription',
	signupModalTextSuccess:
		'Votre compte a été créé avec succès, veuillez valider votre adresse email 🤗.',
	googleAccountText:
		"Ce compte a été associé a votre compte google. Vous pourrez vous connecter avec l'email et le mot de passe enregistré une fois l'email validé.",
	signupModalButtonSuccess: 'Aller vers la page de connexion',
	signupModalButtonError: 'Fermer',
	confirmErrorTitle: 'Erreur de confirmation de compte',
	confirmErrorButton: 'Aller vers la page de connexion',
	loginGoogle: 'Continuer avec Google',
	signUpGoogle: 'Continuer avec Google',
	or: 'OU',
	termsAndConditionsAgreement: "J'ai lu et accepte ",
	termsAndConditions: "les conditions d'utilisations Styleep",
	userAgreementPlaceholder: "Conditions d'utilisation",
	invoiceMandate: "que Styleep facture au nom de ma société",
	userInvoiceMandatePlaceholder: "Mandat de facturation",
	title: 'Récoltez, décidez ',
	subtitle:
		"Styleep est la première plateforme française de mise en relation entre professionnels de beauté.",
	link: 'En découvrir plus sur',
	titleConfirm: "Confirmation d\'email",
	errorUserType: 'L\'utilisateur n\'existe pas.',
	studioName: "Nom de l\'entreprise",
	franchise: "Êtes-vous une entreprise franchisé ? Si oui, de quelle marque ?",
	knowBy: 'Comment avez-vous connu Styleep ?',
	address: 'Adresse de l\'entreprise',
	creationDate: 'Date de création de l\'entreprise',
	collaborators: 'Nombre de collaborateurs',
	rightSideText: 'la nouvelle façon de travailler pour les entreprises & freelances'
}

export const EN = {
	forThe: {
		studio: 'For studios',
		freelance: 'For hairdresser',
	},
	salonValueProps: [
		'Testez vos produits',
		'Définissez votre audience',
		'Détectez de nouvelles opportunités',
		'Prenez des décisions qui impacteront votre marque',
	],
	freelanceValueProps: [
		'Test your products',
		'Define your audience',
		'Detect patterns and opportunities',
		'Take decision that matter',
	],
	logIn: 'Log In',
	signUp: 'Sign Up',
	godfather: 'Godfather code',
	firstname: 'First name',
	lastname: 'Last name',
	mail: 'Email address',
	company: 'Company',
	phone: 'Phone number',
	position: 'Position occupied',
	password: 'Password',
	forgotPassword: 'Forgot password ?',
	noConfirmationMail: 'I did not receive a confirmation email !',
	valid: 'Sign In',
	validUp: 'Sign up',
	addInfoTitle:
		'We just need a few more informations before you can access your home page.',
	addInfo: 'Add informations',
	successMessage:
		'Your account has been created with success. Please check your email for next instructions 🤗.',
	errorTitle: 'Login error',
	chooseUserTypeFreelance: "I'm a hairdresser",
	chooseUserTypeStudio: 'I manage a salon',
	unlock: 'Unlock your account',
	typeCode: 'Type here the code you received by email',
	forgotPasswordTitle:
		'Please provide your email to receive your new password code',
	validForgot: 'Send',
	newPasswordTitle:
		'To reactivate your account, please enter your email and your new password',
	newPassword: 'New password',
	accountNotValidated:
		'Please confirm your account via email or return a link to the following page',
	redirectConfirm: 'Go to the email landing page',
	signupModalTitle: 'Signup',
	signupModalTextSuccess:
		'Your account has been created with success. Please check your email for next instructions 🤗.',
	googleAccountText:
		'This account has been associated with your google account. You will be able to login with this email and password once the email has been verified.',
	signupModalButtonSuccess: 'Go to the login page',
	signupModalButtonError: 'Close',
	confirmErrorTitle: 'Account confirmation error',
	confirmErrorButton: 'Go to the login page',
	loginGoogle: 'Continue with Google',
	signUpGoogle: 'Continue with Google',
	or: 'OR',
	termsAndConditionsAgreement: 'I have read and approve ',
	termsAndConditions: "Styleep's terms and conditions of use",
	userAgreementPlaceholder: 'Terms and conditions',
	invoiceMandate: "that Styleep create invoice at my company name",
	userInvoiceMandatePlaceholder: "Invoice Mandate",
	title: 'Collect, decide',
	subtitle:
		'Styleep allows you to interact directly with your present and future consumers in order to obtain the maximum amount of qualified informations to optimize your sales',
	link: 'Discover more on',
	errorUserType: 'User type doesn\'t exist.',
	studioName: "Studio name",
	franchise: "Are you a franchise of a brand ? If which one ?",
	knowBy: 'How did you hear about Styleep ?',
	address: 'Studio address',
	creationDate: 'Creation date of the studio',
	collaborators: 'Number of collaborators',
	titleConfirm: "Email confirmation page",
	rightSideText: 'the new way of work for studios & hairdresser'
}

export default {
	fr: FR,
	us: EN,
}

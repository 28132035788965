import { fetchApi, objectToFormData } from "../helpers";

export const createBadge = ({ name, icon, intensity }) => {
    console.log('first', { name, icon, intensity })
    return fetchApi('/badges', {
        method: 'POST',
        body: objectToFormData({
            name,
            icon,
            intensity
        }),
        multiform: true
    })
}

export const deleteBadgeAPI = name => fetchApi('/badges', {
    method:'DELETE',
    body: {
        name
    }
})
export const updateImageAPI = (imageId, newImage) => fetchApi('/image/'+imageId, {
    method:'PATCH',
    body: objectToFormData({
        myImage: newImage
    }),
    multiform: true
})

export const getBadges = () => fetchApi('/badges')
import { fetchApi } from "containers/helpers"


export const getEmailAdminSent = () => fetchApi(
    '/emailSents'
)

export const getEmailSendTypes = () => fetchApi(
    '/admin/email/types'
)

export const getEmailTemplates = () => fetchApi(
    '/admin/email/templates'
)

export const sendEmailAdmin = (template, to, data) => fetchApi(
    '/admin/email/send', {
    method: 'POST',
    body: { template, to, data }
})